import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ddp } from '@theclinician/ddp-connector';
import { getMilestoneNames } from '../../../../../common/api/aggregations/Projects';
import ActionItem from './ActionItem';
import { openTab, openMilestoneDialog } from '../../../actions';
import { takeQuestionnaire } from '../../../../../components/dialogs/TakeQuestionnaire';

const propTypes = {
  projectId: PropTypes.string.isRequired,
};

const defaultProps = {};

const Container = compose(
  ddp({
    queries: () => ({
      milestoneNames: getMilestoneNames.withParams({}),
    }),
    renderLoader: null,
  }),
  connect(null, (dispatch, { projectId }) => ({
    onRecipientClick: (label, value) => {
      dispatch(
        openTab({
          projectId,
          settings: {
            patientId: value,
          },
        }),
      );
    },
    onMilestoneClick: (recipientId, milestoneId) =>
      dispatch(
        openMilestoneDialog({
          recipientId,
          milestoneId,
        }),
      ),
    onQuestionnaireClick: (answersSheetId) =>
      dispatch(takeQuestionnaire(answersSheetId)),
  })),
)(ActionItem);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
