import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';

import Modal from '../Modal';
import Stack from '../../../common/components/primitives/Stack';
import FormItem from '../../../common/components/FormItem';
import Input from '../../../common/components/Input';
import { apiAdminOnePermissionsDomain } from '../../../common/api/admin';

import { setDomain, getDomain } from '../../../screens/SettingsDomains/store';

const EditDomainForm = ({ domain, creating }) => {
  const dispatch = useDispatch();
  const [domainId, setDomainId] = useState(domain ? domain.id : '');

  const [domainName, setDomainName] = useState(domain ? domain.name : '');

  const setStateDomain = useCallback(
    (value, key) => {
      const domainCopy = {
        ...domain,
        [`${key}`]: value,
      };

      if (key === 'id') {
        setDomainId(value);
      } else if (key === 'name') {
        setDomainName(value);
      }

      dispatch(setDomain(domainCopy));
    },
    [domain, setDomainId, setDomainName, dispatch],
  );

  return (
    <form>
      <Stack>
        <FormItem label="Id">
          <Input
            value={domainId}
            onChange={(e) => setStateDomain(e.target.value, 'id')}
            disabled={!creating}
          />
        </FormItem>
        <FormItem label="Name">
          <Input
            value={domainName}
            onChange={(e) => setStateDomain(e.target.value, 'name')}
          />
        </FormItem>
      </Stack>
    </form>
  );
};

const EditDomainDialog = ({ open, creating, onCancel, onCreate }) => {
  const { t } = useTranslation();
  const domain = useSelector(getDomain);

  const { ready: isReady } = useDDPSubscription(
    domain &&
      domain._id &&
      apiAdminOnePermissionsDomain.withParams({
        domainId: domain._id,
      }),
  );

  return (
    <Modal
      title={!creating ? t('editDomain') : t('addDomain')}
      visible={open}
      isOkDisabled={!isReady}
      onOk={onCreate}
      onCancel={onCancel}
    >
      <EditDomainForm domain={domain} creating={creating} />
    </Modal>
  );
};

EditDomainForm.propTypes = {
  domain: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  creating: PropTypes.bool,
};

EditDomainForm.defaultProps = {
  domain: null,
  creating: false,
};

EditDomainDialog.propTypes = {
  creating: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

EditDomainDialog.defaultProps = {
  open: false,
  creating: false,
};

export default EditDomainDialog;
