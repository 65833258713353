import React from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import { ddp } from '@theclinician/ddp-connector';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import { QUESTION_TYPE__FREE_TEXT } from '../../../../common/constants';
import ProjectSelect from '../../../../common/selectors/Project';
import QuestionnaireSelect from '../../../../common/selectors/Questionnaire';
import {
  isMatchingVersion,
  findMaxVersion,
} from '../../../../common/utils/versions';
import { latestVersion } from '../../../../common/api/collections/Questionnaires2';
import {
  property,
  higherOrderSelector,
} from '../../../../common/utilsClient/selectors';
import Select from '../../../../common/components/Select';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Stack from '../../../../common/components/primitives/Stack';
import store from '../../store';
import NLP from './NLP';
import { apiZedocOneProject } from '../../../../common/api/zedoc';

const Container = compose(
  connect(() => {
    const selectProjectId = property('projectId');
    const selectProject = ProjectSelect.one().whereIdMatchesProp('projectId');
    const selectVersionRanges = createSelector(selectProject, (project) =>
      mapValues(keyBy(project.questionnaires, 'identifier'), 'version'),
    );
    const selectVersions = createSelector(
      selectProject,
      QuestionnaireSelect.all(),
      (project, questionnaires) => {
        const byIdentifier = groupBy(questionnaires, (q) => q.getIdentifier());
        const versions = {};
        forEach(
          project.questionnaires,
          ({ version: versionRange, identifier }) => {
            const byVersion = groupBy(byIdentifier[identifier], (q) =>
              q.getVersion(),
            );
            const allowedVersions = filter(keys(byVersion), (version) =>
              isMatchingVersion(versionRange, version),
            );
            if (allowedVersions.length > 0) {
              versions[identifier] = findMaxVersion(allowedVersions);
            }
          },
        );
        return versions;
      },
    );
    const selectOptions = createSelector(
      selectVersions,
      QuestionnaireSelect.all().byId(),
      (versions, questionnairesById) => {
        const options = [];
        forEach(versions, (version, identifier) => {
          const questionnaireId = `${identifier}@${version}`;
          const questionnaire = questionnairesById[questionnaireId];
          if (questionnaire) {
            questionnaire.forEachQuestion((question) => {
              if (question.type === QUESTION_TYPE__FREE_TEXT) {
                options.push({
                  label: `[${identifier}; ${question.path}] ${question.title}`,
                  value: `${identifier},${question.id}`,
                });
              }
            });
          }
        });
        return options;
      },
    );
    const selectDashboardId = property('dashboardId');
    const selectCurrentValue = createSelector(
      higherOrderSelector(selectDashboardId, (dashboardId) =>
        store.get(`dashboards.${dashboardId}.value`),
      ),
      selectOptions,
      (value, options) => {
        if (value) {
          return value;
        }
        return options[0] && options[0].value;
      },
    );
    const selectIdentifier = createSelector(
      selectCurrentValue,
      (value) => value && value.split(',')[0],
    );
    const selectQuestionnaireId = createSelector(
      selectVersions,
      selectIdentifier,
      (versions, identifier) => {
        const version = versions[identifier];
        if (version) {
          return `${identifier}@${version}`;
        }
        return null;
      },
    );
    const selectQuestionnaire = QuestionnaireSelect.one().whereIdEquals(
      selectQuestionnaireId,
    );
    const selectQuestionId = createSelector(
      selectCurrentValue,
      (value) => value && value.split(',')[1],
    );
    const selectQuestion = createSelector(
      selectQuestionnaire,
      selectQuestionId,
      (questionnaire, questionId) =>
        questionnaire && questionnaire.getQuestionById(questionId),
    );
    return createStructuredSelector({
      currentValue: selectCurrentValue,
      options: selectOptions,
      versionRanges: selectVersionRanges,
      versionRange: createSelector(
        selectVersionRanges,
        selectIdentifier,
        (versionRanges, identifier) => versionRanges[identifier],
      ),
      dashboardId: selectDashboardId,
      projectId: selectProjectId,
      questionId: selectQuestionId,
      questionnaireId: selectQuestionnaireId,
      questionnaire: selectQuestionnaire,
      question: selectQuestion,
    });
  }),
  ddp({
    subscriptions: (state, { projectId, versionRanges }) => {
      // TODO: Here we will need to add subscriptions for the actual analytics.
      const subs = [
        projectId &&
          apiZedocOneProject.withParams({
            projectId,
          }),
      ];
      forEach(versionRanges, (versionRange, identifier) => {
        subs.push(
          latestVersion.withParams({
            questionnaireId: `${identifier}@${versionRange}`,
          }),
        );
      });
      return subs;
    },
  }),
  withHandlers({
    handleOnChange:
      ({ dispatch, dashboardId }) =>
      (value) =>
        dispatch(store.set(`dashboards.${dashboardId}.value`, value)),
  }),
)(
  ({
    currentValue,
    options,
    handleOnChange,
    projectId,
    questionId,
    questionnaireId,
    dashboardId,
    versionRange,
    question,
  }) => {
    const questionnaireIdWithVersionRange = questionnaireId
      ? [questionnaireId.split('@')[0], versionRange].join('@')
      : null;
    return (
      <Stack>
        <Sidebar sidebar={null}>
          <Select value={currentValue} onChange={handleOnChange} showSearch>
            {map(options, ({ value, label }) => (
              <Select.Option key={value}>{label}</Select.Option>
            ))}
          </Select>
        </Sidebar>
        {questionnaireIdWithVersionRange && (
          <NLP
            questionTitle={question && question.title}
            projectId={projectId}
            questionId={questionId}
            questionnaireId={questionnaireIdWithVersionRange}
            dashboardId={dashboardId}
            versionRange={versionRange}
          />
        )}
      </Stack>
    );
  },
);

export default Container;
