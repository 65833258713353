import { useMemo } from 'react';
import { createSelector } from 'reselect';
import { push, replace } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { parse as parseQuery, encode } from '../common/utils/querystring';

export const transitionTo =
  ({ replaceState, ...options }) =>
  (dispatch, getState) => {
    const state = getState();
    const { location } = state.router;
    const {
      pathname = location.pathname,
      hash = location.hash,
      query,
    } = options;
    const search = encode({
      ...parseQuery(location.search),
      ...query,
    });
    if (replaceState) {
      return dispatch(replace(pathname + search + hash));
    }
    return dispatch(push(pathname + search + hash));
  };

export const setQuery = (query, replaceState) =>
  transitionTo({
    query,
    replaceState,
  });

export const setQueryParam = (key, value, replaceState) =>
  transitionTo({
    query: {
      [key]: value,
    },
    replaceState,
  });

export const selectQuery = createSelector(
  (state) => state.router.location.search,
  (search) => parseQuery(search),
);

export const selectPathname = (state) => state.router.location.pathname;

export const selectQueryParam = (key, defaultValue) =>
  createSelector(
    (state) => state.router.location,
    ({ search }) => parseQuery(search)[key] || defaultValue,
  );

export const selectUrl = createSelector(
  (state) => state.router.location,
  ({ pathname, search, hash }) => `${pathname}${search}${hash}`,
);

export const useQueryParam = (key, parse) => {
  const value = useSelector(selectQueryParam(key));
  return useMemo(() => parse(value), [value, parse]);
};

export default selectQuery;
