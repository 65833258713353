import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const noop = () => {};

const ModalContext = React.createContext({
  render: noop,
  cancel: noop,
});

export const withModal = () => {
  return (Component) => (props) => {
    return (
      <ModalContext.Consumer>
        {(modal) => <Component {...props} modal={modal} />}
      </ModalContext.Consumer>
    );
  };
};

export const useModal = () => {
  return useContext(ModalContext);
};

export class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      visible: false,
    };
    let handle;
    this.modal = {
      render: (dialog) => {
        if (handle) {
          clearTimeout(handle);
          handle = null;
        }
        this.setState({
          dialog,
          visible: true,
        });
      },
      cancel: (delay = 0) => {
        this.setState({
          visible: false,
        });
        handle = setTimeout(() => {
          if (!this.unmounted) {
            this.setState({
              dialog: null,
            });
          }
        }, delay);
      },
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const { children } = this.props;
    const { dialog: Dialog, visible } = this.state;
    return (
      <ModalContext.Provider value={this.modal}>
        {children}
        {(() => {
          if (typeof Dialog === 'function') {
            return <Dialog visible={visible} />;
          }
          return Dialog;
        })()}
      </ModalContext.Provider>
    );
  }
}

ModalContainer.propTypes = {
  children: PropTypes.node,
};

ModalContainer.defaultProps = {
  children: null,
};
