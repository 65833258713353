import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import checkPermissions from './checkPermissions';

const createRestrictedRoute = ({ permissions, renderLoader, redirectTo }) => {
  const DecoratedComponent = checkPermissions({
    permissions,
    renderLoader,
    currentUser: true,
  })(
    ({
      currentUser,
      currentPermissions,
      waitingForPermissions,
      component,
      render,
      ...props
    }) => {
      if (waitingForPermissions) {
        return null;
      }
      if (currentPermissions) {
        if (component) {
          return React.createElement(component, {
            ...props,
            currentUser,
          });
        }
        if (render) {
          return render({
            ...props,
            currentUser,
          });
        }
        return null;
      }
      if (redirectTo) {
        const { location } = props;
        return React.createElement(Redirect, {
          to: {
            pathname: redirectTo,
            state: {
              from: location,
            },
            hash: location && location.hash,
          },
        });
      }
      return null;
    },
  );

  const Container = ({ component, render, ...rest }) =>
    React.createElement(Route, {
      ...rest,
      render: (props) =>
        React.createElement(DecoratedComponent, {
          ...props,
          render,
          component,
        }),
    });

  Container.propTypes = {
    render: PropTypes.func,
    component: PropTypes.elementType,
  };

  Container.defaultProps = {
    render: null,
    component: null,
  };

  return Container;
};

export default createRestrictedRoute;
