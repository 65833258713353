import styled from 'styled-components/macro';
import React from 'react';
import CheckboxAntd from 'antd/es/checkbox';
import 'antd/es/checkbox/style/css';
import { theme } from '../utilsClient/cssHelpers';

const Component = styled(CheckboxAntd)`
  && {
    user-select: none;
    color: inherit;
    display: flex;
    align-items: center;
    padding: ${theme('space.2')};

    /* display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; */

    /* TODO: Remove after getting rid of antd */
    &:hover {
      .ant-checkbox-inner {
        border-color: ${theme('color.primary')};
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${theme('color.primary')};
    }

    .ant-checkbox {
      top: 0;

      &.ant-checkbox-checked .ant-checkbox-inner {
        border-color: ${theme('color.primary')};
        background-color: ${theme('color.primary')};
      }
    }

    span.ant-checkbox + * {
      padding-right: ${theme('space.2')};
      padding-left: ${theme('space.2')};
      /* padding-left: 0; */
    }

    .ant-checkbox-inner {
      background-color: ${theme('color.surface')};
      border-color: ${theme('color.border')};
      border-radius: ${theme('border.radius.2')};
      border-width: 2px;
      overflow: hidden;
      height: 1.5em;
      width: 1.5em;

      &::after {
        top: 9px;
        left: 5px;
      }
    }
  }
`;

const Checkbox = (props) => {
  return (
    <Component data-testid={`checkbox-option-${props.value}`} {...props} />
  );
};

export default Checkbox;
