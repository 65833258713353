import omitBy from 'lodash/omitBy';
import { createStructuredSelector } from 'reselect';
import createSelectors from './createSelectors';
import Recipient from '../models/Recipient';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import Variable from '../models/Variable';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';

const Select = createSelectors(
  Recipient,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (rawRecipient, { piiRealm, variablesDb } = {}) => {
      if (PermissionsDomain.belongsToRealm(rawRecipient.ownership, piiRealm)) {
        return rawRecipient;
      }
      const newRecipient = omitBy(rawRecipient, (value, key) => {
        const variable = new Variable({
          scopeName: Recipient.scopeName,
          nativeKey: key,
        });
        return variable.isPII();
      });
      if (newRecipient.variables) {
        newRecipient.variables = omitBy(
          newRecipient.variables,
          (value, key) => {
            const variable = variablesDb && variablesDb[key];
            return !variable || variable.isPII();
          },
        );
      }
      return newRecipient;
    },
  },
);

// TODO: I am leaving this selector for compatibility but it
//       will not return anything now, as proxiedRecipientId
//       field is no longer supported. Let's remove it completely
//       once there are no more references to RecipientSelect.current().
Select.current = () => () => null;

export default Select;
