import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import CurrentUserSelect from '../../common/selectors/CurrentUser';

const makeEntryRoute = (LoggedInComponent) => {
  const EntryRoute = connect(() =>
    createStructuredSelector({
      currentUser: CurrentUserSelect.user(),
      isLoggingIn: CurrentUserSelect.isLoggingIn(),
    }),
  )(({ component: Component, currentUser, isLoggingIn, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          return <LoggedInComponent />;
        }

        return <Component isLoggingIn={isLoggingIn} {...props} />;
      }}
    />
  ));

  return EntryRoute;
};

export default makeEntryRoute;
