import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../common/components/base/Text';
import { theme } from '../../../../common/utilsClient/cssHelpers';

const StyledAppointmentWrapper = styled.div`
  background-color: ${theme('color.surface')};
  box-shadow: ${theme('boxShadow.base')};
  border-radius: 1.5em;
  padding: ${theme('space.1')} ${theme('space.2')};
`;

const VariablesTag = ({ name, variables, projectVariables }) => {
  const [label, setLabel] = useState();

  useEffect(() => {
    const displayVariablesIds = variables.map(({ id }) => id);

    const values = [];
    displayVariablesIds.forEach((id) => {
      const variableId = Object.keys(projectVariables).find(
        (key) => key === id,
      );
      if (variableId) {
        values.push(projectVariables[variableId]);
      }
    });

    if (values.length > 0) {
      setLabel(values.join(', '));
    }
  }, [projectVariables, variables]);

  return name || label ? (
    <StyledAppointmentWrapper>
      <Text.Span size="small">
        {name}
        {label && `. ${label}`}
      </Text.Span>
    </StyledAppointmentWrapper>
  ) : null;
};

VariablesTag.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.array,
  projectVariables: PropTypes.objectOf(PropTypes.any),
};

VariablesTag.defaultProps = {
  name: null,
  variables: [],
  projectVariables: {},
};

export default VariablesTag;
