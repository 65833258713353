import PropTypes from 'prop-types';
import { withState, withProps, lifecycle } from 'recompose';
import { compose } from 'redux';
import { ddp } from '@theclinician/ddp-connector';
import { createSelector, createStructuredSelector } from 'reselect';
import { reset, formValueSelector, change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { addToProject } from '../../../common/api/collections/Recipients';
import {
  getSortedMilestones,
  apiZedocAllProjects,
} from '../../../common/api/zedoc';
import UserSelect from '../../../common/selectors/User';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../common/selectors/Project';
import RecruitFiltered from './RecruitFiltered';
import { PROJECT_ATTACH_PARTICIPATION } from '../../../common/permissions';
import { notifyError, notifySuccess } from '../../../utils/notify';
import createDialog from '../../../store/ui/dialogs/create';
import { property, constant } from '../../../common/utilsClient/selectors';
import { isParticipant } from './utils';

const dialog = createDialog('Components | Dialogs | RecruitFiltered', {
  propTypes: {
    recipientId: PropTypes.string,
  },
});

const form = 'Components | Dialogs | RecruitFiltered';
const Container = compose(
  withState('isValueGenerating', 'setGenerateValue', false),
  // NOTE: This state does not survive hot module replacement
  withState('milestoneOptions', 'setMilestoneOptions', []),
  // NOTE: This state is to show a loading indicator while a patient is being added
  withState('isConfirmLoading', 'setConfirmLoading', false),
  dialog.connect({
    selectors: () => {
      const getProjectId = createSelector(
        (state) => formValueSelector(form)(state, 'projectId'),
        property('defaultProjectId'),
        (projectId, defaultProjectId) => projectId || defaultProjectId,
      );
      return createStructuredSelector({
        form: constant(form),
        projectId: getProjectId,
        projects: ProjectSelect.all(),
        users: UserSelect.all().whenMemberOfProject(getProjectId),
        permissionsValidator:
          CurrentUserSelect.getCurrentPermissionsValidator(),
      });
    },
    mapDispatchToProps: (dispatch) => ({
      onCancel: () => {
        dispatch(dialog.close());
        reset(form);
      },
    }),
  }),
  ddp({
    subscriptions: (_, props) => [
      props.open && apiZedocAllProjects.withParams({}),
    ],
    mutations: {
      onSubmit:
        ({ dispatch, setConfirmLoading }) =>
        ({ patients, projectId, milestoneId, milestoneDate }) => {
          setConfirmLoading(true);
          // NOTE: If there's an error we choose not to close the modal yet

          if (!isEmpty(patients)) {
            const addPatientPromises = [];
            patients.forEach((p) => {
              if (p.projectId !== projectId) {
                addPatientPromises.push(
                  dispatch(
                    callMethod(addToProject, {
                      projectId,
                      milestoneId,
                      milestoneDate,
                      recipientId: p.recipientId,
                    }),
                  ),
                );
              }
            });
            Promise.all(addPatientPromises)
              .then(notifySuccess('Patients added to project!'))
              .then(() => setConfirmLoading(false))
              .then(() => dispatch(dialog.close()))
              .then(() => dispatch(reset(form)))
              .catch((err) => {
                notifyError()(err);
                setConfirmLoading(false);
              });
          }
        },
      onSelectProject:
        ({ mutate, dispatch, setMilestoneOptions }) =>
        ({ value }) =>
          value &&
          mutate(
            // TODO: Of course it does not make sense to call a method as we already have milestones subscription.
            //       The problem is we currently cannot "wait for subscription" imperatively, and form values
            //       can only be updated imperatively.
            getSortedMilestones.withParams({
              projectId: value,
            }),
          )
            .then((milestones) => {
              setMilestoneOptions(
                milestones.map((milestone) => ({
                  value: milestone.value,
                  label: milestone.label,
                })),
              );
              if (milestones.length > 0) {
                dispatch(change(form, 'milestoneId', milestones[0].value));
              }
            })
            .catch(notifyError()),
    },
    renderLoader: null,
  }),
  withProps(
    ({ recipientId, patient, onSubmit, projects, permissionsValidator }) => ({
      onSubmit: (data) =>
        onSubmit({
          ...data,
          recipientId,
        }),
      projectOptions:
        projects && patient
          ? projects
              .filter(({ _id }) => !isParticipant(patient, _id))
              .filter((project) =>
                permissionsValidator(PROJECT_ATTACH_PARTICIPATION, {
                  relativeTo: project.getDomains(),
                }),
              )
              .map((project) => ({
                value: project._id,
                label: project.name,
              }))
          : [],
    }),
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.open === prevProps.open) {
        return;
      }
      const { open, defaultProjectId, onSelectProject } = this.props;
      if (open && defaultProjectId) {
        onSelectProject({
          value: defaultProjectId,
        });
      }
    },
  }),
)(RecruitFiltered);

Container.propTypes = {
  defaultProjectId: PropTypes.string,
  onAdded: PropTypes.func,
};

Container.defaultProps = {
  defaultProjectId: null,
  onAdded: null,
};

export default Container;
export { dialog };
