import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import Select from '../../inputs/Select';
import Modal from '../Modal';
import Stack from '../../../common/components/primitives/Stack';
import FormItem from '../../../common/components/FormItem';
import Input from '../../../common/components/Input';
import Spacer from '../../../common/components/primitives/Spacer';
import Text from '../../../common/components/base/Text';
import { apiAdminOneUsersGroup } from '../../../common/api/admin';
import { getGroup, setGroup } from '../../../screens/SettingsUsersGroups/store';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const EditUsersGroup = ({ group, groupOptions, roleOptions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [groupName, setGroupName] = useState((group && group.name) || '');
  const [groupRoles, setGroupRoles] = useState(
    group && group.roles && group.roles.length ? group.roles : [],
  );
  const [groupBelongsTo, setGroupBelongsTo] = useState(
    (group && group.belongsTo) || '',
  );
  const [groupType, setGroupType] = useState((group && group.type) || '');

  const typeOptions = [
    {
      label: 'Organization',
      value: 'Organization',
    },
    {
      label: 'Temporary',
      value: 'Temporary',
    },
  ];

  const updateGroupState = useCallback(
    (value, key) => {
      const groupCopy = {
        ...group,
        [`${key}`]: value,
      };
      if (key === 'name') {
        setGroupName(value);
      } else if (key === 'type') {
        setGroupType(value);
      } else if (key === 'belongsTo') {
        setGroupBelongsTo(value);
      } else if (key === 'roles') {
        setGroupRoles(value);
      }
      dispatch(setGroup(groupCopy));
    },
    [
      group,
      dispatch,
      setGroupName,
      setGroupType,
      setGroupBelongsTo,
      setGroupRoles,
    ],
  );

  return (
    <form>
      <Stack>
        <FormItem label="Name">
          <Input
            value={groupName}
            onChange={(e) => {
              updateGroupState(e.target.value, 'name');
            }}
          />
        </FormItem>
        <FormItem label="Type">
          <StyledSelect
            value={groupType}
            onChange={(value) => {
              updateGroupState(value, 'type');
            }}
            options={typeOptions}
          />
        </FormItem>
        <Spacer space={2}>
          <Text.Paragraph importance="high">
            {t('rolesAndPermissions')}
          </Text.Paragraph>
        </Spacer>
        <FormItem label="Domain">
          <StyledSelect
            value={groupBelongsTo}
            onChange={(value) => {
              updateGroupState(value, 'belongsTo');
            }}
            options={groupOptions}
          />
        </FormItem>
        <FormItem label="Roles">
          <StyledSelect
            multiple
            value={groupRoles}
            onChange={(values) => {
              updateGroupState(values, 'roles');
            }}
            options={roleOptions}
          />
        </FormItem>
      </Stack>
    </form>
  );
};

const EditDomainDialog = ({
  open,
  creating,
  onCancel,
  onCreate,
  groupOptions,
  roleOptions,
}) => {
  const { t } = useTranslation();
  const group = useSelector(getGroup);
  const { ready: isReady } = useDDPSubscription(
    group &&
      group._id &&
      apiAdminOneUsersGroup.withParams({
        groupId: group._id,
      }),
  );
  return (
    <Modal
      title={!creating ? t('editGroup') : t('addGroup')}
      visible={open}
      isOkDisabled={!isReady}
      onOk={onCreate}
      onCancel={onCancel}
    >
      <EditUsersGroup
        group={group}
        creating={creating}
        groupOptions={groupOptions}
        roleOptions={roleOptions}
      />
    </Modal>
  );
};

EditUsersGroup.propTypes = {
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

EditUsersGroup.defaultProps = {
  group: null,
  roleOptions: [],
  groupOptions: [],
};

EditDomainDialog.propTypes = {
  creating: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

EditDomainDialog.defaultProps = {
  open: false,
  creating: false,
  roleOptions: [],
  groupOptions: [],
};

export default EditDomainDialog;
