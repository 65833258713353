import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../common/selectors/Project';
import { getFilters } from '../../../store/ui/search/selectors';
import { setFilters, clearFilters } from '../../../store/ui/search/actions';
import createDialog from '../../../store/ui/dialogs/create';
import store from '../../../store/ui/dialogs/store';
import FilterModal from './FilterModal';
import { apiZedocOneProject } from '../../../common/api/zedoc';

const name = 'Components | Inputs | Filters';
const dialog = createDialog(name, {
  propTypes: {
    filters: PropTypes.objectOf(PropTypes.any),
  },
});

const getProjectId = (state, props) => props.projectId;

const createGetBoundFilters = (getFiltersState) =>
  createSelector(
    ProjectSelect.one().whereIdEquals(getProjectId),
    getFiltersState,
    (project, filtersState) =>
      project ? project.getBoundFilters(filtersState) : [],
  );

const constant = (x) => () => x;
const getEditableFilters = store.get(`${name}.filters`);
const getAppliedFilters = getFilters;
const getBoundFilters = createGetBoundFilters(getEditableFilters);
const getDefaultFilters = createGetBoundFilters(constant({}));
const getModifiedFiltersIds = createSelector(
  getBoundFilters,
  getDefaultFilters,
  (boundFilters, defaultFilters) => {
    const filterIds = [];
    const byId = keyBy(boundFilters, 'id');
    defaultFilters.forEach((defaultFilter) => {
      const { id } = defaultFilter;
      const refState = byId[id] && byId[id].state;
      if (!isEqual(refState, defaultFilter.state)) {
        filterIds.push(id);
      }
    });
    return filterIds;
  },
);

const Container = compose(
  dialog.connect({}),
  connect(
    createStructuredSelector({
      boundFilters: getBoundFilters,
      appliedFilters: getAppliedFilters,
      currentUser: CurrentUserSelect.user(),
      modifiedIds: getModifiedFiltersIds,
      hasFilters: createSelector(
        getAppliedFilters,
        (filters) => !isEmpty(filters),
      ),
    }),
  ),
  ddp({
    subscriptions: (state, { projectId }) => [
      projectId &&
        apiZedocOneProject.withParams({
          projectId,
        }),
    ],
    renderLoader: null,
  }),
  withHandlers({
    onOpen:
      ({ dispatch }) =>
      () =>
        dispatch(($1, getState) => {
          const state = getState();
          const filters = getAppliedFilters(state);

          dispatch(
            dialog.open({
              filters,
            }),
          );
        }),
    onOk:
      ({ dispatch, filters }) =>
      () => {
        dispatch(
          setFilters({
            filters,
          }),
        );
        dispatch(dialog.close());
      },
    onCancel:
      ({ dispatch }) =>
      () =>
        dispatch(dialog.close()),
    onClearFilters:
      ({ dispatch }) =>
      () =>
        dispatch(clearFilters()),
    setFilter:
      () =>
      ({ id, state }) =>
        store.set(`${name}.filters.${id}`, state),
    clearFilter:
      () =>
      ({ id }) =>
        store.set(`${name}.filters.${id}`, null),
  }),
  setDisplayName('Filters'),
)(FilterModal);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;
