/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components/macro';
import { theme } from '../common/utilsClient/cssHelpers';

const Component = styled.div`
  height: 100%;

  color: ${theme('color.onBackground')};

  h1 {
    ${theme('typography.title1')};
  }

  h2 {
    ${theme('typography.title2')};
  }

  a {
    color: ${theme('color.primary')};

    &:hover {
      color: ${theme('color.primaryHover')};
    }
  }
`;

export default (props) => <Component {...props} />;
