import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import some from 'lodash/some';
import flatMap from 'lodash/flatMap';
import size from 'lodash/size';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import createSelectors from '../../common/selectors/createSelectors';
import EAPP from '../../common/models/EAPP';
import ProjectSelect from '../../common/selectors/Project';
import ProjectDashboardSelect from '../../common/selectors/ProjectDashboard';
import AnswersSheetSelect from '../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../common/selectors/Questionnaire';
import ActivitySelect from '../../common/selectors/Activity';
import ProjectMilestoneSelect from '../../common/selectors/ProjectMilestone';
import ParticipationSelect from '../../common/selectors/Participation';
import RecipientSelect from '../../common/selectors/Recipient';
import store from './store';
import {
  selectPathname,
  selectQuery,
  selectQueryParam,
} from '../../store/router';
import {
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
} from '../../common/constants';
import {
  getPerspectiveIdField,
  getPerspectiveId,
  getViewUrl,
  hasSpecialization,
} from './dashboards';
import {
  constant,
  higherOrderSelector,
} from '../../common/utilsClient/selectors';
import createGetViewParams from './createGetViewParams';
import { createViewsTree, findTreeNode, forEachNode } from './viewsTree';
import describeRecipient, { getTruncatedId } from './describeRecipient';

const EAPPSelect = createSelectors(EAPP);

const toBoolean = (value) => !!value;

const getMatchParams = (pathname) => {
  const match = matchPath(pathname, {
    path: '/projects/:projectId/:perspective?/:perspectiveId?',
  });
  if (!match) {
    return {};
  }
  return match.params || {};
};

export const selectProjectId = createSelector(
  selectPathname,
  (pathname) => getMatchParams(pathname).projectId,
);

export const selectProject = ProjectSelect.one().whereIdEquals(selectProjectId);

export const selectViewType = createSelector(
  selectQueryParam('_view'),
  (view) => {
    if (view) {
      return view.split('.')[0];
    }
    return undefined;
  },
);

export const selectViewPresetNo = createSelector(
  selectQueryParam('_view'),
  (view) => {
    if (view) {
      const presetNoStr = view.split('.')[1];
      const presetNo = +presetNoStr;
      if (!isNaN(presetNo)) {
        return presetNo;
      }
    }
    return undefined;
  },
);

const selectViewSettings = createSelector(selectQuery, (query) => {
  const tabSettings = {};
  forEach(query, (value, key) => {
    const match = /^_view\.(.*)/.exec(key);
    if (match) {
      tabSettings[match[1]] = value;
    }
  });
  if (!isEmpty(tabSettings)) {
    return tabSettings;
  }
  return undefined;
});

export const selectCurrentViewParamsFromUrl = createSelector(
  selectPathname,
  selectViewType,
  selectViewPresetNo,
  selectViewSettings,
  (pathname, type, presetNo, settings) => {
    const { projectId, perspective, perspectiveId } = getMatchParams(pathname);
    const allSettings = {
      ...settings,
    };
    if (perspective && perspectiveId) {
      allSettings[getPerspectiveIdField(perspective)] = perspectiveId;
    }
    return {
      projectId,
      type,
      presetNo,
      perspective,
      settings: allSettings,
    };
  },
);

export const selectAllProjectDashboards = ProjectDashboardSelect.all()
  .forProject(selectProjectId)
  .sort({
    index: 1,
  });

export const selectGetViewParams = createSelector(
  ProjectDashboardSelect.all().forProject(selectProjectId).sort({
    index: 1,
  }),
  createGetViewParams,
);

export const selectCurrentViewParams = createSelector(
  selectCurrentViewParamsFromUrl,
  selectGetViewParams,
  (rawParams, getViewParams) => {
    return getViewParams(rawParams);
  },
);

export const selectCurrentViewUrl = createSelector(
  selectCurrentViewParams,
  getViewUrl,
);

export const getActivePatientId = store.get('activePatientId');
export const getActiveMilestoneId = store.get('activeMilestoneId');
export const getActiveActivityId = store.get('activeActivityId');
export const getMilestoneDialogActiveKey = store.get(
  'milestoneDialogActiveKey',
);
export const getMilestoneDialogVisible = store.get(
  'milestoneDialogVisible',
  toBoolean,
  false,
);

export const getActiveAnswersSheetId = store.get('activeAnswersSheetId');
export const getActiveEAPPId = store.get('activeEAPPId');
export const getActiveNoteId = store.get('activeNoteId');
export const getEditNoteDialogVisible = store.get(
  'editNoteDialogVisible',
  toBoolean,
  false,
);
export const getEditMilestoneDialogVisible = store.get(
  'editMilestoneDialogVisible',
  toBoolean,
  false,
);
export const getDownloadResponsesCSVDialogVisible = store.get(
  'downloadResponsesCSVDialogVisible',
  toBoolean,
  false,
);
export const getAnswersSheetDialogVisible = store.get(
  'answersSheetDialogVisible',
  toBoolean,
  false,
);
export const getEAPPDialogVisible = store.get(
  'EAPPDialogVisible',
  toBoolean,
  false,
);
export const getAddAnswersSheetsDialogVisible = store.get(
  'addAnswersSheetsDialogVisible',
  toBoolean,
  false,
);
export const getRemoveAnswersSheetDialogVisible = store.get(
  'removeAnswersSheetDialogVisible',
  toBoolean,
  false,
);
export const getPreviewPatientResponseDialogVisible = store.get(
  'previewPatientResponseDialogVisible',
  toBoolean,
  false,
);
export const getActiveAnswersSheet = AnswersSheetSelect.one().whereIdEquals(
  getActiveAnswersSheetId,
);
export const getActiveEAPP = EAPPSelect.one().whereIdEquals(getActiveEAPPId);

export const createGetSelectedVariant = (getCardId) =>
  createSelector(
    store.get('cards'),
    getCardId,
    (cards, id) => cards[id] && cards[id].chart,
  );

export const getActiveNote = createSelector(
  getActiveAnswersSheet,
  getActiveNoteId,
  (answersSheet, noteId) =>
    noteId &&
    answersSheet &&
    answersSheet.signedNotes &&
    answersSheet.signedNotes.find(({ id }) => id === noteId),
);

// Dialogs

export const getProjectProfileDialog = store.get('dialogs.projectProfile');

export const getEditActivityDialog = store.get('dialogs.editActivity');

export const getEditPatientDialog = store.get('dialogs.editPatient');

// Table

export const getCurrentPage = store.get('currentPage');
export const getNumberOfPatients = store.get('nPatients');
export const getPageSize = store.get('pageSize');
export const getNumberOfPages = createSelector(
  getPageSize,
  getNumberOfPatients,
  (pageSize, numberOfPatients) => {
    if (!pageSize || !numberOfPatients) {
      return 0;
    }
    return Math.ceil(numberOfPatients / pageSize);
  },
);
export const getCurrentPageIndex = createSelector(
  getCurrentPage,
  getNumberOfPages,
  (currentPage, numberOfPages) => {
    if (!currentPage || !numberOfPages) {
      return 0;
    }
    return Math.max(0, Math.min(currentPage - 1, numberOfPages - 1));
  },
);

export const selectAllCustomViews = createSelector(
  store.get('tabs'),
  selectProjectId,
  selectGetViewParams,
  (tabs, projectId, getViewParams) =>
    map(
      filter(tabs, {
        projectId,
      }),
      getViewParams,
    ),
);

export const selectAllCustomViewsWithFullHierarchy = higherOrderSelector(
  selectAllCustomViews,
  (views) => {
    return createSelector(
      ...map(views, (view) => {
        const { settings } = view;
        const answersSheetId = getPerspectiveId(
          DASHBOARD_PERSPECTIVE__RESPONSES,
          settings,
        );
        if (answersSheetId) {
          return createSelector(
            AnswersSheetSelect.one().whereIdEquals(answersSheetId),
            (answersSheet) => {
              return {
                ...view,
                hierarchy: {
                  answersSheetId,
                  ...pick(answersSheet, [
                    'activityId',
                    'participationId',
                    'recipientId',
                  ]),
                },
              };
            },
          );
        }
        const activityId = getPerspectiveId(
          DASHBOARD_PERSPECTIVE__ACTIVITIES,
          settings,
        );
        if (activityId) {
          return createSelector(
            ActivitySelect.one().whereIdEquals(activityId),
            (activity) => {
              return {
                ...view,
                hierarchy: {
                  activityId,
                  ...pick(activity, ['participationId', 'recipientId']),
                },
              };
            },
          );
        }
        const participationId = getPerspectiveId(
          DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
          settings,
        );
        if (participationId) {
          return createSelector(
            ParticipationSelect.one().whereIdEquals(participationId),
            (participation) => {
              return {
                ...view,
                hierarchy: {
                  participationId,
                  ...pick(participation, ['recipientId']),
                },
              };
            },
          );
        }
        const recipientId = getPerspectiveId(
          DASHBOARD_PERSPECTIVE__PATIENTS,
          settings,
        );
        if (recipientId) {
          return constant({
            ...view,
            hierarchy: {
              recipientId,
            },
          });
        }
        return constant(null);
      }),
      (...entities) => compact(entities),
    );
  },
);

export const selectAllPerspectiveResponsesDashboards = createSelector(
  ProjectDashboardSelect.all().forProject(selectProjectId),
  (dashboards) => {
    const dashboardsFiltered = filter(dashboards, (dashboard) =>
      hasSpecialization(dashboard.type, DASHBOARD_PERSPECTIVE__RESPONSES, {
        perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      }),
    );

    return groupBy(dashboardsFiltered, 'type');
  },
);

export const selectViewsTree = createSelector(
  selectAllCustomViewsWithFullHierarchy,
  selectAllProjectDashboards,
  createViewsTree,
);

export const selectAllVisibleViews = createSelector(
  selectViewsTree,
  (viewsTree) => {
    const views = [];
    forEachNode(viewsTree, (treeNode) => {
      if (treeNode.view) {
        views.push(treeNode.view);
      }
    });
    return views;
  },
);

export const cleanTree = (tree) => {
  if (!tree.children) {
    return tree;
  }
  // NOTE: We use flatMap, because cleanTree can sometimes return an array.
  let newChildren = flatMap(tree.children, cleanTree);
  if (newChildren.length === 1) {
    const { perspective, perspectiveId, title } = tree;
    const grandChild = newChildren[0];
    return {
      ...grandChild,
      hierarchy: [
        ...(grandChild.hierarchy || []),
        {
          title,
          perspective,
          perspectiveId,
        },
      ],
    };
  }
  for (
    let i = 0, byTitle = groupBy(newChildren, 'title');
    some(byTitle, (group) => size(group) > 1) &&
    some(newChildren, (child) => size(child.hierarchy) > i);
    i += 1, byTitle = groupBy(newChildren, 'title')
  ) {
    // eslint-disable-next-line no-loop-func
    newChildren = map(newChildren, (child) => {
      const { title, hierarchy } = child;
      if (hierarchy && i < hierarchy.length) {
        return {
          ...child,
          title: `${hierarchy[i].title} / ${title}`,
        };
      }
      return child;
    });
  }

  const childrenLeafs = filter(newChildren, (child) => !child.perspective);
  // NOTE: The root node will never have perspective field, which means
  //       that the root will never be reduced to an array.
  if (tree.perspective && childrenLeafs.length === 0) {
    const { perspective, perspectiveId, title } = tree;
    return map(newChildren, (child) => {
      return {
        ...child,
        hierarchy: [
          ...(child.hierarchy || []),
          {
            title,
            perspective,
            perspectiveId,
          },
        ],
      };
    });
  }
  return {
    ...tree,
    children: newChildren,
  };
};

export const selectViewsTreeWithTitles = higherOrderSelector(
  selectCurrentViewUrl,
  selectViewsTree,
  (currentViewUrl, viewsTree) => {
    const field = (name) => {
      return constant((x) => x && x[name]);
    };

    const createViewSelector = (tree) => {
      if (tree.view) {
        const { type, preset: { title = type } = {} } = tree.view;
        return constant({
          id: tree.view.url,
          active: tree.view.url === currentViewUrl,
          title,
          ...tree,
        });
      }
      const { perspective, perspectiveId } = tree;
      const selectChildren = createSelector(
        // NOTE: We are a tree-like selectors structure based on views tree.
        //       This may be extremely inefficient for large trees, but we
        //       are working under assumption that these trees will be relatively
        //       small in any reasonable situation.
        ...map(tree.children, createViewSelector),
        (...children) => children,
      );
      let selectTitle;
      switch (perspective) {
        case DASHBOARD_PERSPECTIVE__RESPONSES: {
          selectTitle = QuestionnaireSelect.one()
            .whereIdEquals(
              AnswersSheetSelect.one()
                .whereIdEquals(perspectiveId)
                .map(field('questionnaireId')),
            )
            .map(field('name'));
          break;
        }
        case DASHBOARD_PERSPECTIVE__ACTIVITIES: {
          selectTitle = ProjectMilestoneSelect.one()
            .whereIdEquals(
              ActivitySelect.one()
                .whereIdEquals(perspectiveId)
                .map(field('milestoneId')),
            )
            .map(field('name'));
          break;
        }
        case DASHBOARD_PERSPECTIVE__PARTICIPATIONS: {
          selectTitle = ParticipationSelect.one()
            .whereIdEquals(perspectiveId)
            .map(field('studyNo'));
          break;
        }
        case DASHBOARD_PERSPECTIVE__PATIENTS: {
          selectTitle = RecipientSelect.one()
            .whereIdEquals(perspectiveId)
            .map(
              constant((recipient) => {
                if (recipient) {
                  return describeRecipient(recipient);
                }
                return getTruncatedId(perspectiveId);
              }),
            );
          break;
        }
        default:
          selectTitle = perspectiveId
            ? constant(getTruncatedId(perspectiveId))
            : constant('');
      }
      return createSelector(selectTitle, selectChildren, (title, children) => {
        return {
          ...tree,
          id: perspective && `${perspective}:${perspectiveId}`,
          title,
          active: some(children, 'active'),
          children,
        };
      });
    };
    return createSelector(createViewSelector(viewsTree), cleanTree);
  },
);

export const selectCurrentTreeNode = createSelector(
  selectViewsTreeWithTitles,
  selectCurrentViewUrl,
  (viewsTree, currentViewUrl) => {
    return findTreeNode(
      viewsTree,
      (node) => node.view && node.view.url === currentViewUrl,
    );
  },
);
