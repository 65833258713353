import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProjectFilter from '../../../common/models/ProjectFilter';
import Select from '../../../common/components/Select';
import SelectCustom from '../Select';

const { Option } = Select;

const FilterSelect = ({ items, filter, onChange, values }) => {
  const { t } = useTranslation('forms');

  return (
    <SelectCustom
      key={filter.title}
      data-testid={`select-dropdown-${filter.title}`}
      onSelect={(value) => onChange(value, filter, true)}
      onDeselect={(value) => onChange(value, filter, false)}
      onChange={(value) => onChange(value, filter)}
      value={values}
      placeholder={t('filters.placeholder')}
      mode="multiple"
      showSearch
      allowClear
    >
      {items.map(({ value, label }) => (
        <Option
          data-testid={`option-${value}`}
          key={value}
          value={value}
          filter={filter}
        >
          {label}
        </Option>
      ))}
    </SelectCustom>
  );
};

FilterSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.instanceOf(ProjectFilter).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

FilterSelect.defaultProps = {
  items: [],
  values: [],
};

export default FilterSelect;
