/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import theming from 'styled-theming';
import styled, { css } from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import palette from '../../utilsClient/palette';
import Milestone from './Milestone';
// import { theme } from '../../utilsClient/cssHelpers';

const backgroundColor = theming('mode', {
  light: css`
    background-color: #fff7e6;
  `,
  dark: css`
    background-color: ${palette.yellow[40]};
    color: ${palette.gray[90]};
  `,
});

const StyledMilestone = styled(Milestone)`
  ${backgroundColor};
`;

const MilestoneDue = ({ ...props }) => {
  const { t } = useTranslation('components');

  return (
    <StyledMilestone
      status={t('Milestone.due')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default MilestoneDue;
