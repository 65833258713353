import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import {
  DASHBOARD_PERSPECTIVE__PATIENTS,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__DETAILS,
} from '../../common/constants';
import {
  getPerspective,
  getSpecialization,
  hasSpecialization,
} from './dashboards';

const getPreset = (dashboard) => {
  return {
    title: dashboard.title,
    settings: {
      dashboardId: dashboard._id,
      // TODO: Later on remove "meta"; we currently only use it
      //       because there are some projects in the db, which
      //       currently use "meta.src" and they're not migrated yet.
      ...cloneDeep(dashboard.meta),
      ...cloneDeep(dashboard.settings),
    },
  };
};

const createGetViewParams = (dashboards) => {
  const byType = {};
  const idToPresetNo = {};
  forEach(dashboards, (dashboard) => {
    const { _id, type } = dashboard;
    if (!byType[type]) {
      byType[type] = [];
    }
    idToPresetNo[_id] = byType[type].length + 1;
    byType[type].push(dashboard);
  });
  const getDashboard = ({
    type,
    settings,
    perspective,
    presetNo,
    dashboardId,
  }) => {
    return find(dashboards, (dashboard) => {
      if (
        (type && dashboard.type !== type) ||
        (dashboardId && dashboard._id !== dashboardId) ||
        (typeof presetNo === 'number' &&
          idToPresetNo[dashboard._id] !== presetNo)
      ) {
        return false;
      }
      if (
        perspective &&
        dashboard.perspective &&
        perspective !== dashboard.perspective
      ) {
        return false;
      }
      const specialization = getSpecialization(settings);
      if (perspective || dashboard.perspective) {
        if (
          !hasSpecialization(
            dashboard.type,
            perspective || dashboard.perspective,
            specialization,
          )
        ) {
          return false;
        }
      } else if (!getPerspective(dashboard.type, specialization)) {
        return false;
      }
      return true;
    });
  };
  return (rawParams) => {
    const specialization = getSpecialization(rawParams.settings);
    const dashboard = getDashboard(rawParams);
    if (dashboard) {
      return {
        ...rawParams,
        projectId: dashboard.projectId,
        type: dashboard.type,
        perspective:
          rawParams.perspective ||
          dashboard.perspective ||
          getPerspective(dashboard.type, specialization),
        presetNo: idToPresetNo[dashboard._id],
        preset: getPreset(dashboard),
      };
    }
    const {
      perspective = DASHBOARD_PERSPECTIVE__PATIENTS,
      type = specialization
        ? PROJECT_DASHBOARD_TYPE__DETAILS
        : PROJECT_DASHBOARD_TYPE__TIMELINE,
    } = rawParams;
    return {
      ...rawParams,
      type,
      perspective,
    };
  };
};

export default createGetViewParams;
