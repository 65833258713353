/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Milestone from './Milestone';

const MilestoneInProgress = ({ ...props }) => {
  const { t } = useTranslation('components');

  return (
    <Milestone
      status={t('Milestone.inProgress')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default MilestoneInProgress;
