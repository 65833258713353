import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { theme } from '../../utilsClient/cssHelpers';
import Alert from '../Alert';
import Sidebar from '../primitives/Sidebar';
import EnvironmentDetails from './EnvironmentDetails';
import { detailsPropTypes } from './propTypes';

const StyledAlert = styled(Alert)`
  padding-top: ${theme('rhythm.small')};
  padding-bottom: ${theme('rhythm.small')};
  z-index: 5;
`;

/** Use `EnvironmentAlert` to warn users.  */
const EnvironmentAlert = ({ type, children, details }) => {
  const message = (
    <Sidebar
      sidebar={details && <EnvironmentDetails details={details} />}
      justify="space-between"
    >
      <div>{children}</div>
    </Sidebar>
  );

  return <StyledAlert type={type} message={message} showIcon banner />;
};

EnvironmentAlert.propTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  children: PropTypes.node,
  details: detailsPropTypes.details,
};

EnvironmentAlert.defaultProps = {
  type: 'info',
  children: null,
  details: [],
};

export default EnvironmentAlert;
