import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import CurrentUserSelect from '../../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../../common/selectors/Project';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import PatientRecordSelect from '../../../../common/selectors/PatientRecord';
import CountSelect from '../../../../common/selectors/Count';
import {
  apiZedocSearchPatientRecords,
  projectMilestones,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import {
  getSorter,
  getQueryFilters,
  getSortingOrder,
  getSortingPresets,
  getCurrentSortingPresetId,
} from '../../../../store/ui/search/selectors';
import {
  setSorting,
  setSortingOrder,
} from '../../../../store/ui/search/actions';
import { dialog as removeMilestone } from '../RemoveMilestone';
import ProjectTable from './ProjectTable';
import {
  openTab,
  openMilestoneDialog,
  setCurrentPage,
  setPageSize,
  setNumberOfPatients,
  setActivePatientId,
} from '../../actions';
import {
  getCurrentPage,
  getNumberOfPatients,
  getPageSize,
  getCurrentPageIndex,
} from '../../selectors';
import { property } from '../../../../common/utilsClient/selectors';

const getProjectId = (state, { projectId }) => projectId;
const getProject = ProjectSelect.one().whereIdEquals(getProjectId);

const Container = compose(
  ddp({
    subscriptions: createSelector(
      property('projectId'),
      getQueryFilters,
      getSorter,
      getCurrentPageIndex,
      getPageSize,
      (projectId, filters, sorter, pageIndex, pageSize) => ({
        project:
          projectId &&
          apiZedocOneProject.withParams({
            projectId,
          }),
        milestones: projectMilestones.withParams({
          projectId,
        }),
        patients: apiZedocSearchPatientRecords.withParams({
          projectId,
          filters,
          sorter,
          controlId: '$meta.id',
          pageIndex,
          resultsPerPage: pageSize,
        }),
      }),
    ),
    selectors: ({ subscriptions }) => ({
      totalItems: createSelector(
        CountSelect.one().whereIdEquals(
          createSelector(
            subscriptions,
            (subs) =>
              `${apiZedocSearchPatientRecords.getName()}.${subs.patients.id}`,
          ),
        ),
        (doc) => get(doc, 'count', null),
      ),
      patients: PatientRecordSelect.all()
        .where(
          createSelector(
            createSelector(
              subscriptions,
              (subs) => `_pagination_${subs.patients.id}`,
            ),
            getCurrentPageIndex,
            (key, pageIndex) => (record) =>
              record[key] && record[key].pageIndex === pageIndex,
          ),
        )
        .sort(
          createSelector(
            createSelector(
              subscriptions,
              (subs) => `_pagination_${subs.patients.id}.index`,
            ),
            (key) => ({
              [key]: 1,
            }),
          ),
        ),
    }),
    renderLoader: null,
    subscriptionsUpdateDelay: 1000,
  }),
  connect(() => {
    const selectMilestones = createSelector(
      ProjectMilestoneSelect.all().forProject(property('projectId')),
      (milestones) => sortBy(milestones, ['daysSinceBaseline', 'createdAt']),
    );
    return createStructuredSelector({
      project: getProject,
      nPatients: getNumberOfPatients,
      pageSize: getPageSize,
      sortingOptions: getSortingPresets,
      currentSorting: getCurrentSortingPresetId,
      currentSortingOrder: getSortingOrder,
      currentPage: getCurrentPage,
      milestones: selectMilestones,
      validate: CurrentUserSelect.getCurrentPermissionsValidator(),
      currentUser: CurrentUserSelect.user(),
    });
  }),
  withHandlers({
    onSelectSorting:
      ({ dispatch }) =>
      (sorting) =>
        dispatch(setSorting(sorting)),
    onSelectSortingOrder:
      ({ dispatch }) =>
      (sortingOrder) =>
        dispatch(setSortingOrder(sortingOrder)),
    onRemoveMilestone:
      ({ dispatch }) =>
      (milestoneId) =>
        dispatch(
          removeMilestone.open({
            milestoneId,
          }),
        ),
    onPatientClick:
      ({ dispatch, projectId }) =>
      (label, patientId) => {
        dispatch(
          openTab({
            projectId,
            settings: {
              patientId,
            },
          }),
        );
      },
    onMilestoneClick:
      ({ dispatch }) =>
      ({ recipientId, milestoneId, activityId, activeKey }) =>
        dispatch(
          openMilestoneDialog({
            recipientId,
            milestoneId,
            activityId,
            activeKey,
          }),
        ),
    onPageChange:
      ({ dispatch }) =>
      (page) => {
        dispatch(setCurrentPage(page));
      },
    onShowSizeChange:
      ({ dispatch }) =>
      (current, size) =>
        dispatch(setPageSize(size)),
    onSetActivePatientId:
      ({ dispatch }) =>
      (recipientId) =>
        dispatch(setActivePatientId(recipientId)),
  }),
  lifecycle({
    componentDidMount() {
      const { dispatch, totalItems } = this.props;
      if (totalItems !== null) {
        dispatch(setNumberOfPatients(totalItems));
      }
    },
    componentDidUpdate(prevProps) {
      const { dispatch, totalItems } = this.props;
      if (totalItems !== null && totalItems !== prevProps.totalItems) {
        dispatch(setNumberOfPatients(totalItems));
      }
    },
  }),
)(ProjectTable);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default Container;
