// @ts-check
import { useRef, useState, useEffect } from 'react';

/**
 * It generates [state, setState] pair like useState, but
 * it also updates the state based on changes to value.
 *
 * The transforming functions MUST satisfy the following
 * invariants in order to prevent an infinite loop of updates:
 *
 * (1) valueToState(stateToValue(valueToState(value))) === valueToState(value)
 * (2) stateToValue(valueToState(stateToValue(state))) === stateToValue(state)
 *
 * @template V
 * @template S
 * @param {V} value
 * @param {(v: V) => void} onChange
 * @param {(s: S) => V | undefined} stateToValue
 * @param {(v: V) => S} valueToState
 */
const useDerivedState = (value, onChange, stateToValue, valueToState) => {
  const [state, setState] = useState(valueToState(value));

  const stateRef = useRef(state);
  const valueRef = useRef(value);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    const newState = valueToState(value);
    if (stateToValue(stateRef.current) !== stateToValue(newState)) {
      setState(newState);
    }
  }, [value, stateRef, valueToState, stateToValue]);

  useEffect(() => {
    const newValue = stateToValue(state);
    if (
      newValue !== undefined &&
      valueToState(newValue) !== valueToState(valueRef.current)
    ) {
      onChange(newValue);
    }
  }, [state, valueRef, valueToState, stateToValue, onChange]);

  return [state, setState];
};

export default useDerivedState;
