import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  grid-auto-rows: minmax(150px, 300px);
  grid-gap: 1px;
  grid-auto-flow: dense;

  /* borders */
  background-color: ${theme('color.surface')};
  box-shadow: ${theme('boxShadow.base')};

  /* bordered corners */
  border-radius: ${theme('border.radius.2')};
  overflow: hidden;
`;

const Grid = ({ children }) => {
  return <Component>{children}</Component>;
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Grid;
