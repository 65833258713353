import moment from 'moment';
import { EyeOutlined, ContainerOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../common/components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Cluster from '../../../../common/components/primitives/Cluster';
import Text from '../../../../common/components/base/Text';
import {
  openTab,
  openMilestoneDialog,
} from '../../../ProjectsDashboard/actions';
import {
  PARTICIPATION_STATE__CANCELED,
  PARTICIPATION_STATE__TERMINATED,
} from '../../../../common/constants';
import { PATIENT_VIEW_PATIENT_RESULTS } from '../../../../common/permissions';
import usePermission from '../../../../utils/usePermission';
import ProjectSettings from '../ProjectSettings';
import PatientRecordSelect from '../../../../common/selectors/PatientRecord';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';

const ProjectProfile =
  // ...
  ({
    recipientId,
    participationId,
    participationState,
    projectId,
    projectName,
  }) => {
    const { t } = useTranslation();

    const patientRecord = useSelector(
      PatientRecordSelect.one().where({
        participationId,
      }),
    );

    const firstActivity =
      patientRecord && patientRecord.getFirstRelevantActivity();

    let firstMilestoneId;
    let firstMilestoneJoinedOn;

    if (firstActivity) {
      firstMilestoneId = firstActivity.milestoneId;
      if (firstActivity.dateStart) {
        firstMilestoneJoinedOn = moment(firstActivity.dateStart).format(
          'DD/MM/YYYY',
        );
      }
    }

    const firstMilestone = useSelector(
      ProjectMilestoneSelect.one().whereIdEquals(firstMilestoneId),
    );

    let firstMilestoneName;
    if (firstMilestone) {
      firstMilestoneName = firstMilestone.name;
      if (!firstMilestoneJoinedOn) {
        const { dateStart } = firstMilestone.getBaselineSchedule(
          patientRecord.baseline,
        );
        firstMilestoneJoinedOn = moment(dateStart).format('DD/MM/YYYY');
      }
    }

    const dispatch = useDispatch();
    const onInsightsClick = useCallback(() => {
      // NOTE: We are not changing query here, so it will lead us to patient charts most likely.
      dispatch(
        openTab({
          projectId,
          settings: {
            patientId: recipientId,
          },
        }),
      );
      const activity =
        patientRecord.getNearestActivity() || patientRecord.getLastActivity();
      if (activity) {
        const { activityId, milestoneId } = activity;
        dispatch(
          openMilestoneDialog({
            activityId,
            milestoneId,
            recipientId: patientRecord.getRecipientId(),
          }),
        );
      }
    }, [dispatch, projectId, recipientId, patientRecord]);

    const canViewPatientResults = usePermission([PATIENT_VIEW_PATIENT_RESULTS]);

    const history = useHistory();
    const onResponsesClick = () =>
      history.push(`/patients/${recipientId}/projects/${projectId}`);

    return (
      <Sidebar
        sidebar={
          <Cluster>
            <ProjectSettings
              projectId={projectId}
              recipientId={recipientId}
              participationId={participationId}
            />
            <Button
              data-testid="button-insights"
              icon={<EyeOutlined />}
              onClick={onInsightsClick}
              disabled={
                participationState === PARTICIPATION_STATE__TERMINATED ||
                participationState === PARTICIPATION_STATE__CANCELED
              }
            >
              {t('insights')}
            </Button>
            <Button
              data-testid="button-responses"
              type="link"
              icon={<ContainerOutlined />}
              onClick={onResponsesClick}
            >
              {t('response', {
                count: 0,
              })}
            </Button>
          </Cluster>
        }
      >
        <Stack space={0}>
          {canViewPatientResults ? (
            <Text.Link
              data-testid={`button-to-${projectName}`}
              to={`/projects/${projectId}`}
              size="large"
              importance="high"
            >
              {projectName}
            </Text.Link>
          ) : (
            <Text.Span size="large" importance="high">
              {projectName}
            </Text.Span>
          )}
          <Text.Paragraph>
            {participationState &&
              `${t('forms:state.label')}: ${t(
                `stateMachines:Participation.states.${participationState.toLowerCase()}`,
              )}`}
            {firstMilestoneJoinedOn && (
              <>
                {'; '}
                <Text.Span importance="low">
                  {t('projects:joined.on')}
                </Text.Span>
                <Text.Span importance="high">
                  {' '}
                  {firstMilestoneJoinedOn}{' '}
                </Text.Span>
              </>
            )}
            {firstMilestoneJoinedOn && firstMilestoneName && (
              <>
                <Text.Span importance="low">
                  {t('projects:joined.to')}
                </Text.Span>
                <Text.Span importance="high">
                  {' '}
                  {`'${firstMilestoneName}'`}
                </Text.Span>
              </>
            )}
          </Text.Paragraph>
        </Stack>
      </Sidebar>
    );
  };

ProjectProfile.propTypes = {
  recipientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  participationState: PropTypes.string.isRequired,
};

ProjectProfile.defaultProps = {
  projectName: null,
};

export default ProjectProfile;
