import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { compose, lifecycle, setDisplayName } from 'recompose';
import DDPConnector, { DDPProvider } from '@theclinician/ddp-connector';
import ConfigProvider from 'antd/es/config-provider';
import { notifyError } from '../../utils/notify';
import Loading from '../../common/components/Loading';
import Empty from '../../common/components/base/Empty';
import Router from '../../routes/Router';
import ThemeProvider from '../ThemeProvider';
import LanguageProvider from '../LanguageProvider';
import { ModalContainer } from '../../utils/modal';
import tracker from '../../utils/tracker';

const propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  persistor: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ddpConnector: PropTypes.instanceOf(DDPConnector).isRequired,
};

const subscribe = (emitter, name, listener) => {
  emitter.on(name, listener);
  return () => {
    emitter.removeListener(name, listener);
  };
};

const App = compose(
  lifecycle({
    componentDidMount() {
      // catch all errors that are not likely to be captured by explicitly
      this.listeners = [
        // NOTE: We don't want implicit loginErrors, e.g. "No login token"
        // subscribe(this.props.ddpConnector, 'loginError', notifyError()),
        subscribe(this.props.ddpConnector, 'resumeLoginError', notifyError()),
        subscribe(this.props.ddpConnector, 'logoutError', notifyError()),
        subscribe(this.props.ddpConnector, 'error', notifyError()),
      ];
    },
    componentWillUnmount() {
      this.listeners.forEach((stop) => stop());
    },
  }),
  setDisplayName('App'),
)(({ store, history, persistor, ddpConnector }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DDPProvider ddpConnector={ddpConnector}>
        <ThemeProvider>
          <ConfigProvider renderEmpty={() => <Empty />}>
            <ModalContainer>
              <Suspense fallback={<Loading />}>
                <LanguageProvider>
                  <Router history={tracker.connectToHistory(history)} />
                </LanguageProvider>
              </Suspense>
            </ModalContainer>
          </ConfigProvider>
        </ThemeProvider>
      </DDPProvider>
    </PersistGate>
  </Provider>
));

App.propTypes = propTypes;

export default App;
