import { connect } from 'react-redux';
import { compose } from 'redux';
import { ddp } from '@theclinician/ddp-connector';
import { createStructuredSelector } from 'reselect';
import {
  sendPasswordResetEmail,
  logout,
} from '../../common/utilsClient/ddp/actions';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import { notifyError, notifySuccess } from '../../utils/notify';
import AlreadySignedIn from '../../components/plates/AlreadySignedIn';
import makeEntryRoute from './makeEntryRoute';

const EntryRoute = makeEntryRoute(
  compose(
    ddp({
      subscriptions: [
        {
          name: 'Users.current',
          params: [],
        },
      ],
    }),
    connect(
      createStructuredSelector({
        currentUser: CurrentUserSelect.user(),
      }),
      (dispatch) => ({
        onLogout: () => dispatch(logout()),
        onReset: () =>
          dispatch(sendPasswordResetEmail())
            .then(notifySuccess('Email sent!'))
            .catch(notifyError()),
      }),
    ),
  )(AlreadySignedIn),
);

export default EntryRoute;
