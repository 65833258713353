import { createSelector } from 'reselect';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import has from 'lodash/has';
import {
  SORTING_ORDER__ASCENDING,
  FILTER_TYPE__PROPERTY,
  DEFAULT_PROJECT_FILTERS,
} from '../../../common/constants';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectFilter from '../../../common/models/ProjectFilter';
import store from './store';
import { selectQueryParam } from '../../router';
import toSelector from '../../../common/utils/toSelector';
import { escapeRegExp } from '../../../common/utils/text';

const identity = (x) => x;

const defaultTextFilter = find(DEFAULT_PROJECT_FILTERS, (filter) =>
  has(filter.defaultState, 'text'),
);

export const getFilters = store.get('filters');
export const getSorting = store.get('sorting');
export const getSortingOrder = store.get(
  'sortingOrder',
  identity,
  SORTING_ORDER__ASCENDING,
);

const getCurrentProject = ProjectSelect.one().whereIdEquals(
  store.get('projectId'),
);
const getCurrentMode = store.get('mode');

const getTextFilter = createSelector(getCurrentProject, (project) => {
  const projectFilter = find(project && project.getFilters(), (filter) =>
    has(filter.defaultState, 'text'),
  );
  if (projectFilter) {
    return projectFilter;
  }
  if (defaultTextFilter) {
    return defaultTextFilter;
  }
  return {
    type: FILTER_TYPE__PROPERTY,
    settings: {
      id: 'recipientNameTerms',
    },
    tags: ['directory', 'dashboard'],
  };
});

export const getSearchPhrase = selectQueryParam('search');
export const getSearchRegEx = createSelector(
  getSearchPhrase,
  (search) => search && new RegExp(escapeRegExp(search), 'i'),
);

const getFiltersState = createSelector(
  getFilters,
  getTextFilter,
  getSearchPhrase,
  (filters, textFilter, search) => ({
    ...filters,
    ...(textFilter && {
      [textFilter.id]: {
        text: search || '',
      },
    }),
  }),
);

export const createGetProjectFilters = (getOptions) =>
  createSelector(
    getCurrentProject,
    getCurrentMode,
    getFiltersState,
    getTextFilter,
    getSearchPhrase,
    toSelector(getOptions),
    (project, mode, filtersState, textFilter, text, options) =>
      project
        ? project.getBoundFilters(
            {
              ...filtersState,
              ...(options && options.filtersStateOverride),
            },
            {
              includeAllTags:
                options && options.useAnalyticsFilters
                  ? [mode, 'charts']
                  : [mode],
            },
          )
        : [
            new ProjectFilter({
              ...textFilter,
              state: {
                text,
              },
            }),
          ],
  );

export const getProjectFilters = createGetProjectFilters();

export const createGetQueryFilters = (selectOptions) =>
  createSelector(createGetProjectFilters(selectOptions), (filters) => {
    const queryFilters = [];
    forEach(filters, ({ id, type, state, settings, collation }) => {
      if (state && state.text) {
        forEach(state.text.split(/\s+/), (text) => {
          queryFilters.push({
            id,
            type,
            settings,
            collation,
            state: {
              text,
            },
          });
        });
      } else {
        queryFilters.push({
          id,
          type,
          settings,
          collation,
          state,
        });
      }
    });
    return queryFilters;
  });

export const getQueryFilters = createGetQueryFilters();

export const getSortingPresets = createSelector(
  getCurrentProject,
  getCurrentMode,
  (project, mode) => {
    if (!project) {
      return [];
    }
    const presets = project ? project.getSortingOptions() : [];
    switch (mode) {
      case 'directory':
        return presets.filter((p) => !!p.useInDirectory);
      case 'dashboard':
        return presets.filter((p) => !!p.useInDashboard);
      default:
        return presets;
    }
  },
);

export const getSortingPresetsById = createSelector(
  getSortingPresets,
  (presets) => keyBy(presets, 'id'),
);

export const getDefaultPresetId = createSelector(
  getCurrentProject,
  (project) => project && project.sortingPresetDefaultId,
);

export const getPreferredSortingPresetId = createSelector(
  getSortingPresetsById,
  getDefaultPresetId,
  store.get('sorting'),
  (presetsById, defaultPresetId, presetId) => {
    if (presetId && presetsById[presetId]) {
      return presetId;
    }
    if (defaultPresetId && presetsById[defaultPresetId]) {
      return defaultPresetId;
    }
    return null;
  },
);

export const getCurrentSortingPresetId = createSelector(
  getPreferredSortingPresetId,
  getSortingPresets,
  (presetId, presets) => {
    if (
      presetId &&
      find(presets, {
        id: presetId,
      })
    ) {
      return presetId;
    }
    if (presets.length > 0) {
      return presets[0].id;
    }
    return null;
  },
);

export const getCurrentSortingPreset = createSelector(
  getSortingPresetsById,
  getCurrentSortingPresetId,
  (presetsById, presetId) => presetsById[presetId],
);

export const getSorter = createSelector(
  getCurrentSortingPreset,
  getSortingOrder,
  (preset, order) => {
    if (!preset) {
      return null;
    }
    const { type, settings, collation } = preset;
    return {
      type,
      collation,
      order,
      settings,
    };
  },
);
