import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDDPSubscription } from '@theclinician/ddp-connector';

import { callMethod } from '../../../../common/utilsClient/ddp/actions';
import Modal from '../../../../components/dialogs/Modal';
import Form from './Form';
import {
  getOpenRoleDialogVisible,
  setEditUserDialogVisible,
  setSelectedRole,
  getSelectedRole,
} from '../../store';
import { notifyError, notifySuccess } from '../../../../utils/notify';
import {
  apiAdminCreateRole,
  apiAdminOneRole,
} from '../../../../common/api/admin';

const DialogCreateRole = () => {
  const { t } = useTranslation();

  const open = useSelector(getOpenRoleDialogVisible);

  const dispatch = useDispatch();
  const onCancel = useCallback(() => {
    dispatch(setEditUserDialogVisible(false));
  }, [dispatch]);

  const history = useHistory();
  const storedRole = useSelector(getSelectedRole);
  const onCreate = useCallback(() => {
    dispatch(
      callMethod(apiAdminCreateRole, {
        name: storedRole.name,
        belongsTo: storedRole.belongsTo,
      }),
    )
      .then(({ inserted: id }) => {
        const roleCopy = {
          ...storedRole,
          _id: id,
          permissions: [],
        };
        dispatch(setSelectedRole(roleCopy));
        dispatch(setEditUserDialogVisible(false));
        history.push(`/settings/roles/${id}`);
        notifySuccess(t('confirmations:addRole.success'));
      })
      .catch(notifyError());
  }, [dispatch, storedRole, history, t]);

  const { ready: isReady } = useDDPSubscription(
    storedRole &&
      storedRole._id &&
      apiAdminOneRole.withParams({
        roleId: storedRole._id,
      }),
  );

  return (
    <Modal
      title={t('addRole')}
      okText={t('create')}
      visible={open}
      isOkDisabled={!isReady}
      onOk={onCreate}
      onCancel={onCancel}
    >
      <Form />
    </Modal>
  );
};

export default DialogCreateRole;
