import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../common/components/Button';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Grid from '../../../common/components/primitives/Grid';
import Search from '../../../components/inputs/Search';
import Select from '../../../components/inputs/Select';
import {
  setActiveDomain,
  getActiveDomain,
  setEditUserDialogVisible,
} from '../store';

const UsersGroupsFilters = ({ domains }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDomainChange = useCallback(
    (value) => dispatch(setActiveDomain(value)),
    [dispatch],
  );

  const onAdd = useCallback(
    () => dispatch(setEditUserDialogVisible(true)),
    [dispatch],
  );

  const activeDomain = useSelector(getActiveDomain);
  return (
    <Sidebar
      sidebar={
        <Button
          data-testid="button-add-user"
          type="primary"
          onClick={onAdd}
          disabled={false}
        >
          {t('addRole')}
        </Button>
      }
    >
      <Grid>
        <Search />
        <Select
          data-testid="select-projects"
          className="zedoc-tour__filter"
          options={[
            {
              value: '',
              label: t('all'),
            },
            ...domains,
          ]}
          onChange={onDomainChange}
          value={activeDomain}
          showSearch
          allowClear
        />
      </Grid>
    </Sidebar>
  );
};

UsersGroupsFilters.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

UsersGroupsFilters.defaultProps = {
  domains: [],
};

export default UsersGroupsFilters;
