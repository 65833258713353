import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import { ORGANIZATION_REVIEW_QUESTIONNAIRE } from '../../permissions';

// SUBSCRIPTIONS

export const one = new ApiSpec({
  name: 'api.collections.Questionnaires2.one',
  permissions: [ORGANIZATION_REVIEW_QUESTIONNAIRE],
  schema: Schema.IdentifierWithVersion,
});

export const questionnaireTranslation = new ApiSpec({
  name: 'api.collections.Questionnaires2.translation',
  permissions: [ORGANIZATION_REVIEW_QUESTIONNAIRE],
  schema: new Schema({
    translationId: Schema.QuestionnaireTranslationIdentifier,
  }),
});

export const latestVersion = new ApiSpec({
  name: 'api.collections.Questionnaires2.latestVersion',
  permissions: [ORGANIZATION_REVIEW_QUESTIONNAIRE],
  schema: new Schema({
    questionnaireId: Schema.IdentifierWithVersionRange,
  }),
});
