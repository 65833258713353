import { registerStore } from '../../common/utilsClient/multiReducer';

export const PATIENTS_PER_PAGE = 10;

const store = registerStore({
  path: 'ui.screens.ProjectsDashboard',
  initialState: {
    nPatients: PATIENTS_PER_PAGE,
    pageSize: PATIENTS_PER_PAGE,
    currentPage: 1,
    cards: {},
    dashboards: {},
    dialogs: {},
    activePatientId: null,
    activeMilestoneId: null,
    activeActivityId: null,
    activeAnswersSheetId: null,
    activeNoteId: null,
    editNoteDialogVisible: false,
    editMilestoneDialogVisible: false,
    milestoneDialogVisible: false,
    milestoneDialogActiveKey: 'questionnaires',
    answersSheetDialogVisible: false,
    remindersDialogVisible: false,
    addAnswersSheetsDialogVisible: false,
    removeAnswersSheetDialogVisible: false,
    previewPatientResponseDialogVisible: false,
    tabs: [],
  },
});

export default store;
