import PropTypes from 'prop-types';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import React from 'react';
import Question from '../../../../common/models/Question';
import ChartCard from '../../../../components/plates/ChartCard';
import { CATChartConfig2, CATChartConfig2Graph } from './config';

const AmCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "AmCharts" */ '../../../../common/components/AmCharts'
  ),
);

const getGraphs = (question) => {
  if (!question) {
    return [];
  }
  return map(question.getChoices(), (choice, index) => ({
    ...CATChartConfig2Graph,
    title: choice.label,
    valueField: index.toString(),
  }));
};

/*
const takeEveryNth = (array, step) => {
  const result = [];
  for (let i = 0; i < array.length; i += step) {
    result.push(array[i]);
  }
  return result;
};
*/

const cleanDataProvider = (dataProvider) =>
  map(dataProvider, (entry) =>
    mapValues(entry, (value) => value.toPrecision(2)),
  );

const Chart2 = ({ dataProvider, nextQuestion, nextQuestionTitle }) => (
  <ChartCard
    title="ICC Curve"
    description={(nextQuestion && nextQuestion.getTitle()) || nextQuestionTitle}
    queriesReady
  >
    <AmCharts
      options={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...CATChartConfig2,
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...getGraphs(nextQuestion),
        dataProvider: cleanDataProvider(dataProvider),
      }}
    />
  </ChartCard>
);

Chart2.propTypes = {
  nextQuestionTitle: PropTypes.string,
  nextQuestion: PropTypes.instanceOf(Question),
  dataProvider: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

Chart2.defaultProps = {
  nextQuestionTitle: null,
  nextQuestion: null,
  dataProvider: null,
};

export default Chart2;
