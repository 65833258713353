import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { Remove, Add } from 'styled-icons/material';
import Button from '../Button';
import Input from '../Input';
import {
  getSubtractedValue,
  getIncreasedValue,
  formatToString,
  formatToNumber,
} from './helpers';
import useDerivedState from '../../utilsClient/useDerivedState';

const Component = styled(Input)`
  /* TODO: I encountered a specificity issue with InputWithSuffix */
  && {
    padding-right: 0;
    padding-left: 0;

    display: inline-flex;
    width: auto;
  }

  input {
    text-align: center;
    width: 4rem;

    /* Hide input number up/down arrows */
    -moz-appearance: textfield !important;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;

const InputNumber = forwardRef(
  (
    {
      'data-testid': datatestid,
      id,
      value,
      onChange,
      size,
      min,
      max,
      step,
      precision,
      placeholder,
      disabled,
      autoFocus,
    },
    ref,
  ) => {
    const stateToValue = useCallback(
      (x) => formatToNumber(x, precision),
      [precision],
    );

    const valueToState = useCallback(
      (x) => formatToString(x, precision),
      [precision],
    );

    const [state, setState] = useDerivedState(
      value,
      onChange,
      stateToValue,
      valueToState,
    );

    const handleOnSubtract = () => {
      const newValue = getSubtractedValue({
        value,
        max,
        min,
        step,
        precision,
      });
      setState(valueToState(newValue));
    };

    const handleOnIncrease = () => {
      const newValue = getIncreasedValue({
        value,
        max,
        min,
        step,
        precision,
      });
      setState(valueToState(newValue));
    };

    const handleOnChange = (event) => {
      setState(event.target.value);
    };

    const handleOnBlur = () => {
      // NOTE: This essentially mean: force cleaning state, i.e.
      //       apply precision constraints. Please note that it will
      //       not have any impact on the actual value because of:
      //       stateToValue * valueToState * stateToValue = stateToValue.
      setState(valueToState(stateToValue(state)));
    };

    return (
      <Component
        ref={ref}
        data-testid={datatestid}
        id={id}
        type="number"
        inputMode="decimal"
        size={size}
        value={state}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        prefix={
          <Button
            type="link"
            icon={<Remove />}
            onClick={handleOnSubtract}
            disabled={disabled}
          />
        }
        suffix={
          <Button
            type="link"
            icon={<Add />}
            onClick={handleOnIncrease}
            disabled={disabled}
          />
        }
      />
    );
  },
);

InputNumber.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
};

InputNumber.defaultProps = {
  'data-testid': null,
  id: null,
  value: null,
  onChange: () => {},
  size: 'default',
  disabled: false,
  autoFocus: false,
  placeholder: null,
  min: null,
  max: null,
  step: 1,
  precision: 0,
};

export default InputNumber;
