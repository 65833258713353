import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { YEAR_MONTH_DAY } from '../../../common/constants';
import ProjectSelect from '../../../common/selectors/Project';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import InputDate from '../../../common/components/InputDate';
import Modal from '../../../components/dialogs/Modal';
import FormItem from '../../../common/components/FormItem';
import settings from '../../../common/settings';
import { getResumeToken } from '../../../common/utilsClient/ddp/selectors';
import getExportFileName from '../../../common/utils/getExportFileName';
import { slugify } from '../../../common/utils/formatting';
import { notifyError, notifySuccess } from '../../../utils/notify';
import { apiZedocOneProject } from '../../../common/api/zedoc';
import { closeDownloadResponsesCSVDialog } from '../actions';
import { getDownloadResponsesCSVDialogVisible } from '../selectors';
import {
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../../../common/constants/filters';

const CreateActivity = ({ projectId }) => {
  useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const isVisible = useSelector(getDownloadResponsesCSVDialogVisible);
  const [dateStart, setDateStart] = useState(
    moment().subtract(90, 'days').format(YEAR_MONTH_DAY),
  );
  const [dateEnd, setDateEnd] = useState('');

  const resumeToken = useSelector(getResumeToken);

  const isStartDateAfterEndDate = useMemo(
    () => moment(dateStart).isAfter(dateEnd),
    [dateStart, dateEnd],
  );
  const isEndDateBeforeStartDate = useMemo(
    () => moment(dateEnd).isBefore(dateStart),
    [dateStart, dateEnd],
  );

  const handleOnCancel = () => dispatch(closeDownloadResponsesCSVDialog());
  const handleOnConfirm = () => {
    if (isEndDateBeforeStartDate || isStartDateAfterEndDate) {
      return;
    }

    const timezone = project && project.getTimezone();
    const filters = [];

    if (dateStart) {
      filters.push({
        type: 'property',
        condition: FILTER_CONDITION__DATE_SAME_OR_AFTER,
        settings: {
          id: 'completedAt',
        },
        state: {
          threshold: dateStart,
          timezone,
        },
      });
    }

    if (dateEnd) {
      filters.push({
        type: 'property',
        condition: FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        settings: {
          id: 'completedAt',
        },
        state: {
          threshold: dateEnd,
          timezone,
        },
      });
    }

    const { backendUrl } = settings.public;
    const url = `${backendUrl}/integrations/responses/projects/${project._id}`;
    // eslint-disable-next-line consistent-return
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        filters,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': resumeToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        return response
          .json()
          .then(({ error, reason }) =>
            Promise.reject(new Error(`[${error}] ${reason}`)),
          );
      })
      .then((blob) =>
        saveAs(blob, getExportFileName(slugify(project.getName()), 'zip')),
      )
      .then(notifySuccess('Exported Project'))
      .catch(notifyError());
    handleOnCancel();
  };

  return (
    <Modal
      title={t('confirmations:downloadResponsesCSV.title')}
      tooltip={t('confirmations:downloadResponsesCSV.tooltip')}
      onCancel={handleOnCancel}
      footer={
        <>
          <Button
            data-testid="button-ok"
            type="primary"
            disabled={isEndDateBeforeStartDate || isStartDateAfterEndDate}
            onClick={handleOnConfirm}
          >
            {t('confirmAndDownload')}
          </Button>
        </>
      }
      visible={isVisible}
    >
      <Stack>
        <FormItem
          label={t('forms:dateStart.label')}
          help={
            isEndDateBeforeStartDate &&
            t('forms:dateStart.errorCannotBeAfterEndDate')
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-start-date"
            onChange={setDateStart}
            value={dateStart}
            max={dateEnd}
          />
        </FormItem>
        <FormItem
          label={t('forms:dateEnd.label')}
          help={
            isStartDateAfterEndDate &&
            t('forms:dateEnd.errorCannotBeBeforeStartDate')
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-end-date"
            onChange={setDateEnd}
            value={dateEnd}
            min={dateStart}
          />
        </FormItem>
        {project && (
          <Text.Paragraph>
            * {project.timezone} {t('forms:timezone.label')}
          </Text.Paragraph>
        )}
      </Stack>
    </Modal>
  );
};

CreateActivity.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default CreateActivity;
