import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '../../../../common/components/primitives/Box';
import Text from '../../../../common/components/base/Text';
import Button from '../../../../common/components/Button';
import List from '../../../../common/components/List';
import Modal from '../../../../components/dialogs/Modal';
import SignedNote from '../../../../common/models/SignedNote';

const propTypes = {
  open: PropTypes.bool,
  notes: PropTypes.arrayOf(PropTypes.instanceOf(SignedNote)),
  userNames: PropTypes.objectOf(PropTypes.string),
  onAddNote: PropTypes.func,
  onClose: PropTypes.func,
};

const noop = () => {};
const defaultProps = {
  open: false,
  notes: [],
  userNames: {},
  onAddNote: noop,
  onClose: noop,
};

const NotesModal = ({ open, notes, userNames, onAddNote, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('forms:note.label', {
        count: 0,
      })}
      footer={
        <React.Fragment>
          <Button onClick={onClose} hasBorder={false}>
            {t('cancel')}
          </Button>
          <Button type="primary" onClick={onAddNote}>
            {t('addNote')}
          </Button>
        </React.Fragment>
      }
      visible={open}
      onCancel={onClose}
    >
      {notes.length > 0 ? (
        <Box.Primary>
          <List
            dataSource={notes}
            renderItem={(note, idx) => (
              <div key={idx}>
                <Text.Paragraph importance="high">
                  {note.renderTitle(userNames)}
                </Text.Paragraph>
                <Text.Paragraph>{note.renderContent(userNames)}</Text.Paragraph>
              </div>
            )}
          />
        </Box.Primary>
      ) : (
        t('forms:note.none')
      )}
    </Modal>
  );
};

NotesModal.propTypes = propTypes;
NotesModal.defaultProps = defaultProps;

export default NotesModal;
