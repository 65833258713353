import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiZedocProjectVariables } from '../common/api/zedoc';
import VariableSelect from '../common/selectors/Variable';

const constant = (x) => () => x;

const useProjectVariables = (projectId, allowedScopes) => {
  const { ready: variablesReady } = useDDPSubscription(
    projectId &&
      apiZedocProjectVariables.withParams({
        projectId,
      }),
  );

  const empty = useMemo(() => [], []);

  const variables = useSelector(
    useMemo(() => {
      if (!projectId) {
        return constant([]);
      }
      return VariableSelect.all()
        .satisfying((variable) => {
          return !!(
            variable &&
            variable[`_project_${projectId}`] &&
            variable[`_project_${projectId}`].index >= 0
          );
        })
        .sort({
          [`_project_${projectId}.index`]: 1,
        });
    }, [projectId]),
  );

  const allowedVariables = useMemo(() => {
    if (!allowedScopes) {
      return variables;
    }
    return filter(variables, (variable) => {
      return includes(allowedScopes, variable.scopeName);
    });
  }, [variables, allowedScopes]);

  if (!variablesReady) {
    return empty;
  }

  return allowedVariables;
};

export default useProjectVariables;
