import reduce from 'lodash/reduce';
import React from 'react';
import Text from '../base/Text';
import Divider from '../Divider';
import Popover from '../Popover';
import Button from '../Button';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Clipboard from '../Clipboard/index';
import { detailsPropTypes, detailsDefaultProps } from './propTypes';

const EnvironmentDetails = ({ details }) => {
  const text = reduce(
    details,
    (string, { label, value }) => string.concat(`**${label}:** ${value} \n\n`),
    `**Current URL:** ${window.location.href} \n\n`,
  );
  const content = (
    <Stack space={2}>
      <Stack space={0}>
        {details.map(({ label, value }) => (
          <Text.Paragraph key={value} size="small">
            <Text.Span importance="high">{label}</Text.Span>
            {': '}
            <Text.Span>{value}</Text.Span>
          </Text.Paragraph>
        ))}
      </Stack>
      <Divider />
      <Cluster justify="space-between">
        <Clipboard text={text} />
        <Text.Link
          href="https://theclinician.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          size="small"
        >
          Report Bug
        </Text.Link>
      </Cluster>
    </Stack>
  );

  return (
    <Popover content={content} placement="bottomRight" trigger="click">
      <Button type="link" size="small" importance="high">
        <Text.Span size="small">Details</Text.Span>
      </Button>
    </Popover>
  );
};

EnvironmentDetails.propTypes = detailsPropTypes;
EnvironmentDetails.defaultProps = detailsDefaultProps;

export default EnvironmentDetails;
