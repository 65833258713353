import { createSelector } from 'reselect';
import createSelectors from './createSelectors';
import Report from '../models/Report';

const Select = createSelectors(Report, {
  forProject: (getProjectId) => (select) =>
    select.where(
      createSelector(
        getProjectId,
        (projectId) => (report) => report.getProjectId() === projectId,
      ),
    ),
});

export default Select;
