import React from 'react';
import PropTypes from 'prop-types';
import {
  QUESTION_MEDIA_TYPE__VIDEO,
  QUESTION_MEDIA_TYPE__AUDIO,
  QUESTION_MEDIA_TYPE__IMAGE,
} from '../constants';
import VideoPlayer from './VideoPlayer';
import AudioPlayer from './AudioPlayer';
import Image from './Image';

const FormMedia = ({ mediaType, mediaUrl }) => {
  switch (mediaType) {
    case QUESTION_MEDIA_TYPE__VIDEO: {
      return <VideoPlayer src={mediaUrl} />;
    }
    case QUESTION_MEDIA_TYPE__AUDIO: {
      return <AudioPlayer src={mediaUrl} />;
    }
    case QUESTION_MEDIA_TYPE__IMAGE: {
      return <Image src={mediaUrl} alt={mediaType} />;
    }
    default:
      return null;
  }
};

FormMedia.propTypes = {
  mediaType: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string,
};

FormMedia.defaultProps = {
  mediaUrl: null,
};

export default FormMedia;
