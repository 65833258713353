import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { MoreHoriz } from 'styled-icons/material';
import Text from '../../../../common/components/base/Text';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Cluster from '../../../../common/components/primitives/Cluster';
import Stack from '../../../../common/components/primitives/Stack';
import Menu from '../../../../common/components/Menu';
import Dropdown from '../../../../common/components/Dropdown';
import Avatar from '../../../../common/components/Avatar';
import Button from '../../../../common/components/Button';
import Icon from '../../../../common/components/Icon';
import PatientRecord from '../../../../common/models/PatientRecord';
import { SearchHighlighter } from '../../../../components/inputs/Search';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../../../common/permissions';
import usePermission from '../../../../utils/usePermission';

const propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  shouldHideMeta: PropTypes.bool,
};

const defaultProps = {
  patientRecord: null,
  actions: [],
  shouldHideMeta: false,
};

const StyledWrapper = styled.div`
  word-break: break-word;
`;

const StyledStack = styled(Stack)`
  overflow: hidden;
`;

const PatientProfile = ({
  patientRecord: patientRecordOrNothing,
  actions,
  shouldHideMeta,
}) => {
  const patientRecord = patientRecordOrNothing || new PatientRecord({});

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: patientRecord && patientRecord.getDomains(),
  });

  const getName = () => {
    if (!canSeePII) {
      return patientRecord.getTruncatedRecipientId();
    }

    return (
      patientRecord.getFullName() ||
      patientRecord.getIdentifier() ||
      patientRecord.getPrimaryIdentifier() ||
      patientRecord.getStudyNo() ||
      patientRecord.getTruncatedRecipientId()
    );
  };

  const display = patientRecord.getFullName() || patientRecord.getIdentifier();

  return (
    <Sidebar
      data-testid={`profile-${display}`}
      space={2}
      sidebar={
        <Dropdown
          overlay={
            <Menu>
              {actions.map(({ title, onClick, disabled }, i) => (
                <Menu.Item
                  data-testid={`profile-menu-item-${title}`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={onClick}
                  disabled={disabled}
                >
                  {title}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            data-testid="button-profile-menu"
            type="link"
            icon={<MoreHoriz />}
          />
        </Dropdown>
      }
    >
      <StyledWrapper>
        <Cluster space={2} wrap="nowrap">
          <div
            style={
              shouldHideMeta
                ? {
                    opacity: 0,
                    pointerEvents: 'none',
                  }
                : {}
            }
          >
            <Avatar kind="bold">{patientRecord.getAge() || '?'}</Avatar>
          </div>
          <div
            style={
              shouldHideMeta
                ? {
                    opacity: 0,
                    pointerEvents: 'none',
                  }
                : {}
            }
          >
            <Icon type={patientRecord.getGenderIcon()} />
          </div>
          <StyledStack space={0}>
            <div
              style={
                shouldHideMeta
                  ? {
                      display: 'none',
                    }
                  : {}
              }
            >
              <Text.Link
                data-testid={`button-patient-${display}`}
                to={`/patients/${patientRecord.recipientId}`}
                importance="high"
              >
                <SearchHighlighter text={getName()} />
              </Text.Link>
            </div>
            <SearchHighlighter text={patientRecord.getStudyNo()} />
          </StyledStack>
        </Cluster>
      </StyledWrapper>
    </Sidebar>
  );
};

PatientProfile.propTypes = propTypes;
PatientProfile.defaultProps = defaultProps;

export default PatientProfile;
