import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

// TODO: Clean up

const defaultBaseColor = 'hsla(0, 0%, 0%, 0.15)';
const defaultHighlightColor = 'hsla(0, 0%, 0%, 0.3)';
const animation = keyframes`
  from {
    transform: translateX(-75%);
  }
  to {
    transform: translateX(0);
  }
`;

const Component = styled.span`
  display: inline-block;
  height: ${(props) =>
    props.height
      ? isNumber(props.height)
        ? `${props.height}px`
        : props.height
      : '1em'};
  width: ${(props) =>
    props.width
      ? isNumber(props.width)
        ? `${props.width}px`
        : props.width
      : '100%'};
  overflow: hidden;
  border-radius: ${(props) => (props.circle ? '50%' : '4px')};
  vertical-align: middle;

  &::before {
    content: '';
    width: 400%;
    height: 100%;
    display: block;
    background-image: linear-gradient(
      90deg,
      ${defaultBaseColor},
      ${defaultBaseColor},
      ${defaultHighlightColor},
      ${defaultBaseColor},
      ${defaultBaseColor}
    );
    animation: ${animation} 1.5s ease-in-out infinite;
    pointer-events: none;
  }
`;

const Skeleton = ({ height, width, circle }) => (
  <Component height={height} width={width} circle={circle} />
);

Skeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
};

Skeleton.defaultProps = {
  height: null,
  width: null,
  circle: false,
};

export default Skeleton;
