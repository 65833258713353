import flatten from 'flat';
import forEach from 'lodash/forEach';
import { getErrorMessage } from '../utils/Schema';
import checkSchema, {
  getAllErrors,
  getErrorMessage as getJsonSchemaErrorMessage,
} from '../utils/checkSchema';

export class ValidationError extends Error {
  constructor(errors, reason) {
    super(reason);
    this.error = 'ValidationError';
    this.reason = reason;
    this.details = errors;
  }
}

export const makeSchemaValidator = (schema, Error = ValidationError) => {
  if (!schema) {
    return () => {};
  }
  return (value) => {
    const error = schema.getErrors(value);
    if (error) {
      const errors = [];
      const described = schema.describe(error);
      const reason = getErrorMessage(described);
      if (typeof described === 'object') {
        const fields = flatten(described);
        Object.keys(fields).forEach((name) => {
          errors.push({
            name,
            type: fields[name],
          });
        });
      }
      throw new Error(errors, reason);
    }
  };
};

export const makeJsonSchemaValidator = (
  jsonSchema,
  Error = ValidationError,
) => {
  if (!jsonSchema) {
    return () => {};
  }
  return (value) => {
    const error = checkSchema(jsonSchema, value);
    if (error) {
      const errors = [];
      const allErrors = getAllErrors(error);
      forEach(allErrors, ({ key, message }) => {
        errors.push({
          name: key,
          type: message,
        });
      });
      throw new Error(errors, getJsonSchemaErrorMessage(error));
    }
  };
};
