import styled from 'styled-components/macro';
import includes from 'lodash/includes';
import React from 'react';
import PropTypes from 'prop-types';
import ProjectFilter from '../../../common/models/ProjectFilter';
import Stack from '../../../common/components/primitives/Stack';
import Checkbox from '../../../common/components/Checkbox';

const StyledCheckbox = styled(Checkbox)`
  ${({ theme: { font } }) => `
    .ant-checkbox + span {
      font-weight: ${font.weight.bold};
    }
  `}
`;

const FilterCheckbox = ({ items, filter, onChange, values }) => {
  return (
    <Stack space={0}>
      {items.map(({ value, label }) => (
        // TODO: Remove extra div after removing antd checkboxes
        <div>
          <StyledCheckbox
            key={value}
            value={value}
            filter={filter}
            checked={includes(values, value)}
            onChange={({ target }) =>
              onChange(target.value, target.filter, target.checked)
            }
          >
            {label}
          </StyledCheckbox>
        </div>
      ))}
    </Stack>
  );
};

FilterCheckbox.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.instanceOf(ProjectFilter).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

FilterCheckbox.defaultProps = {
  items: [],
  values: [],
};

export default FilterCheckbox;
