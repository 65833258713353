import {
  ReloadOutlined,
  PauseOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Section } from '../../../screens/FormsSandbox/components/SingleScreenQuestionnaire';
import Questionnaire from '../../../common/containers/Questionnaire';
import { enterFormValues } from '../../../common/containers/Questionnaire/actions';
import QuestionnaireModel from '../../../common/models/Questionnaire';
import Button from '../../../common/components/Button';
import PageHeader from '../../../common/components/PageHeader';
import Divider from '../../../common/components/Divider';
import Box from '../../../common/components/primitives/Box';
import Center from '../../../common/components/primitives/Center';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import SubmitButton from './SubmitButton';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  overflow-y: auto;
  background-color: #f2f2f4;
  z-index: 1001;
  display: flex;
  flex-direction: column;
`;

const TakeQuestionnaire = ({
  open,
  answersSheetId,
  questionnaire,
  variables,
  handleSubmit,
  handlePause,
  handleClose,
  handleUndo,
  handleSubmissionError,
}) => {
  const name = answersSheetId;

  const dispatch = useDispatch();
  const onTransferDataFromWearable = useCallback(
    (formValues) => dispatch(enterFormValues(name, formValues)),
    [dispatch, name],
  );

  if (!open || !answersSheetId || !questionnaire) {
    return null;
  }
  return (
    <Wrapper>
      <PageHeader
        title={questionnaire.getName()}
        extra={
          <Cluster>
            <Button
              data-testid="button-reload-questionnaire"
              onClick={handleUndo}
              size="small"
              icon={<ReloadOutlined />}
            />
            <Button
              data-testid="button-pause-questionnaire"
              onClick={handlePause}
              size="small"
              icon={<PauseOutlined />}
            />
            <Button
              data-testid="button-close-questionnaire"
              onClick={handleClose}
              size="small"
              icon={<CloseOutlined />}
            />
          </Cluster>
        }
      />
      <Box>
        <Center>
          <Questionnaire
            questionnaire={questionnaire}
            name={name}
            answersSheetId={answersSheetId}
            variables={variables}
          >
            <Stack>
              <Section
                questionnaire={questionnaire}
                onTransferDataFromWearable={onTransferDataFromWearable}
              />
              <Divider>
                <SubmitButton
                  onSubmit={handleSubmit}
                  onSubmissionError={handleSubmissionError}
                />
              </Divider>
            </Stack>
          </Questionnaire>
        </Center>
      </Box>
    </Wrapper>
  );
};

TakeQuestionnaire.propTypes = {
  open: PropTypes.bool.isRequired,
  answersSheetId: PropTypes.string,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel),
  variables: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  handleUndo: PropTypes.func.isRequired,
  handlePause: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmissionError: PropTypes.func.isRequired,
};

TakeQuestionnaire.defaultProps = {
  answersSheetId: null,
  questionnaire: null,
  variables: null,
};

export default TakeQuestionnaire;
