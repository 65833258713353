import map from 'lodash/map';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../common/components/Button';
import Input, { InputGroup } from '../../common/components/Input';
import AutoComplete from '../../common/components/AutoComplete';
import { getSearchTerms } from './utils';

const toLowerCase = (str) => str && str.toLowerCase();

const filterOption = (inputValue, option) => {
  if (typeof option.label !== 'string' || typeof inputValue !== 'string') {
    return false;
  }
  const searchTerms = map(getSearchTerms(inputValue), toLowerCase);
  if (searchTerms.length === 0) {
    return true;
  }
  if (searchTerms.length === 1) {
    return option.label.toLowerCase().indexOf(searchTerms[0]) >= 0;
  }
  const tokens = map(getSearchTerms(option.label), toLowerCase);
  return (
    tokens.length >= 2 &&
    tokens[0].indexOf(searchTerms[0]) >= 0 &&
    tokens[1].indexOf(searchTerms[1]) >= 0
  );
};

const Search = React.forwardRef(
  ({ options, value, active, onChange, onSelect, onFocus }, forwardedRef) => {
    const { t } = useTranslation();

    return (
      <InputGroup>
        <AutoComplete
          ref={forwardedRef}
          options={options}
          value={value}
          onSearch={onChange}
          onSelect={onSelect}
          onFocus={onFocus}
          filterOption={filterOption}
          defaultActiveFirstOption
        >
          <Input data-testid="filters-search-input" placeholder={t('search')} />
        </AutoComplete>
        <Button
          type={active ? 'primary' : null}
          icon={<SearchOutlined />}
          disabled={!active}
          data-testid="filters-search-button"
        />
      </InputGroup>
    );
  },
);

Search.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
};

Search.defaultProps = {
  options: [],
  value: null,
  active: false,
  onChange: null,
  onSelect: null,
  onFocus: null,
};

export default Search;
