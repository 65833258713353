import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';
import { PROJECT_REVIEW_DASHBOARD } from '../permissions';

export const userDetails = new ApiSpec({
  name: 'Users.current',
  permissions: [],
  schema: new Schema(),
});

export const userRoles = new ApiSpec({
  name: 'Roles.current',
  permissions: [],
  schema: new Schema(),
});

export const apiCurrentUserPermissionsRealm = new ApiSpec({
  name: 'api.currentUser.permissionsRealm',
  permissions: [],
  schema: {
    type: 'object',
    required: ['permissions'],
    properties: {
      scope: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      permissions: {
        type: 'array',
        items: {
          type: 'string',
        },
        minCount: 1,
      },
    },
  },
});

export const checkUserCredentials = new ApiSpec({
  name: 'api.currentUser.checkUserCredentials',
  isPublic: true,
  schema: new Schema({
    login: {
      type: String,
    },
    hashedPassword: {
      type: new Schema({
        digest: String,
        algorithm: String,
      }),
    },
  }),
});

export const resumeTokenKeepAlive = new ApiSpec({
  name: 'api.currentUser.resumeTokenKeepAlive',
  permissions: [],
  schema: new Schema({
    resumeToken: {
      type: String,
    },
    mapLocation: {
      type: new Schema({
        type: {
          type: String,
          allowedValues: ['Point'],
        },
        coordinates: {
          type: [Number],
          decimal: true,
          minCount: 0,
          maxCount: 2,
        },
      }),
      optional: true,
    },
  }),
});

// NOTE: We are simply wrapping a method that's implemented in Meteor internals.
export const forgotPassword = new ApiSpec({
  name: 'forgotPassword',
  schema: new Schema({
    email: {
      type: String,
      regEx: Schema.RegEx.Email,
    },
  }),
});

export const removeUserSession = new ApiSpec({
  name: 'api.currentUser.removeUserSession',
  schema: new Schema({
    hashedToken: {
      type: String,
    },
  }),
});

export const removeOtherSessions = new ApiSpec({
  name: 'api.currentUser.removeOtherSessions',
  schema: new Schema({}),
});

export const isLicenseValid = new ApiSpec({
  name: 'api.currentUser.isLicenseValid',
  schema: new Schema({}),
  isPublic: true,
});

export const setNewPassword = new ApiSpec({
  name: 'api.currentUser.setNewPassword',
  schema: new Schema({
    hashedPassword: {
      type: Schema.HashedPassword,
    },
    // old password is not required for replacing temporary password
    hashedOldPassword: {
      type: Schema.HashedPassword,
      optional: true,
    },
  }),
});

export const validateNewPassword = new ApiSpec({
  name: 'api.currentUser.validateNewPassword',
  schema: new Schema({
    hashedPassword: {
      type: Schema.HashedPassword,
    },
  }),
});

export const isUsingTemporaryPassword = new ApiSpec({
  name: 'api.currentUser.isUsingTemporaryPassword',
  schema: new Schema({}),
});

export const setOptions = new ApiSpec({
  name: 'api.currentUser.setOptions',
  permissions: [],
  schema: new Schema({
    onboarded: {
      type: Boolean,
      optional: true,
    },
  }),
});

export const generateLoginToken = new ApiSpec({
  name: 'api.currentUser.generateLoginToken',
  permissions: [],
  schema: new Schema({
    baseUrl: {
      type: String,
      optional: true,
    },
  }),
});

export const dashboardFilters = new ApiSpec({
  name: 'api.currentUser.dashboardFilters',
  resources: [PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const updateUserDashboard = new ApiSpec({
  name: 'api.currentUser.updateUserDashboard',
  resources: [PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['dashboardId', 'savedFilters'],
    properties: {
      dashboardId: {
        type: 'string',
      },
      savedFilters: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'label', 'filtersJSONString'],
          properties: {
            id: {
              type: 'string',
            },
            label: {
              type: 'string',
            },
            filtersJSONString: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});
