import trim from 'lodash/trim';

// eslint-disable-next-line import/prefer-default-export
export const getSearchTerms = (inputValue) => {
  if (!inputValue) {
    return [];
  }
  const tokens = inputValue.split(/\s*.?=\s*/);
  if (tokens.length < 2 && inputValue.charAt(0) === ':') {
    return [trim(inputValue.substr(1)), ''];
  }
  return tokens;
};
