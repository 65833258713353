import React, { Suspense, lazy } from 'react';

const LazyFormFieldSignature = lazy(() =>
  import(/* webpackChunkName: "FormFieldSignature" */ './FormFieldSignature'),
);

export default (props) => (
  <Suspense fallback={<div>loading...</div>}>
    <LazyFormFieldSignature {...props} />
  </Suspense>
);
