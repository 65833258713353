import moment from 'moment';
import forEach from 'lodash/forEach';
import BaseModel from './BaseModel';
import { isPrefix } from '../utils/text';

class Role extends BaseModel {
  constructor(doc) {
    super(doc);
    this.permissions = this.permissions || [];

    const permissionsByKey = {};
    this.permissions.forEach((p) => {
      // NOTE: Permission tier can overwrite global role tier
      permissionsByKey[p.key] = {
        tier: p.tier || this.tier || 0,
      };
      if (p.scope) {
        permissionsByKey[p.key].scope = p.scope;
      }
    });
    Object.defineProperty(this, 'permissionsByKey', {
      value: permissionsByKey,
    });
  }

  mergePermissionsInto(permissionsByKey, scope) {
    forEach(this.permissionsByKey, (permission, key) => {
      if (permission.scope) {
        // Scoped permission can only be applied in scoped queries.
        if (!isPrefix(permission.scope)(scope)) {
          return;
        }
      }
      // eslint-disable-next-line no-param-reassign
      permissionsByKey[key] = Math.max(
        permission.tier,
        permissionsByKey[key] || 0,
      );
    });
    return permissionsByKey;
  }

  hasPermission(key, { tier } = {}) {
    if (!this.permissionsByKey[key]) {
      return false;
    }
    if (tier === undefined || tier === null) {
      return true;
    }
    if (typeof tier === 'number') {
      return this.permissionsByKey[key].tier >= tier;
    }
    return false;
  }

  getDomain() {
    return this.belongsTo;
  }

  getName() {
    return this.name;
  }

  getDescription() {
    const parts = this.belongsTo.split('/');
    return `${this.name} (${parts[parts.length - 2]})`;
  }

  getReference() {
    return {
      id: this._id,
      tier: this.tier,
      name: this.name,
      tagsNames: this.tags ? this.tags.map((x) => x.name) : [],
      appliesTo: this.getDomain(),
    };
  }

  // NOTE: This method can be overwritten on server.
  static getRolesDB() {
    return {};
  }

  formatCreatedAt() {
    // eslint-disable-next-line
    return !this.createdAt
      ? ''
      : moment(this.createdAt).format('MMM Do YYYY hh:mm A');
  }

  static roleHasPermission({
    roleId,
    tier,
    permission,
    rolesDB = this.getRolesDB(),
  }) {
    const role = rolesDB[roleId];
    return (
      !!role &&
      role.hasPermission(permission, {
        tier,
      })
    );
  }

  static extractFlatPermissions({
    rolesIds = [],
    rolesDB = this.getRolesDB(),
    scope,
  }) {
    const flatCopy = {};
    rolesIds.forEach((id) => {
      const role = rolesDB[id];
      if (role) {
        role.mergePermissionsInto(flatCopy, scope);
      }
    });
    return flatCopy;
  }
}

Role.collection = 'Roles';

export default Role;
