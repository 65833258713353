import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SubmissionError } from 'redux-form';
import { compose } from 'recompose';
import { sendPasswordResetEmail } from '../../common/utilsClient/ddp/actions';
import { notifySuccess } from '../../utils/notify';
import { getIsLoading } from '../../store/ui/entry/selectors';
import { setIsLoading } from '../../store/ui/entry/actions';
import ForgotForm from './ForgotForm';

const Forgot = ({ onSubmit, isLoading }) => (
  <ForgotForm form="entry" onSubmit={onSubmit} isLoading={isLoading} />
);

Forgot.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ email }) => {
    dispatch(setIsLoading(true));

    dispatch(sendPasswordResetEmail(email.trim()))
      .then(() => dispatch(setIsLoading(false)))
      .then(notifySuccess('Email sent!'))
      .catch((err) => {
        throw new SubmissionError({
          _error: err.message,
        });
      });
  },
});

const DecoratedForgot = compose(
  connect(
    createStructuredSelector({
      isLoading: getIsLoading,
    }),
    mapDispatchToProps,
  ),
)(Forgot);

export default DecoratedForgot;
