import isEmpty from 'lodash/isEmpty';
import {
  // PlusSquareOutlined,
  MinusSquareOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Sidebar from '../primitives/Sidebar';
import Text from '../base/Text';
// import Empty from '../base/Empty';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { extractCubicBezierValues } from '../../utilsClient/cssHelpers';
import theme from '../../utilsClient/theme';

const CollectionItem = ({ itemKey, label, onRemove, isCompact, children }) => {
  const { t } = useTranslation();

  const handleOnRemove = () => (onRemove ? onRemove(itemKey) : {});

  return isCompact ? (
    <Sidebar
      sidebar={
        <Tooltip title={t('remove')}>
          <Button
            data-testid="collection-button-remove"
            type="danger"
            icon={<MinusSquareOutlined />}
            onClick={handleOnRemove}
            ghost
          />
        </Tooltip>
      }
    >
      <div>{children}</div>
    </Sidebar>
  ) : (
    <Stack space={1}>
      <Cluster justify="space-between">
        {label && <Text.Span>{label}</Text.Span>}
        {onRemove && (
          <Tooltip title="Remove">
            <Button
              data-testid="collection-button-remove"
              type="danger"
              icon={<MinusSquareOutlined />}
              onClick={handleOnRemove}
              ghost
            />
          </Tooltip>
        )}
      </Cluster>
      <div>{children}</div>
    </Stack>
  );
};

CollectionItem.propTypes = {
  itemKey: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  isCompact: PropTypes.bool,
  children: PropTypes.node,
};

CollectionItem.defaultProps = {
  itemKey: null,
  label: null,
  onRemove: () => {},
  isCompact: false,
  children: null,
};

const Collection = ({
  'data-testid': testId,
  title,
  items,
  addTitle,
  // emptyDescription,
  onAdd,
  onRemove,
  isAnimated,
  isCompact,
}) => {
  const { t } = useTranslation();

  // TODO: Get duration from theme
  const duration = 0.24;

  const variants = isAnimated
    ? {
        initial: {
          x: '-50%',
          opacity: 0,
          transition: {
            duration,
          },
        },
        exit: {
          x: '-50%',
          opacity: 0,
          transition: {
            ease: extractCubicBezierValues(theme.motion.easing.expressive.exit),
            duration,
          },
        },
        animate: {
          x: 0,
          opacity: 1,
          transition: {
            ease: extractCubicBezierValues(
              theme.motion.easing.expressive.entrance,
            ),
            duration,
          },
        },
      }
    : {};

  const positionTransition = {
    ease: extractCubicBezierValues(theme.motion.easing.standard),
  };

  return (
    <Stack space={2}>
      <Cluster justify="space-between">
        {title}
        {onAdd && (
          <Button
            data-testid={`collection-${testId}-add`}
            type="primary"
            // icon={<PlusSquareOutlined />}
            onClick={onAdd}
            // ghost
          >
            {addTitle || t('add')}
          </Button>
        )}
      </Cluster>
      {/* TODO: Remove empty div after moving Empty away from ant design */}
      {/* TODO: Animate first added / last removed items */}
      {isEmpty(items) ? (
        <div>{/* <Empty description={emptyDescription} /> */}</div>
      ) : (
        <AnimatePresence initial={false}>
          {items.map((item) => (
            <motion.div
              key={item.key}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants}
              positionTransition={positionTransition}
            >
              <CollectionItem
                itemKey={item.key}
                label={item.label}
                onRemove={onRemove}
                isCompact={isCompact}
              >
                {item.children}
              </CollectionItem>
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </Stack>
  );
};

Collection.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      children: PropTypes.node,
      label: PropTypes.string,
    }),
  ),
  addTitle: PropTypes.string,
  // emptyDescription: PropTypes.node,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  isAnimated: PropTypes.bool,
  isCompact: PropTypes.bool,
};

Collection.defaultProps = {
  'data-testid': 'item',
  title: null,
  items: [],
  addTitle: null,
  // emptyDescription: null,
  onAdd: null,
  onRemove: null,
  isAnimated: true,
  isCompact: false,
};

export default Collection;
