import { createStructuredSelector } from 'reselect';
import createSelectors from './createSelectors';
import Activity from '../models/Activity';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';
import omitPIIFields from '../utils/omitPIIFields';

const Select = createSelectors(
  Activity,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (rawActivity, { piiRealm, variablesDb } = {}) => {
      if (PermissionsDomain.belongsToRealm(rawActivity.ownership, piiRealm)) {
        return rawActivity;
      }
      const newActivity = omitPIIFields(
        rawActivity,
        Activity.scopeName,
        variablesDb,
      );
      return newActivity;
    },
  },
);

export default Select;
