import omitBy from 'lodash/omitBy';
import Variable from '../models/Variable';

const omitPIIFields = (doc, scopeName, variablesDb) => {
  let newDoc = {
    ...doc,
  };
  if (scopeName) {
    newDoc = omitBy(doc, (value, key) => {
      const variable = new Variable({
        scopeName,
        nativeKey: key,
      });
      return variable.isPII();
    });
  }
  if (newDoc.variables) {
    newDoc.variables = omitBy(newDoc.variables, (value, key) => {
      const variable = variablesDb && variablesDb[key];
      return !variable || variable.isPII();
    });
  }
  return newDoc;
};
export default omitPIIFields;
