import { createGlobalStyle, css } from 'styled-components/macro';
import globalStyles from '../common/utilsClient/globalStyles';
import { media } from '../common/utilsClient/cssHelpers';

const mediaStyles = css`
  ${media.greaterThan('mobile')`
    html {
      font-size: 100%;
    }
  `}
`;

const GlobalStyle = createGlobalStyle`
  ${globalStyles};

  #root {
    height: 100%;
  }
  
  /* Media */

  ${mediaStyles};
`;

export default GlobalStyle;
