import throttle from 'lodash/throttle';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withInterval from './withInterval';
import { resumeTokenKeepAlive } from '../utilsClient/ddp/actions';
import { USER_SESSION_KEEP_ALIVE_SECONDS } from '../constants';

const constant = (x) => () => x;
const dispatchKeepAlive = throttle(
  (dispatch, condition) => dispatch(resumeTokenKeepAlive(condition)),
  1000 * USER_SESSION_KEEP_ALIVE_SECONDS,
);

const withSessionKeepAlive = ({
  defaultCondition = constant(true),
  additionalAction,
} = {}) =>
  compose(
    connect(null, (dispatch) => ({
      dispatch,
    })),
    withInterval({
      msDelay: 1000 * USER_SESSION_KEEP_ALIVE_SECONDS,
      runInitially: true,
      onInterval({ dispatch, isInitialRun, condition = defaultCondition }) {
        // NOTE: Only send a keep alive message if it's the initial run or if the specified condition was met
        dispatchKeepAlive(
          dispatch,
          (state) => isInitialRun || !condition || condition(state),
        );
        if (additionalAction) {
          additionalAction(dispatch);
        }
      },
    }),
  );

export default withSessionKeepAlive;
