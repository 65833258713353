/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';
import Skeleton from '../Skeleton';

const LazyMarkdown = lazy(() =>
  import(/* webpackChunkName: "Markdown" */ './Markdown'),
);

const Markdown = (props) => (
  <Suspense
    fallback={
      <Skeleton
        active
        title={false}
        paragraph={{
          rows: 1,
        }}
      />
    }
  >
    <LazyMarkdown {...props} />
  </Suspense>
);

['h1', 'h2', 'h3', 'h4', 'li', 'span'].forEach((Tag) => {
  Markdown[Tag] = ({ source, className, 'data-testid': testId }) => (
    <Markdown source={source} inline>
      {(innerHtml) => (
        // eslint-disable-next-line react/no-danger
        <Tag
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
          className={className}
          data-testid={testId}
        />
      )}
    </Markdown>
  );
});

export default Markdown;
