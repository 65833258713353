import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../common/components/base/Text';
import UserProfile from '../../common/components/UserProfile';
import PatientRecord from '../../common/models/PatientRecord';
import { SearchHighlighter } from '../inputs/Search';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../common/permissions';
import usePermission from '../../utils/usePermission';

const propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
};

const defaultProps = {
  patientRecord: null,
  actions: [],
};

const PatientProfile = ({ patientRecord: patientRecordOrNothing, actions }) => {
  const patientRecord = patientRecordOrNothing || new PatientRecord({});

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: patientRecord && patientRecord.getDomains(),
  });

  const getName = () => {
    if (!canSeePII) {
      return patientRecord.getTruncatedRecipientId();
    }

    return (
      patientRecord.getFullName() ||
      patientRecord.getIdentifier() ||
      patientRecord.getPrimaryIdentifier() ||
      patientRecord.getStudyNo() ||
      patientRecord.getTruncatedRecipientId()
    );
  };

  const display = patientRecord.getFullName() || patientRecord.getIdentifier();
  return (
    <UserProfile
      data-testid={`profile-${display}`}
      age={patientRecord.getAge() || '?'}
      gender={patientRecord.getGenderIcon()}
      studyNo={<SearchHighlighter text={patientRecord.getStudyNo()} />}
      name={
        <Text.Link
          data-testid={`button-patient-${display}`}
          to={`/patients/${patientRecord.recipientId}`}
          importance="high"
        >
          <SearchHighlighter text={getName()} />
        </Text.Link>
      }
      actions={actions}
      testid={
        patientRecord.getFullName() ||
        patientRecord.getIdentifier() ||
        patientRecord.getPrimaryIdentifier() ||
        patientRecord.getTruncatedRecipientId()
      }
    />
  );
};

PatientProfile.propTypes = propTypes;
PatientProfile.defaultProps = defaultProps;

export default PatientProfile;
