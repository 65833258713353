import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';
import Button from '../Button';

const Component = styled.li`
  list-style: none;

  min-width: 32px;
  height: 32px;
  text-align: center;

  > button {
    width: 100%;
    padding: 0 ${theme('space.1')};
  }
`;

const Pager = ({ page, current, onChange }) => {
  const handlePageChange = () => onChange(page);

  return (
    <Component>
      <Button
        type={page === current ? 'primary' : 'default'}
        size="small"
        onClick={handlePageChange}
        isAnimated={false}
      >
        {page}
      </Button>
    </Component>
  );
};

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  current: PropTypes.number,
  onChange: PropTypes.func,
};

Pager.defaultProps = {
  current: null,
  onChange: () => {},
};

export default Pager;
