import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector } from 'react-redux';
import ProjectDashboardSelect from '../../../../common/selectors/ProjectDashboard';
import { apiAnalyticsDataProvidersForDashboard } from '../../../../common/api/analytics';
import Stack from '../../../../common/components/primitives/Stack';
import ChartGrid from '../../../../common/components/Chart/Grid';
import ChartCard from '../../../../components/plates/ChartCard';
import ConnectedFilters from './ConnectedFilters';
import { FILTER_TYPE__PROPERTY } from '../../../../common/constants';
import Chart from './Chart';
import { createGetFilters } from './store';

const DashboardAnalytics = ({ dashboardId, recipientId, projectId }) => {
  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => map(rawFilters, (filter) => omit(filter, 'meta')),
    [rawFilters],
  );
  const { id: subscriptionId, ready: subscriptionReady } = useDDPSubscription(
    apiAnalyticsDataProvidersForDashboard.withParams({
      projectId,
      dashboardId,
      controlId: '$meta.id',
      filters,
    }),
  );
  const { id: recipientSubscriptionId, ready: recipientSubscriptionReady } =
    useDDPSubscription(
      recipientId &&
        apiAnalyticsDataProvidersForDashboard.withParams({
          projectId,
          dashboardId,
          controlId: '$meta.id',
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'recipientId',
              },
              state: {
                include: [recipientId],
              },
            },
          ],
        }),
    );
  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  return (
    <Stack>
      <ConnectedFilters
        storeKey={`dashboards.${dashboardId}`}
        projectId={projectId}
        dashboardId={dashboardId}
      />
      <ChartGrid>
        {map(dashboard && dashboard.charts, (chart) => {
          const {
            id,
            type,
            title,
            description,
            settings,
            detailedViewTransformation,
          } = chart;
          const chartId = chart.id;
          return (
            <ChartCard
              key={chart.id}
              title={title}
              description={description}
              loading={!(subscriptionReady && recipientSubscriptionReady)}
            >
              <Chart
                key={id}
                type={type}
                settings={settings}
                chartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${subscriptionId}.${chartId}`}
                recipientChartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${recipientSubscriptionId}.${chartId}`}
                detailedViewTransformation={detailedViewTransformation}
              />
            </ChartCard>
          );
        })}
      </ChartGrid>
    </Stack>
  );
};

DashboardAnalytics.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
};

DashboardAnalytics.defaultProps = {
  recipientId: null,
};

export default DashboardAnalytics;
