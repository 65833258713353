import { createStructuredSelector, createSelector } from 'reselect';
import {
  lifecycle,
  branch,
  compose,
  renderNothing,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import TakeQuestionnaire from './TakeQuestionnaire';
import { selectQueryParam, setQueryParam } from '../../../store/router';
import {
  currentSessions,
  answersSheetAndQuestionnaire,
  heartbeat,
  pauseAndSaveDraft,
  complete,
  undoLastChanges,
} from '../../../common/api/forms';
import { ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS } from '../../../common/constants';
import Loading from '../../../common/components/Loading';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { reconcilingSelector } from '../../../common/utilsClient/selectors';
import {
  forceAutosave,
  syncFormValues,
} from '../../../common/containers/Questionnaire';
import { notifyError } from '../../../utils/notify';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import AnswersSheetSelect from '../../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../../common/selectors/Questionnaire';
import AnswersSheetSessionSelect from '../../../common/selectors/AnswersSheetSession';

const identity = (x) => x;

const getAnswersSheetId = selectQueryParam('takeQuestionnaire');

const handleComponentDidUpdate = (props, prevProps) => {
  const { onRestoreSession, handleKeepAlive, sessionId, abandonedSessionId } =
    props;

  if (sessionId && sessionId !== prevProps.sessionId) {
    handleKeepAlive();
  }

  if (
    abandonedSessionId &&
    abandonedSessionId !== prevProps.abandonedSessionId
  ) {
    onRestoreSession();
  }
};

const Container = compose(
  // NOTE: Here the presence of answersSheetId indicates whether the modal
  //       dialog should be visible or not; even if we didn't not use
  //       "renderNothing" would still get away with using just one branch(),
  //       however, the ddp() hooks might run some of the subscriptions prematurely.
  connect(() => {
    return createStructuredSelector({
      answersSheetId: getAnswersSheetId,
    });
  }),
  branch((props) => !!props.answersSheetId, identity, renderNothing),
  ddp({
    renderLoader: null,
    subscriptions: createSelector(getAnswersSheetId, (answersSheetId) => [
      {
        name: 'Users.current',
        params: [],
      },
      answersSheetId &&
        currentSessions.withParams({
          answersSheetId,
        }),
      answersSheetId &&
        answersSheetAndQuestionnaire.withParams({
          answersSheetId,
        }),
    ]),
    selectors: () => {
      const getAnswersSheet =
        AnswersSheetSelect.one().whereIdEquals(getAnswersSheetId);
      return {
        open: createSelector(
          getAnswersSheetId,
          (answersSheetId) => !!answersSheetId,
        ),
        isLoggedIn: CurrentUserSelect.isLoggedIn(),
        answersSheet: AnswersSheetSelect.one().whereIdEquals(getAnswersSheetId),
        answersSheetId: getAnswersSheetId,
        questionnaire: QuestionnaireSelect.one().whereIdEquals(
          createSelector(
            getAnswersSheet,
            (answersSheet) => answersSheet && answersSheet.getQuestionnaireId(),
          ),
        ),
        variables: reconcilingSelector(
          AnswersSheetSessionSelect.one().where({
            userId: CurrentUserSelect.userId(),
            answersSheetId: getAnswersSheetId,
          }),
          (session) => {
            if (session) {
              return session.getEvaluationScopeVariables();
            }
            return {};
          },
        ),
      };
    },
    mutations: {
      handleKeepAlive:
        ({ dispatch, answersSheetId }) =>
        () => {
          if (answersSheetId) {
            dispatch(
              callMethod(
                heartbeat,
                {
                  answersSheetId,
                },
                {
                  noRetry: true,
                  cancelOnReconnect: true,
                },
              ),
            ).catch((err) => {
              if (
                err.error === 'cancel' ||
                err.error === 'api.forms.heartbeat.notLoggedIn' ||
                err.error === 'api.forms.heartbeat.notFound'
              ) {
                return undefined;
              }
              return notifyError()(err);
            });
          }
        },
      handleUndo:
        ({ dispatch, answersSheetId }) =>
        () =>
          Promise.resolve()
            .then(() => dispatch(forceAutosave(answersSheetId)))
            .then(() =>
              dispatch(
                callMethod(undoLastChanges, {
                  answersSheetId,
                }),
              ),
            )
            .then(({ formValues }) =>
              dispatch(syncFormValues(answersSheetId, formValues)),
            )
            .catch(notifyError()),
      handlePause:
        ({ dispatch, answersSheetId }) =>
        () =>
          Promise.resolve()
            .then(() => dispatch(forceAutosave(answersSheetId)))
            .then(() =>
              dispatch(
                callMethod(pauseAndSaveDraft, {
                  answersSheetId,
                }),
              ),
            )
            .then(() =>
              dispatch(setQueryParam('takeQuestionnaire', null, true)),
            )
            .catch(notifyError()),
      handleClose:
        ({ dispatch, answersSheetId }) =>
        () =>
          Promise.resolve()
            .then(() => dispatch(forceAutosave(answersSheetId)))
            .then(() =>
              dispatch(setQueryParam('takeQuestionnaire', null, true)),
            )
            .catch(notifyError()),
      handleSubmissionError: () => (err) => notifyError()(err),
      handleSubmit:
        ({ dispatch, answersSheetId }) =>
        () =>
          Promise.resolve().then(
            () =>
              dispatch(
                callMethod(complete, {
                  answersSheetId,
                }),
              )
                .then(() =>
                  dispatch(setQueryParam('takeQuestionnaire', null, true)),
                )
                .catch((err) => {
                  notifyError()(err);
                }),
            () => {
              // ignore error ...
            },
          ),
      handleInitialize:
        ({ dispatch, answersSheetId }) =>
        () =>
          dispatch(
            syncFormValues(answersSheetId, null, {
              answersSheetId,
            }),
          ),
    },
  }),
  branch(
    (props) =>
      props.subscriptionsReady && props.answersSheet && props.questionnaire,
    identity,
    renderComponent(Loading),
  ),
  lifecycle({
    componentDidMount() {
      const { handleKeepAlive, handleInitialize } = this.props;

      this.keepAliveInterval = setInterval(
        handleKeepAlive,
        ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS * 1000,
      );

      handleComponentDidUpdate(this.props, {});
      handleInitialize();
    },

    componentWillUnmount() {
      if (this.keepAliveInterval) {
        clearInterval(this.keepAliveInterval);
        delete this.keepAliveInterval;
      }
    },

    componentDidUpdate(prevProps) {
      handleComponentDidUpdate(this.props, prevProps);
    },
  }),
)(TakeQuestionnaire);

export default Container;

const takeQuestionnaire = (answersSheetId, replaceState) =>
  setQueryParam('takeQuestionnaire', answersSheetId, replaceState);
export { takeQuestionnaire };
