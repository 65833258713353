/* eslint-disable import/no-named-as-default-member */
import { css } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import theming from 'styled-theming';
import palette from './paletteProjectWizard';
import theme, { ratio } from '../common/utilsClient/theme';

const newTheme = cloneDeep(theme);

newTheme.color.primary = theming('mode', {
  light: palette.blue[80],
  dark: palette.blue[60],
});

newTheme.color.primaryHover = theming('mode', {
  light: palette.blue[90],
  dark: palette.blue[55],
});

newTheme.color.primaryActive = theming('mode', {
  light: palette.blue[100],
  dark: palette.blue[50],
});

newTheme.color.onBackground = theming('mode', {
  light: palette.blue[80],
  dark: palette.blue[60],
});

newTheme.color.onSurface = theming('mode', {
  light: palette.blue[80],
  dark: palette.blue[60],
});

newTheme.color.onUnderlay = theming('mode', {
  light: palette.blue[80],
  dark: palette.blue[60],
});

newTheme.size = {
  small: `${ratio * 1.5}rem`,
  base: `${ratio * 1.75}rem`,
  large: `${ratio * 2}rem`,
};

newTheme.border = {
  radius: {
    0: '0em',
    1: '0.375em',
    2: '0.5em',
    3: '0.625em',
  },
};

newTheme.boxShadow = {
  small: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  large:
    'box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
};

newTheme.typography.title1 = css`
  font-size: ${theme.scale[5]};
  line-height: ${theme.space[5]};
  font-weight: ${theme.font.weight.bold};
`;

newTheme.typography.title2 = css`
  font-size: ${theme.scale[3]};
  line-height: ${theme.space[4]};
  font-weight: ${theme.font.weight.normal};
`;

// newTheme.color.underlay = theming('mode', {
//   light: palette.blue[10],
//   dark: palette.gray[10],
// });

export default newTheme;
