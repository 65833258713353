import map from 'lodash/map';
import first from 'lodash/first';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React, {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useContext,
  useCallback,
} from 'react';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  patientDetails,
  apiZedocOneProject,
  apiZedocProjectVariables,
  apiZedocCreateParticipation,
  apiZedocUpdateParticipation,
} from '../../../common/api/zedoc';
import {
  PARTICIPATION_STATE__ACTIVE,
  VARIABLE_WIDGET_TYPE__RELATIVE_DATE,
  PARTICIPATION_STATE_MACHINE,
  PARTICIPATION_STATE__INITIAL,
} from '../../../common/constants';
import { getCurrentYearMonthDay } from '../../../common/utils/date';
import {
  PROJECT_ATTACH_PARTICIPATION,
  PROJECT_PROFILE_CREATE_PARTICIPATION,
  PROJECT_PROFILE_UPDATE_PARTICIPATION,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../../common/permissions';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectTrackSelect from '../../../common/selectors/ProjectTrack';
import Variable from '../../../common/models/Variable';
import Participation from '../../../common/models/Participation';
import PermissionsDomain from '../../../common/models/PermissionsDomain';
import RecipientSelect from '../../../common/selectors/Recipient';
import ParticipationSelect from '../../../common/selectors/Participation';
import VariableSelect from '../../../common/selectors/Variable';
import { getLeafErrors } from '../../../common/utils/checkSchema';
import Modal from '../Modal';
import FormFieldSearch from '../../forms/FormFieldSearch';
import FormFieldSwitch from '../../forms/FormFieldSwitch';
import FormFieldRelativeDate from './FormFieldRelativeDate';
import FormFieldStudyNo from './FormFieldStudyNo';
import Stack from '../../../common/components/primitives/Stack';
import Loading from '../../../common/components/Loading';
import { notifyError, notifySuccess } from '../../../utils/notify';
import branding from '../../../utils/branding';
import Form from '../../forms/Form';
import useInput from '../../../utils/useInput';
import usePermission from '../../../utils/usePermission';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import useReconcile from '../../../common/utilsClient/useReconcile';
import FormFieldState, {
  getPayload,
  getNonEditableKeys,
  coincidesWithNonEditableKeys,
} from './FormFieldState';
import FormFieldContext from './FormFieldContext';

const empty = {};

const ConnectedFormFieldState = forwardRef((props, forwardedRef) => {
  const { state, payload } = useContext(FormFieldContext);
  return (
    <FormFieldState
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      payload={payload}
      previousState={state}
      stateMachine={PARTICIPATION_STATE_MACHINE}
    />
  );
});

const ProjectProfileDialog = ({
  projectId: initialProjectId,
  allowOtherProjects,
  recipientId,
  participationId,
  onCancel,
  onSubmitted,
  visible,
}) => {
  const { t, i18n } = useTranslation();
  const projectProfile = useRef();

  const inputs = {
    projectId: useInput(initialProjectId),
    confirmOverride: useInput(false),
  };

  const {
    projectId: { value: projectId },
    confirmOverride: {
      value: proceedEvenIfIdentifierExists,
      onChange: setProceedEvenIfIdentifierExists,
    },
  } = inputs;

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const project = useSelector(
    useMemo(
      () =>
        ProjectSelect.one()
          .whereIdEquals(projectId)
          .lookup({
            from: VariableSelect.all().satisfying((variable) => {
              return variable.isPatient() || variable.isParticipation();
            }),
            as: 'variables',
            foreignKey: '_id',
            key: (doc, docId, variables) => {
              return map(doc.variables, ({ id, compulsory }) => {
                const variable = first(variables[id]);
                if (variable) {
                  return new Variable({
                    ...variable.raw,
                    compulsory,
                  });
                }
                return new Variable({
                  _id: id,
                  compulsory,
                });
              });
            },
          }),
      [projectId],
    ),
  );

  const { variables } = project || {};

  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  const participation = useSelector(
    ParticipationSelect.one().whereIdEquals(participationId),
  );

  // NOTE: Can use this to show a warning if we detect that patient
  //       is already in this project.
  // const anotherParticipation = useSelector(
  //   ParticipationSelect.one()
  //     .where({
  //       projectId,
  //       recipientId,
  //     })
  //     .satisfying(p => !p.isDischarged()),
  // );

  const { ready: variablesReady } = useDDPSubscription(
    projectId &&
      apiZedocProjectVariables.withParams({
        projectId,
      }),
  );

  const { ready: patientDetailsReady } = useDDPSubscription(
    projectId &&
      recipientId &&
      patientDetails.withParams({
        projectId,
        recipientId,
      }),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const defaultTrack = useSelector(
    ProjectTrackSelect.one()
      .where({
        projectId,
      })
      .sort({
        index: 1,
      }),
  );

  const defaultTrackId = defaultTrack && defaultTrack._id;
  const { domainsReady, permissionsDomains: allowedDomains } =
    usePermissionsRealm([PROJECT_PROFILE_CREATE_PARTICIPATION], {
      scope: project ? project.getDomains() : [],
    });

  const defaultParticipation = useMemo(() => {
    return new Participation({
      ownership: map(
        PermissionsDomain.extractFundamentalDomains(map(allowedDomains, '_id')),
        (domain) => ({
          domain,
        }),
      ),
      state: PARTICIPATION_STATE__ACTIVE,
      trackId: defaultTrackId,
      trackDate: getCurrentYearMonthDay(),
    });
  }, [allowedDomains, defaultTrackId]);

  const [nextParticipation, setNextParticipation] = useState(
    participation || defaultParticipation,
  );

  const evaluateNextParticipation = useCallback(
    (formValues) => {
      const rawParticipation = participation
        ? cloneDeep(participation.raw)
        : cloneDeep(defaultParticipation.raw);
      const variablesById = keyBy(variables, '_id');
      forEach(formValues.variables, (value, variableId) => {
        const variable = variablesById[variableId];
        if (variable && variable.isParticipation() && value !== undefined) {
          variable.setValue(rawParticipation, value);
        }
      });
      return new Participation(rawParticipation);
    },
    [participation, defaultParticipation, variables],
  );

  const handleOnChange = useCallback(
    (formValues) => {
      setNextParticipation(evaluateNextParticipation(formValues));
    },
    [evaluateNextParticipation],
  );

  const state = participation
    ? participation.state
    : PARTICIPATION_STATE__INITIAL;

  const trackId = nextParticipation
    ? nextParticipation.trackId
    : participation && participation.trackId;

  // NOTE: If nextParticipation was never updated, it's theoretically possible
  //       that it will be nullish.
  const nextState = nextParticipation ? nextParticipation.state : state;

  const payload = useReconcile(
    useMemo(() => {
      return getPayload(
        PARTICIPATION_STATE_MACHINE,
        nextParticipation,
        participation,
      );
    }, [nextParticipation, participation]),
  );

  const nonEditableKeys = useReconcile(
    useMemo(() => {
      return getNonEditableKeys(
        PARTICIPATION_STATE_MACHINE,
        payload,
        state,
        nextState,
      );
    }, [payload, state, nextState]),
  );

  const validateConstraints = useCallback(
    (formValues) => {
      const rawParticipation = evaluateNextParticipation(formValues).raw;
      const modelErrors = getLeafErrors(
        Participation.validate(rawParticipation),
      );
      const formErrors = {
        variables: {},
      };
      forEach(variables, (variable) => {
        const variableKey = variable.getKey(rawParticipation);
        if (
          variable.isParticipation() &&
          !coincidesWithNonEditableKeys(nonEditableKeys, variableKey)
        ) {
          const error = get(modelErrors, variableKey);
          if (error) {
            formErrors.variables[variable._id] = error;
          }
        }
      });
      if (!isEmpty(formErrors.variables)) {
        projectProfile.current.setErrors(formErrors);
        return Promise.reject(
          new Error('confirmations:validateQuestionnaire.error'),
        );
      }
      return Promise.resolve(formValues);
    },
    [variables, nonEditableKeys, evaluateNextParticipation],
  );

  const [conflictingRecipientId, setConflictingRecipientId] = useState(false);

  const resetConflictsAndOverride = useCallback(() => {
    setConflictingRecipientId(null);
    setProceedEvenIfIdentifierExists(false);
  }, [setConflictingRecipientId, setProceedEvenIfIdentifierExists]);

  const handleApiErrors = useCallback(
    (error) => {
      if (error && /\.identifierConflict$/.test(error.error)) {
        setConflictingRecipientId(error.details.recipientId);
        notifyError()(
          t('confirmations:identifierConflict.warning', {
            context: branding,
          }),
        );
        return;
      }
      notifyError()(error);
    },
    [setConflictingRecipientId, t],
  );

  const handleOnOk = useCallback(() => {
    if (ddpIsPending) {
      return;
    }

    if (participationId) {
      projectProfile.current
        .submit()
        .then(validateConstraints)
        .then((formValues) => {
          return ddpCall(
            apiZedocUpdateParticipation.withParams({
              participationId,
              ...formValues,
            }),
          )
            .then(({ details }) => {
              if (onSubmitted) {
                return onSubmitted(details);
              }
              return undefined;
            })
            .then(
              notifySuccess(
                t('confirmations:editRecipient.success', {
                  context: branding,
                }),
              ),
            );
        })
        .then(onCancel)
        .catch(handleApiErrors);
    } else {
      projectProfile.current
        .submit()
        .then(validateConstraints)
        .then((formValues) => {
          return ddpCall(
            apiZedocCreateParticipation.withParams(
              omitBy(
                {
                  projectId,
                  recipientId,
                  proceedEvenIfIdentifierExists: recipientId
                    ? true
                    : proceedEvenIfIdentifierExists,
                  ...formValues,
                },
                isNil,
              ),
            ),
          )
            .then(({ details }) => {
              if (onSubmitted) {
                return onSubmitted(details);
              }
              resetConflictsAndOverride();
              return undefined;
            })
            .then(
              notifySuccess(
                t('confirmations:addToProject.success', {
                  context: branding,
                }),
              ),
            );
        })
        .then(onCancel)
        .catch(handleApiErrors);
    }
  }, [
    projectProfile,
    projectId,
    recipientId,
    participationId,
    onCancel,
    onSubmitted,
    t,
    ddpCall,
    ddpIsPending,
    proceedEvenIfIdentifierExists,
    resetConflictsAndOverride,
    handleApiErrors,
    validateConstraints,
  ]);

  const loading =
    !domainsReady || !projectReady || !variablesReady || !patientDetailsReady;

  const { domains: projectDomains } = usePermissionsRealm([
    PROJECT_ATTACH_PARTICIPATION,
  ]);

  const canUpdateParticipation = usePermission(
    PROJECT_PROFILE_UPDATE_PARTICIPATION,
    {
      relativeTo: participation && participation.getDomains(),
    },
  );

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });

  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      properties: {
        variables: {
          type: 'object',
          required: [],
          properties: {},
          dependencies: {},
        },
      },
    };
    forEach(variables, (variable) => {
      if (variable) {
        if (variable.isPII() && !canSeePII) {
          newSchema.properties.variables.properties[variable._id] = false;
        } else {
          newSchema.properties.variables.properties[variable._id] =
            variable.getJsonSchema({
              projectId,
              language: i18n.language,
              allowedDomains,
            });
          if (variable.compulsory) {
            newSchema.properties.variables.required.push(variable._id);
          }
        }
        // switch (variable._id) {
        //   case VARIABLE_ID__PATIENT_BASELINE: {
        //     newSchema.properties.variables.properties[`${variable._id}:overwrite`] = {
        //       type: 'boolean',
        //     };
        //     break;
        //   }
        //   default: {
        //     // ...
        //   }
        // }
      }
    });
    return newSchema;
  }, [canSeePII, allowedDomains, variables, i18n.language, projectId]);

  const initialValues = useMemo(() => {
    if (loading) {
      return null;
    }
    const allVariables = {
      variables: {},
    };
    const context = {
      recipient,
      participation: participation || defaultParticipation,
    };
    forEach(variables, (variable) => {
      const value = variable.getFromContext(context);
      if (!isNil(value)) {
        allVariables.variables[variable._id] = value;
      }
    });
    return allVariables;
  }, [loading, variables, recipient, participation, defaultParticipation]);

  const isNewParticipation = !participationId;

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: ['variables'],
      },
      variables: {
        label: '',
        children: [],
      },
    };
    forEach(variables, (variable) => {
      const field = {
        testLabel: variable.name,
        disabled:
          !variable.isEditable(isNewParticipation) ||
          coincidesWithNonEditableKeys(nonEditableKeys, variable.getKey()),
      };
      newFields[`variables.${variable._id}`] = field;
      newFields.variables.children.push(variable._id);
      if (variable.isPII() && !canSeePII) {
        // NOTE: The reason this is needed is because schema for PII fields
        //       will be "false" and so it will not have any "title" assigned to it.
        const variableSchema = variable.getJsonSchema({
          projectId,
          language: i18n.language,
        });
        if (variableSchema) {
          field.label = variableSchema.title;
        }
      }
      if (variable.isParticipationStudyNo()) {
        // studyNo
        field.component = FormFieldStudyNo;
      } else if (variable.isParticipationState()) {
        // state
        field.component = ConnectedFormFieldState;
      }
      switch (variable.widgetType) {
        case VARIABLE_WIDGET_TYPE__RELATIVE_DATE: {
          field.component = FormFieldRelativeDate;
          break;
        }
        default: {
          // ...
        }
      }
    });
    return newFields;
  }, [
    canSeePII,
    i18n.language,
    variables,
    isNewParticipation,
    projectId,
    nonEditableKeys,
  ]);

  const fieldContext = useReconcile({
    projectId,
    trackId,
    state,
    payload,
  });

  return (
    <Modal
      data-testid="project-profile-dialog"
      title={
        participation || loading
          ? t('editRecipient', {
              context: branding,
            })
          : `${t('addToProject', {
              context: branding,
            })} ${project ? project.name : ''}`
      }
      onOk={handleOnOk}
      okText={participation || loading ? t('save') : t('saveAndAddToProject')}
      isOkDisabled={participation && !canUpdateParticipation}
      onCancel={onCancel}
      visible={visible}
      confirmLoading={loading || ddpIsPending}
    >
      <Stack space={4}>
        {allowOtherProjects && (
          <FormFieldSearch
            data-testid="form-field-project-id"
            name="projectId"
            label={t('forms:project.label')}
            valueSetId="zedoc/projects@1.0.0"
            valueSetDomains={projectDomains}
            input={inputs.projectId}
            meta={empty}
          />
        )}
        {!loading && projectId && (
          <FormFieldContext.Provider value={fieldContext}>
            <Form
              data-testid="project-profile-form"
              key={projectId}
              ref={projectProfile}
              name={
                recipientId
                  ? `project_profile_${projectId}_${recipientId}`
                  : `project_profile_${projectId}`
              }
              initialValues={initialValues}
              onChange={handleOnChange}
              schema={schema}
              fields={fields}
            />
          </FormFieldContext.Provider>
        )}
        {!recipientId && conflictingRecipientId && (
          <FormFieldSwitch
            data-testid="form-field-proceed-with-conflict"
            name="confirmOverride"
            label="Confirm override"
            input={inputs.confirmOverride}
            meta={empty}
          />
        )}
        {loading && <Loading />}
      </Stack>
    </Modal>
  );
};

ProjectProfileDialog.propTypes = {
  projectId: PropTypes.string,
  allowOtherProjects: PropTypes.bool,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  visible: PropTypes.bool,
};

ProjectProfileDialog.defaultProps = {
  projectId: null,
  allowOtherProjects: true,
  recipientId: null,
  participationId: null,
  onCancel: null,
  onSubmitted: null,
  visible: true,
};

export default ProjectProfileDialog;
