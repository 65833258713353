import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditPatientDialog from '../../../components/dialogs/EditPatient_v2';
import { getEditPatientDialog, closeEditPatientDialog } from '../store';

const ConnectedEditPatientDialog = ({ onSubmitted }) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeEditPatientDialog());
  }, [dispatch]);
  const options = useSelector(getEditPatientDialog);
  if (!options) {
    return <EditPatientDialog key="no-options" visible={false} />;
  }
  const { recipientId } = options;
  return (
    <EditPatientDialog
      key="has-options"
      visible
      recipientId={recipientId}
      onCancel={handleOnCancel}
      onSubmitted={onSubmitted}
    />
  );
};

ConnectedEditPatientDialog.propTypes = {
  onSubmitted: PropTypes.func,
};

ConnectedEditPatientDialog.defaultProps = {
  onSubmitted: null,
};

export default ConnectedEditPatientDialog;
