import find from 'lodash/find';
import includes from 'lodash/includes';
import mapKeys from 'lodash/mapKeys';
import isNil from 'lodash/isNil';
import {
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__EAPPS,
} from '../../common/constants';
import { encode as encodeQuery } from '../../common/utils/querystring';

const registeredTypes = {};

export const getPerspectiveIdField = (perspective) => {
  switch (perspective) {
    case DASHBOARD_PERSPECTIVE__PATIENTS:
      return 'patientId';
    case DASHBOARD_PERSPECTIVE__PARTICIPATIONS:
      return 'participationId';
    case DASHBOARD_PERSPECTIVE__ACTIVITIES:
      return 'activityId';
    case DASHBOARD_PERSPECTIVE__RESPONSES:
      return 'responseId';
    case DASHBOARD_PERSPECTIVE__ASSIGNMENTS:
      return 'assignmentId';
    case DASHBOARD_PERSPECTIVE__EAPPS:
      return 'eappId';
    default:
      return undefined;
  }
};

export const getPerspectiveId = (perspective, settings) => {
  const idField = getPerspectiveIdField(perspective);
  if (settings && idField) {
    return settings[idField];
  }
  return undefined;
};

export const getSpecialization = (settings) => {
  if (settings) {
    if (settings.patientId) {
      return {
        perspectiveId: settings.patientId,
        perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      };
    }
    if (settings.participationId) {
      return {
        perspectiveId: settings.participationId,
        perspective: DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
      };
    }
    if (settings.activityId) {
      return {
        perspectiveId: settings.activityId,
        perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
      };
    }
    if (settings.responseId) {
      return {
        perspectiveId: settings.responseId,
        perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      };
    }
    if (settings.assignmentId) {
      return {
        perspectiveId: settings.assignmentId,
        perspective: DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
      };
    }
    if (settings.eappId) {
      return {
        perspectiveId: settings.eappId,
        perspective: DASHBOARD_PERSPECTIVE__EAPPS,
      };
    }
  }
  return undefined;
};

export const getLocationObject = (params) => {
  const { projectId, perspective, type, presetNo, settings } = params;
  if (!projectId) {
    return null;
  }
  let view;
  if (type) {
    view = isNil(presetNo) ? type : `${type}.${presetNo}`;
  }
  const query = {
    _view: view,
    ...mapKeys(settings, (value, key) => `_view.${key}`),
  };
  if (!perspective) {
    return {
      query,
      pathname: `/projects/${projectId}`,
    };
  }
  const perspectiveId = getPerspectiveId(perspective, settings);
  if (perspectiveId) {
    const perspectiveIdField = getPerspectiveIdField(perspective);
    delete query[`_view.${perspectiveIdField}`];
  }
  if (perspectiveId) {
    return {
      query,
      pathname: `/projects/${projectId}/${perspective}/${encodeURIComponent(
        perspectiveId,
      )}`,
    };
  }
  return {
    query,
    pathname: `/projects/${projectId}/${perspective}`,
  };
};

export const getViewUrl = (params) => {
  const location = getLocationObject(params);
  if (!location) {
    return '';
  }
  const { pathname, query } = location;
  const search = encodeQuery(query);
  return `${pathname}${search}`;
};

export function hasSpecialization(type, perspective, specialization) {
  if (!registeredTypes[type]) {
    return false;
  }
  const details = find(registeredTypes[type].perspectives, {
    perspective,
  });
  if (details) {
    const { genericView, specializations } = details;
    if (!specialization) {
      return !!genericView;
    }
    return includes(specializations, specialization.perspective);
  }
  return false;
}

export function getPerspective(type, specialization) {
  if (!registeredTypes[type]) {
    return undefined;
  }
  let details;
  if (specialization) {
    details = find(
      registeredTypes[type].perspectives,
      ({ specializations }) => {
        return includes(specializations, specialization.perspective);
      },
    );
  } else {
    details = find(registeredTypes[type].perspectives, ({ genericView }) => {
      return !!genericView;
    });
  }
  return details && details.perspective;
}

export function hasGenericView(type, perspective = getPerspective(type)) {
  return hasSpecialization(type, perspective);
}

export const registerType = (type, details) => {
  if (registeredTypes[type]) {
    throw new Error(`Dashboard type ${type} already registered`);
  }
  registeredTypes[type] = details;
};
