import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../common/components/Markdown';
import { theme } from '../../common/utilsClient/cssHelpers';
import {
  QUESTION_TITLE_APPEARANCE__NEUTRAL,
  QUESTION_TITLE_APPEARANCES,
} from '../../common/constants';

const StyledBox = styled.div`
  background-color: ${theme('color.underlay')};
  color: ${(props) => theme(`color.${props.type}`)};
  padding: ${theme('space.3')};
  border-left: 8px solid ${(props) => theme(`color.${props.type}`)};
  border-radius: ${theme('border.radius.2')};
`;

const FormFieldTitle = ({ title, type }) => {
  return type === QUESTION_TITLE_APPEARANCE__NEUTRAL ? (
    <Markdown.h2 source={title} />
  ) : (
    <StyledBox type={type}>
      <Markdown.h2 source={title} />
    </StyledBox>
  );
};

FormFieldTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(QUESTION_TITLE_APPEARANCES),
};

FormFieldTitle.defaultProps = {
  type: 'neutral',
};

export default FormFieldTitle;
