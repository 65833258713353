import { SyncOutlined } from '@ant-design/icons';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDDPCall } from '@theclinician/ddp-connector';
import QuestionnaireRecordSelect from '../../common/selectors/QuestionnaireRecord';
import QuestionnairesRepositoryMetadataEntrySelect from '../../common/selectors/QuestionnairesRepositoryMetadataEntry';
import Stack from '../../common/components/primitives/Stack';
import Box from '../../common/components/primitives/Box';
import Sidebar from '../../common/components/primitives/Sidebar';
import Text from '../../common/components/base/Text';
import List from '../../common/components/List';
import Highlighter from '../../common/components/Highlighter';
import Button from '../../common/components/Button';
import PageBar from '../../components/Layout/PageBar';
import { apiZedocSearchQuestionnaireRecords } from '../../common/api/zedoc';
import { apiAdminRefreshQuestionnairesRepository } from '../../common/api/admin';
import { slugify } from '../../common/utils/formatting';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__SEARCH_TERMS,
} from '../../common/constants';
import { ORGANIZATION_UPDATE_QUESTIONNAIRE } from '../../common/permissions';
import ConnectedFilters from './ConnectedFilters';
import { notifyError, notifySuccess } from '../../utils/notify';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import usePermission from '../../utils/usePermission';
import { getFilters, getSorter } from './store';

const renderValue = (match) => {
  if (isArray(match)) {
    return map(match, ({ value, label }) => label || value).join(', ');
  }
  if (isNil(match)) {
    return 'N/A';
  }
  return match.toString();
};

const selectRepositoryMetadata =
  QuestionnairesRepositoryMetadataEntrySelect.one();

const ListScreen = () => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const metadata = useSelector(selectRepositoryMetadata);
  const canModifyQuestionnaires = usePermission([
    ORGANIZATION_UPDATE_QUESTIONNAIRE,
  ]);
  const { t } = useTranslation();
  useDocumentTitle([
    t('questionnaire', {
      count: 0,
    }),
  ]);
  const {
    ready: subscriptionsReady,
    items: questionnaires,
    totalItems,
    paginationKey,
    pageSize,
    renderPagination,
  } = usePagination({
    selector: QuestionnaireRecordSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchQuestionnaireRecords.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const { ddpCall, ddpIsPending } = useDDPCall();
  const callFetchFromRepository = useCallback(
    () =>
      ddpCall(apiAdminRefreshQuestionnairesRepository.withParams({}))
        .then(
          notifySuccess(
            ({ nChanged, nCreated }) =>
              `New: ${nCreated}, Changed: ${nChanged}`,
          ),
        )
        .catch(notifyError()),
    [ddpCall],
  );
  const highlight = useMemo(
    () =>
      flatten(
        map(filters, (filter) => {
          if (
            filter.type === FILTER_TYPE__PROPERTY &&
            filter.condition === FILTER_CONDITION__SEARCH_TERMS &&
            filter.settings &&
            filter.settings.id === 'searchTerms' &&
            filter.state &&
            filter.state.text
          ) {
            return filter.state.text.split(/\s+/);
          }
          return [];
        }),
      ),
    [filters],
  );
  const filtersById = keyBy(filters, 'id');
  return (
    <Stack>
      <PageBar
        title={t('questionnaire', {
          count: 0,
        })}
        extra={
          <>
            {metadata && (
              <span>
                {t('updatedAt')} {moment(metadata.lastUpdatedAt).format('LLL')}
              </span>
            )}
            <Button
              data-testid="button-sync"
              icon={<SyncOutlined />}
              onClick={callFetchFromRepository}
              loading={ddpIsPending}
              disabled={!canModifyQuestionnaires}
            >
              {t('sync')}
            </Button>
          </>
        }
      />
      <Sidebar>
        <ConnectedFilters />
      </Sidebar>
      <Box.Primary boxShadow="base">
        <List
          data-testid="table-questionnaires"
          title={`${t('total')} ${totalItems}`}
          dataSource={questionnaires}
          loading={!subscriptionsReady}
          pageSize={pageSize}
          renderItem={(questionnaire) => (
            <div key={questionnaire._id}>
              <Text.Link
                data-testid={`item-${slugify(questionnaire._id)}`}
                to={`/forms/sandbox/${encodeURIComponent(
                  questionnaire.getLatestVersionId(),
                )}`}
                size="large"
                importance="high"
              >
                <Highlighter
                  text={questionnaire.getTitle()}
                  highlight={highlight}
                />
              </Text.Link>
              <Text.Paragraph>
                <Highlighter
                  text={questionnaire.getNamespace()}
                  highlight={highlight}
                />
                /
                <Highlighter
                  text={questionnaire.getIdentifier()}
                  highlight={highlight}
                />
                {', '}
                {`${t('version')} ${questionnaire.getVersion()}`}
              </Text.Paragraph>
              {map(
                paginationKey &&
                  questionnaire[paginationKey] &&
                  questionnaire[paginationKey].filters,
                ({ id, match }) => {
                  const filter = filtersById[id];
                  if (
                    filter &&
                    filter.condition !== FILTER_CONDITION__SEARCH_TERMS
                  ) {
                    return (
                      <span key={id}>
                        {filter.name}
                        {':'}
                        &nbsp;
                        {renderValue(match)}
                        {'; '}
                      </span>
                    );
                  }
                  return null;
                },
              )}
            </div>
          )}
          renderPagination={renderPagination}
        />
      </Box.Primary>
    </Stack>
  );
};

ListScreen.propTypes = {};
ListScreen.defaultProps = {};

export default ListScreen;
