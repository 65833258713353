/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components/macro';
import React from 'react';
import SwitchAntd from 'antd/es/switch';
import 'antd/es/switch/style/css';
import { theme } from '../utilsClient/cssHelpers';

const Component = styled(SwitchAntd)`
  && {
    background-color: ${theme('color.surface')};
    box-shadow: ${theme('boxShadow.base')};

    &,
    & .ant-switch-inner {
      color: ${theme('color.onSurface')};
    }

    &.ant-switch-checked {
      background-color: ${theme('color.primary')};

      .ant-switch-inner {
        color: ${theme('color.onPrimary')};
      }
    }

    &::after {
      /* background-color: ${theme('color.surface')}; */
    }
  }
`;

const Switch = (props) => <Component {...props} />;

export default Switch;
