import React from 'react';
import { wrapDisplayName, setDisplayName } from 'recompose';

/**
 * Creates a higher order component that runs onInterval function periodically.
 *
 * @param {Number} [msDelay=1000]
 * @param {Function} onInterval
 * @param {Boolean} runInitially
 * @returns {HigherOrderComponent}
 */
const withInterval = ({ msDelay = 1000, runInitially, onInterval }) => {
  const hoc = (BaseComponent) => {
    /**
     * A component that adds "autosave" function to properties. That function can be called with new changes object
     * and the actual call to "onAutosave" will be postponed by the number of milliseconds given by autosaveDelay.
     */
    class Container extends React.Component {
      constructor(props) {
        super(props);

        this.interval = null;
        this.handle = this.handle.bind(this);
      }

      /**
       * Initialize the interval.
       * @private
       */
      componentDidMount() {
        if (runInitially) {
          this.handle({
            isInitialRun: true,
          });
        }
        this.interval = setInterval(this.handle, msDelay);
      }

      /**
       * Cancel pending interval.
       * @private
       */
      componentWillUnmount() {
        if (this.interval) {
          clearInterval(this.interval);
        }
        this.unmounted = true;
      }

      handle(custom) {
        if (onInterval) {
          onInterval({
            ...this.props,
            ...custom,
          });
        }
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }
    return Container;
  };
  if (process.env.NODE_ENV !== 'production') {
    return (BaseComponent) =>
      setDisplayName(wrapDisplayName(BaseComponent, 'withInterval'))(
        hoc(BaseComponent),
      );
  }
  return hoc;
};

export default withInterval;
