import filter from 'lodash/filter';
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';
import Cluster from '../primitives/Cluster';
import Tab from './Tab';
import Rail from './Rail';
import Panel from './Panel';

const StyledTabs = styled.div`
  ${(props) =>
    props.inset &&
    `
  margin: -${theme('space.3')(props)}`}
`;

const StyledList = styled.ul`
  display: flex;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
`;

const StyledTabsDivider = styled.div`
  border-bottom: 1px solid ${theme('color.border')};
`;

const Tabs = ({
  tabs,
  onChange,
  activeKey,
  inset,
  // eslint-disable-next-line react/prop-types
  className,
  beforeElem,
}) => {
  const filteredTabs = filter(tabs, (tab) => tab);

  return activeKey ? (
    <StyledTabs className={className} inset={inset}>
      <StyledTabsDivider>
        <Cluster space={0} align="stretch" justify="space-between">
          {/* TODO: In some cases we want to rendre tabs list on the same line with other elements
          //  but it'd make more sense to have separate LEGO-like Tabs.List and Tabs.Content and then
          //  render them anywhere
          */}
          {beforeElem}
          <StyledList role="tablist">
            {filteredTabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                onClose={tab.onClose}
                onChange={onChange}
                activeKey={activeKey}
              />
            ))}
          </StyledList>
        </Cluster>
      </StyledTabsDivider>
      <Rail tabs={filteredTabs} activeKey={activeKey}>
        {filteredTabs.map((tab) => (
          <Panel
            key={tab.value}
            value={tab.value}
            content={tab.content}
            activeKey={activeKey}
          />
        ))}
      </Rail>
    </StyledTabs>
  ) : (
    'No `activeKey` set'
  );
};

Tabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  inset: PropTypes.bool,
  beforeElem: PropTypes.node,
};

Tabs.defaultProps = {
  tabs: [],
  onChange: () => {},
  inset: false,
  beforeElem: null,
};

export default Tabs;
