import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import { ddp } from '@theclinician/ddp-connector';
import CurrentUserSelect from '../../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../../common/selectors/Project';
import ExportSelectionSelect from '../../../../common/selectors/ExportSelection';
import CSVInputSchemaSelect from '../../../../common/selectors/CSVInputSchema';
import settings from '../../../../common/settings';
import {
  getSorter,
  getQueryFilters,
} from '../../../../store/ui/search/selectors';
import ProjectMilestones from './ProjectMilestones';
import {
  forceSyncToPatientService,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import { callMethod } from '../../../../common/utilsClient/ddp/actions';
import {
  openProjectProfileDialog,
  openEditMilestoneDialog,
  openDownloadResponsesCSVDialog,
} from '../../actions';
import { getResumeToken } from '../../../../common/utilsClient/ddp/selectors';
import { notifyError, notifySuccess } from '../../../../utils/notify';
import { slugify } from '../../../../common/utils/formatting';
import getExportFileName from '../../../../common/utils/getExportFileName';

const { backendUrl } = settings.public;

const getProjectId = (state, { projectId }) => projectId;
const getProject = ProjectSelect.one().whereIdEquals(getProjectId);

const DashboardTimeline = compose(
  ddp({
    subscriptions: (state, { projectId }) => [
      projectId &&
        apiZedocOneProject.withParams({
          projectId,
        }),
    ],
    selectors: () => ({
      project: getProject,
      resumeToken: getResumeToken,
      exportFilters: getQueryFilters,
      exportSorter: getSorter,
      exportSelections: ExportSelectionSelect.all().where({
        projectId: getProjectId,
      }),
      csvInputSchemas: CSVInputSchemaSelect.all().where({
        projectId: getProjectId,
      }),
      validate: CurrentUserSelect.getCurrentPermissionsValidator({
        relativeTo: createSelector(
          getProject,
          (project) => project && project.getDomains(),
        ),
      }),
    }),
    renderLoader: null,
  }),
  withHandlers({
    onProjectExport:
      ({ project, resumeToken, exportFilters, exportSorter }) =>
      (dataExportId) => {
        const url = dataExportId
          ? `${backendUrl}/integrations/dataExports/${dataExportId}`
          : `${backendUrl}/integrations/responses/projects/${project._id}`;
        // see https://stackoverflow.com/a/32545850/2817257
        return fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            filters: exportFilters,
            sorter: exportSorter,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': resumeToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.blob();
            }
            return response
              .json()
              .then(({ error, reason }) =>
                Promise.reject(new Error(`[${error}] ${reason}`)),
              );
          })
          .then((blob) =>
            saveAs(
              blob,
              getExportFileName(
                slugify(project.getName()),
                dataExportId ? 'csv' : 'zip',
              ),
            ),
          )
          .then(notifySuccess('Exported Project'))
          .catch(notifyError());
      },
    onCsvUpload:
      ({ resumeToken }) =>
      (csvSchemaId, formData) => {
        const url = `${backendUrl}/integrations/csv/${csvSchemaId}`;
        // see https://stackoverflow.com/a/35206069/2817257
        return fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            'X-Auth-Token': resumeToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.blob();
            }
            return response
              .json()
              .then(({ error, reason }) =>
                Promise.reject(new Error(`[${error}] ${reason}`)),
              );
          })
          .then(
            notifySuccess(
              'Successfully uploaded file. Please wait until it is fully processed.',
            ),
          )
          .catch(notifyError());
      },
    onAddPatient:
      ({ dispatch }) =>
      () =>
        dispatch(openProjectProfileDialog({})),
    onAddMilestone:
      ({ dispatch }) =>
      () =>
        dispatch(openEditMilestoneDialog({})),
    onDownloadResponsesCSV:
      ({ dispatch }) =>
      () =>
        dispatch(openDownloadResponsesCSVDialog({})),
    onForceSyncToProms:
      ({ dispatch, project }) =>
      () =>
        dispatch(
          callMethod(
            forceSyncToPatientService,
            {
              projectId: project._id,
            },
            {
              noRetry: true,
            },
          ),
        )
          .then(
            notifySuccess(
              ({ nRecords }) =>
                `${nRecords} records are now being synchronized - please wait`,
            ),
          )
          .catch(notifyError()),
  }),
)(ProjectMilestones);

DashboardTimeline.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default DashboardTimeline;
