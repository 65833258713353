import React from 'react';
import Menu from '../../common/components/Menu';

const { Item } = Menu;

const MenuComponent = ({ items, ...rest }) => (
  <Menu {...rest}>
    {items.map(({ title, onClick }, i) => (
      <Item data-testid={`menu-item-${title}`} key={i} onClick={onClick}>
        {title}
      </Item>
    ))}
  </Menu>
);

export default MenuComponent;
