import each from 'lodash/each';
import map from 'lodash/map';
import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector } from 'react-redux';
import { apiZedocSearchResponses } from '../../../../common/api/zedoc';
import { one as oneQuestionnaire } from '../../../../common/api/collections/Questionnaires2';
import AnswersSheetSelect from '../../../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../../../common/selectors/Questionnaire';
import QuestionnaireTranslationSelect from '../../../../common/selectors/QuestionnaireTranslation';
import Cluster from '../../../../common/components/primitives/Cluster';
import Table from '../../../../common/components/Table';
import Tooltip from '../../../../common/components/Tooltip';
import Button from '../../../../common/components/Button';
import TranslationsSelect from '../../../../components/inputs/TranslationsSelect';
import usePresentableAnswersSheet from '../../../../utils/usePresentableAnswersSheet';
import copyResponsesToClipboard from '../../../../utils/copyResponsesToClipboard';
import usePagination from '../../../../utils/usePagination';
import { presentAnswersSheet } from '../../../../common/utils/questionnaire';

const getDataSource = ({
  answersSheets,
  translations,
  currentLanguage,
  questionnaire,
}) => {
  const dataSource = [];

  each(answersSheets, (answersSheet) => {
    const translation =
      translations &&
      translations.find(({ _id }) => _id === answersSheet.translationId);
    const translatedQuestionnaire =
      translation &&
      translation.language === currentLanguage &&
      translation.translate(questionnaire);
    const valueLabelPairs = questionnaire
      ? presentAnswersSheet(
          translatedQuestionnaire || questionnaire,
          answersSheet,
          {
            filterQuestions: (q) => !q.isCollection(),
          },
        )
      : [];

    valueLabelPairs.forEach(({ value, label }, i) => {
      if (!dataSource[i]) {
        dataSource[i] = {};
      }
      dataSource[i].title = label;
      dataSource[i][answersSheet._id] = value.toString();
    });
  });

  return dataSource;
};

const getColumns = ({ answersSheets, currentLanguage }) => {
  return [
    {
      title: 'Questions',
      dataIndex: 'title',
      key: 'title',
    },
    ...map(answersSheets, (answersSheet) => ({
      title: (
        <Cluster space={1}>
          <span>
            {moment(answersSheet.completedAt).format('DD/MM/YYYY HH:mm')}
          </span>
          <CopyResponses
            answersSheetId={answersSheet._id}
            currentLanguage={currentLanguage}
          />
        </Cluster>
      ),
      dataIndex: answersSheet._id,
      key: answersSheet._id,
    })),
  ];
};

const CopyResponses = ({ answersSheetId, currentLanguage }) => {
  const { t } = useTranslation();
  const { meta, responses } = usePresentableAnswersSheet(
    answersSheetId,
    currentLanguage,
  );

  const handleCopyResponses = () => copyResponsesToClipboard(meta, responses);

  return (
    <Tooltip title={t('copyResponses')}>
      <Button
        data-testid="button-copy-responses"
        type="link"
        icon={<CopyOutlined />}
        onClick={handleCopyResponses}
      />
    </Tooltip>
  );
};
CopyResponses.propTypes = {
  answersSheetId: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

const PatientResultsTable = ({ projectId, recipientId, questionnaireId }) => {
  useDDPSubscription(
    recipientId &&
      questionnaireId &&
      apiZedocSearchResponses.withParams({
        recipientId,
        questionnaireId,
      }),
  );
  useDDPSubscription(
    questionnaireId && oneQuestionnaire.withParams(questionnaireId),
  );

  const {
    ready: subscriptionsReady,
    items: answersSheets,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: AnswersSheetSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchResponses.withParams({
        recipientId,
        questionnaireId,
        projectId,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const translations = useSelector(QuestionnaireTranslationSelect.all());

  const [currentLanguage, setCurrentLanguagea] = useState();

  const dataSource = getDataSource({
    answersSheets,
    translations,
    currentLanguage,
    questionnaire,
  });
  const columns = getColumns({
    answersSheets,
    currentLanguage,
  });

  return (
    <>
      <TranslationsSelect
        questionnaireId={questionnaire?._id}
        onChange={setCurrentLanguagea}
        value={currentLanguage}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={!subscriptionsReady}
        pagination={getPaginationProps()}
      />
    </>
  );
};

PatientResultsTable.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
};

export default PatientResultsTable;
