import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_LOG_STATE__FAILED } from '../../common/constants/collections/NotificationsLogs';
import { ButtonLink } from '../../common/components/Button';
import Text from '../../common/components/base/Text';
import { deliveryTypeTranslationKeys, stateTranslationKeys } from './helpers';

const NotificationLog = ({ deliveryType, state, sentAt, onClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ButtonLink
        type={state === NOTIFICATION_LOG_STATE__FAILED ? 'danger' : 'primary'}
        onClick={onClick}
      >
        <Text.Span size="small" decoration="underline">
          <Text.Span>
            {t(`${deliveryTypeTranslationKeys[deliveryType]}`)}
          </Text.Span>{' '}
          {t(`states:${stateTranslationKeys[state] || ''}`)}
        </Text.Span>
      </ButtonLink>{' '}
      <Text.Span size="small">{sentAt}</Text.Span>
    </div>
  );
};

NotificationLog.propTypes = {
  deliveryType: PropTypes.string,
  state: PropTypes.string,
  sentAt: PropTypes.string,
  onClick: PropTypes.func,
};

NotificationLog.defaultProps = {
  deliveryType: null,
  state: null,
  sentAt: null,
  onClick: () => {},
};

export default NotificationLog;
