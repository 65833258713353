/* eslint-disable react/forbid-prop-types */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { languages } from 'countries-list';
import User from '../../../common/models/User';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import List from '../../../common/components/List';
import DarkModeToggle from '../../../common/components/DarkModeToggle';
import LanguageSelector from '../../../common/components/LanguageSelector';
import { useTheme } from '../../../common/components/ThemeProvider';
import { getLanguage, setLanguage } from '../../../store/preferences';
import { supportedLanguages } from '../../../utils/i18next';
import Modal from '../Modal';

const DarkModeToggleContainer = () => {
  const theme = useTheme();
  const checked = theme.mode === 'dark';
  const onToggle = (bool) => theme.toggle(bool);

  return <DarkModeToggle checked={checked} onToggle={onToggle} />;
};

const CurrentUserProfile = ({
  visible,
  onCancel,
  user,
  rolesNames,
  groupsNames,
  assignedProjects,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const languageOptions = supportedLanguages.map((value) => ({
    value,
    label: languages[value] ? languages[value].native : value,
  }));

  const currentLanguage = useSelector(getLanguage);
  const onLanguageChange = useCallback(
    (language) => dispatch(setLanguage(language)),
    [dispatch],
  );

  const items = useMemo(
    () => [
      {
        title: t('forms:theme.label'),
        description: <DarkModeToggleContainer />,
      },
      {
        title: t('forms:language.label'),
        description: (
          <LanguageSelector.Simple
            options={languageOptions}
            value={currentLanguage}
            onChange={onLanguageChange}
          />
        ),
      },
      user &&
        user.getFullName() && {
          title: t('forms:name.label'),
          description: user.getFullName(),
        },
      user &&
        user.getEmailAddress() && {
          title: t('forms:email.label'),
          description: user.getEmailAddress(),
        },
      user &&
        user.getPhoneNumber() && {
          title: t('forms:phone.label'),
          description: user.getPhoneNumber(),
        },
      !isEmpty(assignedProjects) && {
        title: t('project', {
          count: 0,
        }),
        description: map(assignedProjects, ({ _id, name }, i) => (
          <React.Fragment key={_id}>
            <Link to={`/projects/${_id}`}>{name}</Link>
            {assignedProjects.length !== i + 1 && <span>, </span>}
          </React.Fragment>
        )),
      },
      !isEmpty(rolesNames) && {
        title: t('forms:roles.label'),
        description: rolesNames.join(', '),
      },
      !isEmpty(groupsNames) && {
        title: t('forms:groups.label'),
        description: groupsNames.join(', '),
      },
    ],
    [
      user,
      rolesNames,
      groupsNames,
      assignedProjects,
      languageOptions,
      t,
      currentLanguage,
      onLanguageChange,
    ],
  );

  const dataSource = filter(items, (item) => item);

  return (
    <Modal
      title={t('myProfile')}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button onClick={onCancel} hasBorder={false}>
          {t('close')}
        </Button>
      }
    >
      <List
        dataSource={dataSource}
        renderItem={(item, idx) => (
          <div key={idx}>
            <Text.Paragraph importance="high">{item.title}</Text.Paragraph>
            <Text.Paragraph>{item.description}</Text.Paragraph>
          </div>
        )}
        bordered={false}
      />
    </Modal>
  );
};

CurrentUserProfile.propTypes = {
  visible: PropTypes.bool,
  user: PropTypes.instanceOf(User),
  rolesNames: PropTypes.array,
  groupsNames: PropTypes.array,
  assignedProjects: PropTypes.array,
  onCancel: PropTypes.func,
};

CurrentUserProfile.defaultProps = {
  visible: false,
  user: null,
  rolesNames: [],
  groupsNames: [],
  assignedProjects: [],
  onCancel: () => {},
};

export default CurrentUserProfile;
