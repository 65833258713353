import React, { useRef } from 'react';
import Dropdown from 'antd/es/dropdown';
import 'antd/es/dropdown/style/css';

const Component = (props) => {
  const ref = useRef();

  const handleGetPopupContainer = () => {
    const dialog = ref.current && ref.current.closest('dialog');

    if (dialog) {
      return dialog;
    }

    return document.body;
  };

  return (
    <div ref={ref}>
      <Dropdown
        getPopupContainer={handleGetPopupContainer}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};

export default Component;
