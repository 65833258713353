import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from '../common/utilsClient/ddp/actions';
import { notifyError, notifySuccess } from '../utils/notify';
import ResetPassword from '../components/dialogs/ResetPassword';

const Container = connect(
  () => ({
    open: true,
  }),
  (dispatch, { token, close }) => ({
    onSubmit: ({ newPassword }) =>
      dispatch(
        resetPassword({
          newPassword,
          token,
        }),
      )
        .then(notifySuccess('New password set!'))
        .catch(notifyError())
        .then(close),
    onCancel: () => close(),
  }),
)(ResetPassword);

Container.propTypes = {
  token: PropTypes.string.isRequired,
  close: PropTypes.func,
};

Container.defaultProps = {
  close: () => {},
};

export default Container;
