import React, { useMemo, useCallback } from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/lists/Table';
import { apiAdminSearchPermissionsDomains } from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import PermissionsDomainSelect from '../../../common/selectors/PermissionsDomain';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import {
  getFilters,
  getSorter,
  setDomain,
  setPermissionsDomainEditDialogVisible,
} from '../store';

const PermissionsDomainTable = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: domains,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: PermissionsDomainSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiAdminSearchPermissionsDomains.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const highlight = useHighlight(filters, ['name', '_id']);

  const handleSelectDomain = useCallback(
    (domain) => {
      dispatch(
        setDomain({
          id: domain._id,
          name: domain.name,
        }),
      );
      dispatch(setPermissionsDomainEditDialogVisible(true));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        title: t('Name'),
        dataIndex: 'name',
        render: (_, domain) => (
          <Highlighter text={domain.getName()} highlight={highlight} />
        ),
      },
      {
        title: t('Identifier'),
        dataIndex: '_id',
        render: (_, domain) => (
          <Highlighter text={domain.getDomain()} highlight={highlight} />
        ),
      },
      {
        title: t('Last updated'),
        dataIndex: 'updatedAt',
        render: (_, domain) => domain.formatLastUpdatedAt(),
      },
    ],
    [t, highlight],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={domains}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={(domain) => ({
        onClick: () => handleSelectDomain(domain),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default PermissionsDomainTable;
