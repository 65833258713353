import { GlobalOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Select, { StyledSelect } from '../Select';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';

const { Option } = Select;

const StyledStyledSelect = styled(StyledSelect)`
  width: 100%;
`;

const LanguageSelector = ({
  value,
  onChange,
  options,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  size,
  // eslint-disable-next-line react/prop-types
  showArrow,
}) => {
  const handleSelectLanguage = (language) => onChange(language);

  return (
    <>
      {/* Extra div because we still use antd select */}
      <div>
        <StyledStyledSelect
          size={size}
          value={value}
          onChange={handleSelectLanguage}
          showArrow={showArrow}
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'br'],
            offset: [0, 8],
          }}
          className={className}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              <Cluster wrap="nowrap" space={2}>
                <GlobalOutlined />
                <Text.Span>{option.label}</Text.Span>
              </Cluster>
            </Option>
          ))}
        </StyledStyledSelect>
      </div>
    </>
  );
};

LanguageSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

LanguageSelector.defaultProps = {
  value: null,
  onChange: () => {},
  options: [],
};

export default LanguageSelector;
