import { createStructuredSelector } from 'reselect';
import createSelectors from './createSelectors';
import ParticipationAndQuestionnaire from '../models/ParticipationAndQuestionnaire';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';
import omitPIIFields from '../utils/omitPIIFields';

const Select = createSelectors(
  ParticipationAndQuestionnaire,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (
      rawParticipationAndQuestionnaire,
      { piiRealm, variablesDb } = {},
    ) => {
      if (
        PermissionsDomain.belongsToRealm(
          rawParticipationAndQuestionnaire.ownership,
          piiRealm,
        )
      ) {
        return rawParticipationAndQuestionnaire;
      }
      const newParticipationAndQuestionnaire = omitPIIFields(
        rawParticipationAndQuestionnaire,
        null,
        variablesDb,
      );
      return newParticipationAndQuestionnaire;
    },
  },
);

export default Select;
