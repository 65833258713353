import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { theme } from '../../utilsClient/cssHelpers';

const arrowSize = 8;
const offset = 4;

const getMeasure = (size) => {
  switch (size) {
    case 'small':
      return 1;
    case 'large':
      return 5;
    default:
      return 3;
  }
};

const ComponentNew = styled.div`
  ${theme('typography.body2')};
  border-radius: ${theme('border.radius.2')};
  background-color: ${theme('color.surfaceInverted')};
  color: ${theme('color.onSurfaceInverted')};
  max-width: ${(props) => theme(`measure.${getMeasure(props.size)}`)};
  white-space: pre-line;
  margin: 0;
  padding: 0.5em 1em;
  z-index: 999;

  /* Animation */
  opacity: 0;
  pointer-events: none;
  transition: all ${theme('motion.duration.fade')}
    ${theme('motion.easing.entrance')};
  ${(props) => props.isVisible && 'opacity: 1'};

  div,
  div::before {
    position: absolute;
    width: ${arrowSize}px;
    height: ${arrowSize}px;
    z-index: -1;
  }

  div::before {
    content: '';
    transform: rotate(45deg);
    background-color: ${theme('color.surfaceInverted')};
  }

  &[data-popper-placement^='top'] > div {
    bottom: -${offset}px;
  }

  &[data-popper-placement^='bottom'] > div {
    top: -${offset}px;
  }

  &[data-popper-placement^='left'] > div {
    right: -${offset}px;
  }

  &[data-popper-placement^='right'] > div {
    left: -${offset}px;
  }
`;

const Tooltip = ({ title, placement, children, className, size }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, offset * 2],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement,
            padding: offset,
          },
        },
      ],
    },
  );

  const handleOnMouseEnter = () => setIsVisible(true);
  const handleOnMouseLeave = () => setIsVisible(false);

  useEffect(() => {
    if (update) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <>
      <span
        ref={setReferenceElement}
        className={className}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {children}
      </span>
      <ComponentNew
        ref={setPopperElement}
        style={styles.popper}
        isVisible={isVisible}
        size={size}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        {title}
        <div ref={setArrowElement} style={styles.arrow} />
      </ComponentNew>
    </>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'base', 'large']),
};

Tooltip.defaultProps = {
  title: null,
  placement: 'top',
  children: null,
  className: null,
  size: 'base',
};

export default Tooltip;
