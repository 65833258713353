/* eslint-disable react/no-danger */
import isEqual from 'lodash/isEqual';
import React from 'react';
import PropTypes from 'prop-types';
import { renderMarks } from './helpers';

const Highlight = React.memo(({ text, highlight }) => {
  return <span>{renderMarks(text, highlight)}</span>;
}, isEqual);

Highlight.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Highlight.defaultProps = {
  text: '',
  highlight: [],
};

export default Highlight;
