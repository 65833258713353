import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import InputDate from './InputDate';
import FormFieldWrapper from './FormFieldWrapper';
import {
  formFieldPropTypesInput,
  formFieldPropTypesMeta,
} from '../utilsClient/propTypes';
import { yearMonthDayShift } from '../utils/date';

const FormFieldDate = ({
  label,
  required,
  extra,
  meta,
  input: { value, onChange },
  disabled,
  plusDays,
  autoFocus,
  'data-testid': testId,
}) => {
  const handleOnChange = useCallback(
    (newValue) => {
      if (onChange) {
        if (plusDays) {
          onChange(yearMonthDayShift(newValue, plusDays));
        } else {
          onChange(newValue);
        }
      }
    },
    [onChange, plusDays],
  );
  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      extra={extra}
    >
      <InputDate
        onChange={handleOnChange}
        value={plusDays ? yearMonthDayShift(value, -plusDays) : value}
        disabled={disabled}
        autoFocus={autoFocus}
        data-testid={testId}
      />
    </FormFieldWrapper>
  );
};

FormFieldDate.propTypes = {
  input: formFieldPropTypesInput.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  extra: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  plusDays: PropTypes.number,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  'data-testid': PropTypes.string,
};

FormFieldDate.defaultProps = {
  label: '',
  extra: null,
  disabled: false,
  plusDays: 0,
  required: false,
  autoFocus: false,
  'data-testid': null,
};

export default FormFieldDate;
