import { createSelector } from 'reselect';
import { createCurrentUserSelectors } from '@theclinician/ddp-connector/lib/selectors';
import EJSON from '../utils/ejson';
import toSelector from '../utils/toSelector';
import User from '../models/User';
import RoleSelect from './Role';

const constant = (x) => () => x;

const Select = createCurrentUserSelectors(User.collection, {
  Model: User,
  prefix: User.store,
  transform: (doc) => EJSON.fromJSONValue(doc),
});

Select.getCurrentPermissionsValidator = (getOptions) =>
  createSelector(
    Select.user(),
    RoleSelect.all().byId(),
    toSelector(getOptions),
    (user, rolesDB, options) =>
      user
        ? user.getPermissionsValidator({
            rolesDB,
            ...options,
          })
        : constant(null),
  );

Select.getPermissionsRealm = (getPermissions, getOptions) =>
  createSelector(
    Select.user(),
    RoleSelect.all().byId(),
    toSelector(getPermissions),
    toSelector(getOptions),
    (user, rolesDB, permissions, options) =>
      user
        ? user.getPermissionsRealm(permissions, {
            rolesDB,
            ...options,
          })
        : constant(null),
  );

Select.getPermissionGrant = (getPermissions, getOptions) =>
  createSelector(
    Select.getCurrentPermissionsValidator(getOptions),
    toSelector(getPermissions),
    (validator, permissions) => (validator ? validator(permissions) : null),
  );

Select.hasPermission = (getPermissions, getOptions) =>
  createSelector(
    Select.getPermissionGrant(getPermissions, getOptions),
    (permissionGrant) => !!permissionGrant,
  );

Select.isOnboarded = () =>
  createSelector(Select.user(), (user) => !!(user && user.onboarded));

Select.isLoggedIn = () =>
  createSelector(
    Select.userId(),
    Select.isLoggingIn(),
    (userId, isLoggingIn) => !!userId && !isLoggingIn,
  );

export default Select;
