import { SHA256 } from '@theclinician/toolbelt';
import CurrentUserSelect from '../../selectors/CurrentUser';
import {
  isUsingTemporaryPassword,
  forgotPassword,
  resumeTokenKeepAlive as resumeTokenKeepAliveSpec,
  setOptions,
} from '../../api/currentUser';
import { ValidationError } from './errors';
import { getResumeToken } from './selectors';

export const callMethod =
  (method, params) =>
  (dispatch, getState, { ddpConnector }) =>
    method.callMethod(params, {
      client: ddpConnector,
      ValidationError,
    });

export const sendPasswordResetEmail =
  (emailOrNothing) => (dispatch, getState) => {
    let email = emailOrNothing;
    if (emailOrNothing) {
      email = emailOrNothing;
    } else {
      const user = CurrentUserSelect.user()(getState());
      if (user) {
        email = user.getEmailAddress();
      }
    }
    return dispatch(
      callMethod(forgotPassword, {
        email,
      }),
    );
  };

// NOTE: This looks weird, but the problem we are trying to solve here
//       is the get the information right after user logs in, when
//       user subscriptions may still not be ready yet. It seems
//       more robust to simply call a method rather than wait for
//       subscriptions to report "ready" state.
export const isCurrentUserPasswordTemporary = () => (dispatch) =>
  dispatch(callMethod(isUsingTemporaryPassword, {}));

export const resumeTokenKeepAlive = (condition) => (dispatch, getState) => {
  const state = getState();
  if (condition && !condition(state)) {
    return Promise.resolve();
  }
  const resumeToken = getResumeToken(state);
  const currentUser = CurrentUserSelect.user()(state);
  if (currentUser && resumeToken) {
    return dispatch(
      callMethod(resumeTokenKeepAliveSpec, {
        resumeToken,
      }),
    ).catch((err) => console.error(err));
  }
  return Promise.resolve();
};

export const setCurrentUserOptions = (options) => (dispatch) =>
  dispatch(callMethod(setOptions, options));

export const login =
  ({ email, password }) =>
  (dispatch, getState, { ddpConnector }) =>
    ddpConnector.loginWithPassword({
      email,
      password,
    });

export const login2FA =
  ({ email, password, code }) =>
  (dispatch, getState, { ddpConnector }) => {
    const options = {
      user: {
        email,
      },
      twoFactor: {
        password: {
          digest: SHA256(password),
          algorithm: 'sha-256',
        },
        code,
      },
    };
    return ddpConnector.login(options);
  };

export const logout =
  () =>
  (dispatch, getState, { ddpConnector }) =>
    ddpConnector.logout();

export const createAccount =
  ({ email, password, firstName, lastName }) =>
  (dispatch, getState, { ddpConnector }) =>
    ddpConnector.createUser({
      email,
      password,
      firstName,
      lastName,
    });

export const resetPassword =
  ({ token, newPassword }) =>
  (dispatch, getState, { ddpConnector }) =>
    ddpConnector.resetPassword({
      token,
      newPassword,
    });
