/* eslint-disable react/jsx-props-no-spreading */
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import styled, { css } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Empty from '../base/Empty';
import Text from '../base/Text';
import Skeleton from '../Skeleton';
import Pagination from '../Pagination';
import { theme } from '../../utilsClient/cssHelpers';
import { renderColumn } from './helpers';
import { selectable as selectableStyles } from './styles';

const oldTableStyles = css`
  tbody > tr:first-child > td {
    border-top: 1px solid ${theme('color.border')};
  }

  tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid ${theme('color.border')};
  }
`;

const newTableStyles = css`
  border-collapse: separate;
  border-spacing: 0 ${theme('space.2')};

  thead > tr > th,
  tbody > tr > td {
    background-color: ${theme('color.surface')};
  }
  thead > tr,
  tbody > tr {
    box-shadow: ${theme('boxShadow.base')};
  }
`;

const StyledTableWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;

  thead > tr > th {
    font-size: ${theme('font.size.small')};
    color: ${theme('color.onSurface')};
    white-space: nowrap;
  }

  tbody > tr > td {
    padding-top: ${theme('space.1')};
    padding-bottom: ${theme('space.1')};
  }

  th {
    font-weight: ${theme('font.weight.bold')};
  }

  th,
  td {
    padding: ${theme('space.2')};
  }

  tr > th:first-child,
  tr > td:first-child {
    /* padding-left: 0; */
  }

  tr > th:last-child,
  tr > td:last-child {
    /* padding-right: 0; */
  }

  ${(props) => (props.isNewStyling ? newTableStyles : oldTableStyles)};
`;

const StyledRow = styled.tr`
  ${(props) => props.isSelectable && selectableStyles}
`;

const getFixedHeaderCellStyles = (left, width, type) => {
  const styles = {};

  if (type === 'headerCell') {
    styles.textOverflow = 'ellipsis';
    styles.overflow = 'hidden';
  }

  styles.position = 'sticky';
  styles.left = left;
  styles.maxWidth = width;
  styles.boxShadow = '4px 0 4px #ebebeb';

  return styles;
};
const StyledHeaderCell = styled.th`
  ${(props) => props.fixed && getFixedHeaderCellStyles(props.left, props.width)}
`;

const StyledDataCell = styled.td`
  ${(props) => props.fixed && getFixedHeaderCellStyles(props.left, props.width)}
`;

const TableBody = ({ children, columns, loading, pageSize }) => {
  return (
    <tbody>
      {loading
        ? range(pageSize).map((index) => (
            <tr key={index}>
              {map(columns, (column) => (
                <td key={column.key}>
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))
        : children}
    </tbody>
  );
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  loading: PropTypes.bool,
};

TableBody.defaultProps = {
  columns: [],
  loading: false,
};

const getCellStyles = (columns, { key, fixed, width }) => {
  if (fixed) {
    const currentIndex = findIndex(columns, (column) => column.key === key);
    const previousColumns = filter(
      columns,
      (column, idx) => idx < currentIndex,
    );
    const left = reduce(
      previousColumns,
      (offset, column) => offset + column.width,
      0,
    );

    return {
      fixed,
      left,
      width,
    };
  }
  return {};
};

const TableRow = ({ columns, item, index, onRow }) => {
  const rowHandlers = onRow(item);
  const isSelectable = rowHandlers && rowHandlers.onClick;
  const handleOnRowClick = () =>
    rowHandlers && rowHandlers.onClick && rowHandlers.onClick();

  return (
    <StyledRow onClick={handleOnRowClick} isSelectable={isSelectable}>
      {map(columns, (column) => (
        <StyledDataCell key={column.key} {...getCellStyles(columns, column)}>
          {renderColumn({
            column,
            item,
            index,
          })}
        </StyledDataCell>
      ))}
    </StyledRow>
  );
};

TableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRow: PropTypes.func.isRequired,
};

// TODO: Add 'onChange'?

const Table = ({
  'data-testid': testId,
  title,
  className,
  dataSource,
  columns,
  rowKey,
  loading,
  pagination,
  pageSize,
  onRow,
  isNewStyling,
}) => {
  return (
    <Stack space={1}>
      {(title || pagination) && (
        <Cluster justify="space-between">
          <Text.Paragraph importance="high">{title}</Text.Paragraph>
          {pagination && (
            <Pagination
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...pagination}
            />
          )}
        </Cluster>
      )}
      <StyledTableWrapper data-testid={testId} className={className}>
        <StyledTable isNewStyling={isNewStyling}>
          <colgroup>
            {map(columns, (column, index) => (
              <col
                key={`col_${index}`}
                style={{
                  width: column.width,
                  minWidth: column.width,
                }}
              />
            ))}
          </colgroup>
          <thead>
            <tr>
              {map(columns, (column) => (
                <StyledHeaderCell
                  key={column.key}
                  {...getCellStyles(columns, column, 'headerCell')}
                >
                  {column.title}
                </StyledHeaderCell>
              ))}
            </tr>
          </thead>
          <TableBody columns={columns} loading={loading} pageSize={pageSize}>
            {isEmpty(dataSource) ? (
              <tr>
                <td colSpan={columns.length}>
                  <Empty />
                </td>
              </tr>
            ) : (
              map(dataSource, (item, index) => (
                <TableRow
                  key={rowKey ? item[rowKey] : index}
                  columns={columns}
                  item={item}
                  index={index}
                  onRow={onRow}
                />
              ))
            )}
          </TableBody>
        </StyledTable>
      </StyledTableWrapper>
    </Stack>
  );
};

Table.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  rowKey: PropTypes.string,
  loading: PropTypes.bool,
  pagination: PropTypes.objectOf(PropTypes.any),
  pageSize: PropTypes.number,
  onRow: PropTypes.func,
  isNewStyling: PropTypes.bool,
};

Table.defaultProps = {
  'data-testid': 'table',
  title: null,
  className: null,
  dataSource: [],
  columns: [],
  rowKey: null,
  loading: false,
  pagination: null,
  pageSize: 10,
  onRow: () => {},
  isNewStyling: false,
};

export default Table;
