import store from './store';
import { dialog } from '../../../../components/dialogs/Snooze';

export const setFilterBy = store.create.set('filterBy');

export const openSnoozeDialog = ({ recipientId, projectId, snoozeEnd }) =>
  dialog.open({
    snoozeEnd,
    recipientId,
    projectId,
  });

export const openSnoozeOneDialog = ({ answersSheetId, snoozeEnd }) =>
  dialog.open({
    snoozeEnd,
    answersSheetId,
  });

export const closeSnoozeDialog = () => dialog.close();
