import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import PatientRecord from '../../../../common/models/PatientRecord';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
  PARTICIPANT_STATE__CANCELED,
} from '../../../../common/constants';
import Milestone from '../../../../common/components/Milestone';
import LocalDate from '../../../../components/plates/LocalDate';

const ActivityButton = ({
  patientRecord,
  // eslint-disable-next-line react/prop-types
  state,
  activityId,
  milestoneId,
  onClick,
  // appearance,
}) => {
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
  );
  const { projectId, questionnaires } = patientRecord;

  const props = {
    onClick,
    // appearance,
    name: milestone ? milestone.getName() : 'Custom',
    className: 'zedoc-tour__milestone',
    'data-testid': `project-milestone-${
      milestone ? milestone.getName() : 'Custom'
    }-${patientRecord.recipientName}`,
  };

  const nQuestionnaires = filter(questionnaires, (q) => {
    return (
      q.state !== PARTICIPANT_STATE__CANCELED && q.activityId === activityId
    );
  }).length;

  // TODO: We can restore it after optimizing "in progress" flag monitoring
  // if (hasAnswersSheetsInProgress) {
  //   return <Milestone.InProgress {...props} />;
  // }
  if (state === ACTIVITY_STATE__CANCELED || state === ACTIVITY_STATE__ABORTED) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Milestone.Canceled {...props} />;
  }
  if (state === ACTIVITY_STATE__COMPLETED) {
    return (
      <Milestone.Completed
        label={
          <LocalDate
            date={patientRecord.getActivityCompletedAt(activityId)}
            projectId={projectId}
            // showTimezone
            short
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  if (state === ACTIVITY_STATE__OMITTED) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Milestone.Omitted {...props} />;
  }
  if (state === ACTIVITY_STATE__EXPIRED) {
    return (
      <Milestone.Incomplete
        label={
          <LocalDate
            date={patientRecord.getActivityDateEnd(activityId)}
            projectId={projectId}
            // showTimezone
            short
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  if (state === ACTIVITY_STATE__ACTIVE) {
    if (nQuestionnaires === 0) {
      return (
        <Milestone.Empty
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }
    return (
      <Milestone.Due
        label={
          <LocalDate
            date={patientRecord.getActivityDateEnd(activityId)}
            projectId={projectId}
            // showTimezone
            short
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  if (state === ACTIVITY_STATE__SUSPENDED) {
    return (
      <Milestone.Suspended
        label={
          <LocalDate
            date={patientRecord.getActivityDateEnd(activityId)}
            projectId={projectId}
            // showTimezone
            short
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  if (
    state === ACTIVITY_STATE__SCHEDULED ||
    state === ACTIVITY_STATE__PLANNED
  ) {
    return (
      <Milestone.Upcoming
        label={
          <LocalDate
            date={patientRecord.getActivityDateStart(activityId)}
            projectId={projectId}
            // showTimezone
            short
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Milestone.Custom {...props} />
  );
};

ActivityButton.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  activityId: PropTypes.string.isRequired,
  state: PropTypes.string,
  milestoneId: PropTypes.string,
  onClick: PropTypes.func,
};

const noop = () => {};
ActivityButton.defaultProps = {
  state: null,
  milestoneId: null,
  onClick: noop,
};

export default ActivityButton;
