import styled from 'styled-components/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputSearch from '../../../common/components/InputSearch';

const StyledSearch = styled(InputSearch)`
  width: initial;
  max-width: 300px;
`;

const SearchComponent = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledSearch placeholder={t('search')} {...rest} />
    </div>
  );
};

export default SearchComponent;
