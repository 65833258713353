import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import map from 'lodash/map';
import { useDDPSubscription } from '@theclinician/ddp-connector';

import { all as allPermissionsDomains } from '../../../../common/api/collections/PermissionsDomains';
import PermissionsDomainSelect from '../../../../common/selectors/PermissionsDomain';
import { ADMIN_CREATE_ROLE } from '../../../../common/permissions';
import CurrentUserSelect from '../../../../common/selectors/CurrentUser';
import Stack from '../../../../common/components/primitives/Stack';
import FormItem from '../../../../common/components/FormItem';
import Input from '../../../../common/components/Input';
import Select from '../../../../components/inputs/Select';
import { getSelectedRole, setSelectedRole } from '../../store';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useDDPSubscription(allPermissionsDomains.withParams());

  const groups = useSelector(
    PermissionsDomainSelect.all().whereUserHasPermission(
      CurrentUserSelect.user(),
      ADMIN_CREATE_ROLE,
    ),
  );

  const groupOptions = useMemo(
    () =>
      map(groups, (group) => ({
        label: group.name,
        value: group._id,
      })),
    [groups],
  );

  const currentRole = useSelector(getSelectedRole);

  const [roleGroup, setRoleGroup] = useState(
    (currentRole && currentRole.belongsTo) || '',
  );

  const [roleName, setRoleName] = useState(
    (currentRole && currentRole.name) || '',
  );

  const onGroupsChange = useCallback(
    (params) => {
      const roleCopy = {
        ...currentRole,
        belongsTo: params,
      };
      setRoleGroup(params);
      dispatch(setSelectedRole(roleCopy));
    },
    [currentRole, setRoleGroup, dispatch],
  );

  const onRoleNameChange = useCallback(
    (value) => {
      const roleCopy = {
        ...currentRole,
        name: value,
      };
      setRoleName(value);
      dispatch(setSelectedRole(roleCopy));
    },
    [currentRole, setRoleName, dispatch],
  );

  return (
    <form>
      <Stack>
        <FormItem label={t('name')}>
          <Input
            value={roleName}
            onChange={(e) => onRoleNameChange(e.target.value)}
          />
        </FormItem>
        <FormItem label={t('domain')}>
          <StyledSelect
            value={roleGroup}
            onChange={onGroupsChange}
            options={groupOptions}
          />
        </FormItem>
      </Stack>
    </form>
  );
};

export default Form;
