import React, { useState, useCallback } from 'react';
import { compose, withProps } from 'recompose';
import { useTranslation } from 'react-i18next';
import Cluster from '../../../common/components/primitives/Cluster';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import Input from '../../inputs/Input';

const FormFieldText = compose(
  withProps(({ input, meta, onGenerateValue, ...custom }) => ({
    props: {
      ...input,
      value: input.value === null ? '' : input.value,
      ...custom,
    },
  })),
)(({ onGenerateValue, label, required, tooltip, meta, extra, props }) => {
  const { t } = useTranslation();

  const [isValueGenerating, setIsValueGenerating] = useState(false);

  const { value, onChange } = props;

  const handleOnClick = useCallback(() => {
    if (onGenerateValue) {
      setIsValueGenerating(true);
      Promise.resolve(onGenerateValue(value))
        .then((newValue) => {
          if (onChange && typeof newValue === 'string') {
            onChange(newValue);
          }
          setIsValueGenerating(false);
        })
        .catch(() => {
          setIsValueGenerating(false);
        });
    }
  }, [value, onChange, onGenerateValue, setIsValueGenerating]);

  return (
    <FormFieldWrapper
      label={
        onGenerateValue ? (
          <Cluster>
            <Text.Span>{label}</Text.Span>
            <Button
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-button-generate`
                  : 'button-generate'
              }
              size="small"
              loading={isValueGenerating}
              disabled={props.disabled}
              onClick={handleOnClick}
            >
              {t('generate')}
            </Button>
          </Cluster>
        ) : (
          label
        )
      }
      tooltip={tooltip}
      extra={extra}
      required={required}
      meta={meta}
    >
      <Input
        // NOTE: This can be overwritten if testid is provided via props.
        data-testid={`form-field-${label}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </FormFieldWrapper>
  );
});

export default FormFieldText;
