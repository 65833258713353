import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector } from 'react-redux';
import SignaturePad from 'signature_pad';
import { QUESTION_TYPE__SIGNATURE } from '../common/constants';
import AnswersSheetSelect from '../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../common/selectors/Questionnaire';
import QuestionnaireTranslationSelect from '../common/selectors/QuestionnaireTranslation';
import ProjectSelect from '../common/selectors/Project';
import ProjectMilestoneSelect from '../common/selectors/ProjectMilestone';
import RecipientSelect from '../common/selectors/Recipient';
import { one as oneAnswersSheet } from '../common/api/collections/AnswersSheets';
import {
  one as oneQuestionnaire,
  questionnaireTranslation,
} from '../common/api/collections/Questionnaires2';
import { presentAnswersSheet } from '../common/utils/questionnaire';
import { projectMilestones } from '../common/api/zedoc';

const getDescription = ({ questionType, value }) => {
  if (questionType === QUESTION_TYPE__SIGNATURE) {
    const canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.left = '-9999px';
    document.body.appendChild(canvas);
    const signaturePad = new SignaturePad(canvas);
    signaturePad.fromData(JSON.parse(value));
    const dataURL = signaturePad.toDataURL();
    document.body.removeChild(canvas);

    return `![](${dataURL})`;
  }

  return value.toString();
};

const usePresentableAnswersSheet = (answersSheetId, currentLanguage) => {
  useDDPSubscription(
    answersSheetId && oneAnswersSheet.withParams(answersSheetId),
  );

  const answersSheet = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );
  const questionnaireId = answersSheet && answersSheet.getQuestionnaireId();
  const { projectId, recipientId, milestoneId, translationId } =
    answersSheet || {};

  useDDPSubscription(
    questionnaireId && oneQuestionnaire.withParams(questionnaireId),
  );
  useDDPSubscription(
    translationId &&
      questionnaireTranslation.withParams({
        translationId,
      }),
  );
  useDDPSubscription(
    projectId &&
      projectMilestones.withParams({
        projectId,
      }),
  );

  const translation = useSelector(
    QuestionnaireTranslationSelect.one().whereIdEquals(translationId),
  );
  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const translatedQuestionnaire =
    translation &&
    translation.language === currentLanguage &&
    translation.translate(questionnaire);
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
  );

  const meta = [
    {
      label: 'NAME',
      value: recipient && recipient.getFullName(),
    },
    {
      label: 'NRIC',
      value: recipient && recipient.getNationalId(),
    },
    {
      label: 'PROJECT NAME',
      value: project && project.name,
    },
    {
      label: 'MILESTONE NAME',
      value: milestone && milestone.name,
    },
    {
      label: 'QUESTIONNAIRE',
      value: questionnaire && questionnaire.name,
    },
    {
      label: 'COMPLETION TIMESTAMP',
      value:
        project &&
        answersSheet &&
        project
          .getMomentInLocalTime(answersSheet.completedAt)
          .format('YYYY-MM-DD HH:mm'),
    },
  ];
  const valueLabelPairs =
    questionnaire && answersSheet
      ? presentAnswersSheet(
          translatedQuestionnaire || questionnaire,
          answersSheet,
          {
            filterQuestions: (q) => !q.isCollection(),
          },
        )
      : [];
  const responses = valueLabelPairs.map(({ value, label, questionType }) => {
    return {
      title: label,
      description: getDescription({
        questionType,
        value,
      }),
    };
  });

  return {
    meta,
    responses,
  };
};

export default usePresentableAnswersSheet;
