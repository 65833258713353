/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import theming from 'styled-theming';
import styled, { css } from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import palette from '../../utilsClient/palette';
import Milestone from './Milestone';

const backgroundColor = theming('mode', {
  light: css`
    background-color: rgb(169, 227, 146);
  `,
  dark: css`
    background-color: ${palette.green[40]};
    color: ${palette.gray[90]};
  `,
});

const StyledMilestone = styled(Milestone)`
  ${backgroundColor};
`;

const MilestoneCompleted = ({ ...props }) => {
  const { t } = useTranslation('components');

  return (
    <StyledMilestone
      status={t('Milestone.completed')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default MilestoneCompleted;
