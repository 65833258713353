import styled from 'styled-components/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyAntd from 'antd/es/empty';
import 'antd/es/empty/style/css';
import { theme } from '../../../utilsClient/cssHelpers';

const Component = styled(EmptyAntd)`
  && {
    color: ${theme('color.onSurface')};
  }
`;

const Empty = () => {
  const { t } = useTranslation();

  return <Component description={t('empty')} />;
};

Empty.defaultProps = {
  description: null,
};

export default Empty;
