import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectProfileDialog from '../../../components/dialogs/ProjectProfile';
import { getProjectProfileDialog, closeProjectProfileDialog } from '../store';

const ConnectedProjectProfileDialog = ({ projectId: defaultProjectId }) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeProjectProfileDialog());
  }, [dispatch]);
  const options = useSelector(getProjectProfileDialog);
  if (!options) {
    return (
      <ProjectProfileDialog
        key=""
        visible={false}
        projectId={defaultProjectId}
      />
    );
  }
  const {
    projectId = defaultProjectId,
    recipientId,
    participationId,
  } = options;
  return (
    <ProjectProfileDialog
      key={projectId}
      projectId={projectId}
      recipientId={recipientId}
      participationId={participationId}
      onCancel={handleOnCancel}
      visible
    />
  );
};

ConnectedProjectProfileDialog.propTypes = {
  projectId: PropTypes.string,
};

ConnectedProjectProfileDialog.defaultProps = {
  projectId: null,
};

export default ConnectedProjectProfileDialog;
