/* eslint-disable react/prop-types */
import styled from 'styled-components';
import {
  LeftOutlined,
  RightOutlined,
  // SaveOutlined,
  SettingOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from '../../../common/components/base/Text';
import Box from '../../../common/components/primitives/Box';
import Cover from '../../../common/components/primitives/Cover';
import Stack from '../../../common/components/primitives/Stack';
import Switcher from '../../../common/components/primitives/Switcher';
import Cluster from '../../../common/components/primitives/Cluster';
import Button from '../../../common/components/Button';
import Markdown from '../../../common/components/Markdown';
import Explanation from './Explanation';

const StyledSwitcher = styled(Switcher)`
  /* Experimental full height */
  &,
  > div,
  > div > div {
    height: 100%;
  }
`;

const Layout = ({
  title,
  descriptionsKeys,
  explanations,
  forms,
  preview,

  // FIXME: If we remove Save button then we need to remove all onSave mentions
  // onSave,
  hasPrevTab,
  hasNextTab,
  onOpenPrevTab,
  onOpenNextTab,
  onComplete,
}) => {
  const { t } = useTranslation();
  return (
    <StyledSwitcher space={0}>
      {forms.map(
        (form) =>
          form && (
            <div
              key={form.key}
              style={{
                overflowY: 'auto',
              }}
            >
              {title && (
                <Box
                  style={{
                    paddingBottom: 0,
                  }}
                >
                  <Text.Heading data-testid={`pw-subheader-${title}`} level={2}>
                    {title}
                  </Text.Heading>
                </Box>
              )}
              <Box className="project-wizard-layout-form">{form}</Box>
            </div>
          ),
      )}
      <Cover
        centeredSelector=".project-wizard-layout-preview"
        isScrollable
        isStretched
        hasPadding
      >
        <Stack>
          {explanations &&
            explanations.map((explanationProps) => {
              if (explanationProps) {
                return (
                  <Explanation
                    key={explanationProps.explanationKey}
                    explanationKey={explanationProps.explanationKey}
                    shortExplanationKey={explanationProps.shortExplanationKey}
                    icon={explanationProps.icon}
                  />
                );
              }
              return null;
            })}
          {descriptionsKeys &&
            descriptionsKeys.map((descriptionKey) => (
              <Cluster space={2} wrap="nowrap">
                <SettingOutlined
                  style={{
                    fontSize: '2.5rem',
                  }}
                />
                <Markdown source={t(descriptionKey)} />
              </Cluster>
            ))}
        </Stack>
        <Box.Primary className="project-wizard-layout-preview" boxShadow="base">
          <Stack>
            <Text.Heading level={3} align="center">
              Preview
            </Text.Heading>
            {preview}
          </Stack>
        </Box.Primary>
        <Cluster justify="space-between">
          <Button
            data-testid="button-Back"
            size="large"
            icon={<LeftOutlined />}
            onClick={onOpenPrevTab}
            disabled={!hasPrevTab}
          >
            {/* FIXME: Translate */}
            Back
          </Button>
          {hasNextTab ? (
            <Button
              data-testid="button-Next"
              type="primary"
              size="large"
              onClick={onOpenNextTab}
            >
              <Cluster space={2}>
                {/* FIXME: Translate */}
                <Text.Span>Next</Text.Span>
                <RightOutlined />
              </Cluster>
            </Button>
          ) : (
            <Button
              data-testid="button-Complete"
              type="primary"
              size="large"
              icon={<CheckOutlined />}
              onClick={onComplete}
            >
              {t('complete')}
            </Button>
          )}
          {/* <Button
            type="primary"
            size="large"
            icon={<SaveOutlined />}
            onClick={onSave}
          >
            Save
          </Button> */}
        </Cluster>
      </Cover>
    </StyledSwitcher>
  );
};

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  forms: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  descriptionsKeys: PropTypes.array,
};

Layout.defaultProps = {
  forms: [],
  descriptionsKeys: [],
};

export default Layout;
