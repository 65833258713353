import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  RESOURCE_TYPE__PROJECT,
  RESOURCE_TYPE__MILESTONE,
  PROJECT_ATTACH_PARTICIPATION,
  PROJECT_PROFILE_CREATE_PARTICIPATION,
  PROJECT_PROFILE_UPDATE_PARTICIPATION,
  PATIENT_REVIEW_PATIENT,
  PATIENT_CREATE_PATIENT,
  PATIENT_UPDATE_PATIENT,
  PATIENT_ATTACH_PARTICIPATION,
  PATIENT_DELETE_PATIENT,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_MILESTONE_CREATE_ACTIVITY,
  PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET,
  PATIENT_MILESTONE_CREATE_ANSWERS_SHEET,
  PATIENT_CREATE_PATIENT_NOTE,
  PATIENT_UPDATE_PATIENT_NOTE,
  PATIENT_DELETE_PATIENT_NOTE,
  ORGANIZATION_ACCESS_VARIABLE,
} from '../../permissions';
import {
  QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
  ANSWERS_SHEET_NAVIGATION_TYPES,
} from '../../constants';

// SUBSCRIPTIONS

export const one = new ApiSpec({
  name: 'api.collections.Recipients.one',
  permissions: [PATIENT_REVIEW_PATIENT],
  schema: new Schema({
    recipientId: {
      type: String,
    },
  }),
});

// QUERIES/ MUTATIONS

export const insert = new ApiSpec({
  name: 'api.collections.Recipients.insert',
  resources: [
    {
      action: PATIENT_CREATE_PATIENT,
      inAllOf: 'domains',
      variables: {
        withPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
      creating: true,
    },
    {
      variables: {
        variablesRealm: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: new Schema({
    domains: {
      type: [String],
    },
    name: {
      type: new Schema({
        text: {
          type: String,
          optional: true,
        },
        family: {
          type: String,
          optional: true,
        },
        given: {
          type: [String],
          optional: true,
        },
      }),
      optional: true,
    },
    identifiers: {
      type: [
        new Schema({
          value: {
            type: String,
          },
          namespace: {
            type: String,
          },
        }),
      ],
      optional: true,
    },
    emails: {
      type: [
        new Schema({
          address: {
            type: String,
            regEx: Schema.RegEx.Email,
          },
          type: {
            type: String,
            optional: true,
          },
        }),
      ],
      optional: true,
    },
    phones: {
      type: [
        new Schema({
          number: {
            type: String,
          },
          type: {
            type: String,
            optional: true,
          },
        }),
      ],
      optional: true,
    },
    ethnicity: {
      type: String,
      optional: true,
    },
    gender: {
      type: String,
      optional: true,
    },
    birthDate: {
      type: String,
      optional: true,
    },
  }),
});

export const update = new ApiSpec({
  name: 'api.collections.Recipients.update',
  resources: [
    {
      action: PATIENT_UPDATE_PATIENT,
      variables: {
        withPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
    {
      variables: {
        canCreateIn: PATIENT_CREATE_PATIENT,
        canDeleteIn: PATIENT_DELETE_PATIENT,
        variablesRealm: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: new Schema({
    domains: {
      type: [String],
    },
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    name: {
      type: new Schema({
        text: {
          type: String,
          optional: true,
        },
        family: {
          type: String,
          optional: true,
        },
        given: {
          type: [String],
          optional: true,
        },
      }),
      optional: true,
    },
    identifiers: {
      type: [
        new Schema({
          value: {
            type: String,
          },
          namespace: {
            type: String,
          },
        }),
      ],
      optional: true,
    },
    emails: {
      type: [
        new Schema({
          address: {
            type: String,
          },
          type: {
            type: String,
            optional: true,
          },
        }),
      ],
      optional: true,
    },
    phones: {
      type: [
        new Schema({
          number: {
            type: String,
          },
          type: {
            type: String,
            optional: true,
          },
        }),
      ],
      optional: true,
    },
    ethnicity: {
      type: String,
      optional: true,
    },
    gender: {
      type: String,
      optional: true,
    },
    birthDate: {
      type: String,
      optional: true,
    },
  }),
});

export const removeCascade = new ApiSpec({
  name: 'api.collections.Recipients.removeCascade',
  permissions: [PATIENT_DELETE_PATIENT],
  schema: new Schema({
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const addToProject = new ApiSpec({
  name: 'api.collections.Recipients.addToProject',
  resources: [
    {
      action: PROJECT_ATTACH_PARTICIPATION,
    },
    {
      action: PATIENT_ATTACH_PARTICIPATION,
    },
    {
      action: PROJECT_PROFILE_CREATE_PARTICIPATION,
      inAllOf: 'project.ownership',
      creating: true,
    },
  ],
  schema: new Schema({
    recipientId: {
      type: String,
    },
    projectId: {
      type: String,
    },
    trackId: {
      type: String,
      optional: true,
    },
    milestoneId: {
      type: String,
      optional: true,
    },
    milestoneDate: {
      type: String,
      optional: true,
    },
  }),
});

export const addToMilestone = new ApiSpec({
  name: 'api.collections.Recipients.addToMilestone',
  resources: [
    {
      type: RESOURCE_TYPE__MILESTONE,
    },
    {
      action: PROJECT_PROFILE_UPDATE_PARTICIPATION,
    },
    {
      action: PATIENT_MILESTONE_CREATE_ACTIVITY,
      inAllOf: 'milestone.ownership',
      creating: true,
    },
  ],
  schema: new Schema({
    participationId: {
      type: String,
    },
    milestoneId: {
      type: String,
    },
    customSchedule: {
      type: new Schema({
        dateStart: {
          type: String,
          optional: true,
        },
        dateEnd: {
          type: String,
          optional: true,
        },
        timeStart: {
          type: String,
          optional: true,
        },
        timeEnd: {
          type: String,
          optional: true,
        },
      }),
      optional: true,
    },
  }),
});

export const addAnswersSheets = new ApiSpec({
  name: 'api.collections.Recipients.addAnswersSheets',
  resources: [
    {
      type: RESOURCE_TYPE__PROJECT,
    },
    {
      action: PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET,
    },
    {
      action: PATIENT_MILESTONE_CREATE_ANSWERS_SHEET,
      inAllOf: 'activity.ownership',
      creating: true,
    },
  ],
  schema: new Schema({
    activityId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    questionnaires: {
      type: [
        new Schema({
          identifier: {
            type: String,
          },
          assigneeType: {
            type: String,
            allowedValues: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
          },
          navigationType: {
            type: String,
            optional: true,
            allowedValues: ANSWERS_SHEET_NAVIGATION_TYPES,
          },
          delegatedToPatient: {
            type: Boolean,
            optional: true,
          },
        }),
      ],
      minCount: 1,
    },
  }),
});

export const insertNote = new ApiSpec({
  name: 'api.collections.Recipients.insertNote',
  permissions: [PATIENT_CREATE_PATIENT_NOTE],
  schema: new Schema({
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    content: {
      type: String,
    },
  }),
});

export const updateNote = new ApiSpec({
  name: 'api.collections.Recipients.updateNote',
  permissions: [PATIENT_UPDATE_PATIENT_NOTE],
  schema: new Schema({
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    id: {
      type: String,
    },
    content: {
      type: String,
    },
  }),
});

export const removeNote = new ApiSpec({
  name: 'api.collections.Recipients.removeNote',
  permissions: [PATIENT_DELETE_PATIENT_NOTE],
  schema: new Schema({
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    id: {
      type: String,
    },
  }),
});
