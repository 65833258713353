import {
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  // FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
} from '../../../../common/constants';

const defaultFilters = [
  {
    name: 'Completion date',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'completedAt',
    },
    meta: {
      perspectives: [DASHBOARD_PERSPECTIVE__RESPONSES],
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
  {
    name: 'Milestone',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'milestoneName',
    },
    meta: {
      perspectives: [
        DASHBOARD_PERSPECTIVE__RESPONSES,
        DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
        DASHBOARD_PERSPECTIVE__ACTIVITIES,
      ],
      conditions: [FILTER_CONDITION__INCLUDE],
    },
  },
  {
    name: 'Any of',
    type: FILTER_TYPE__ANY_OF,
    settings: {
      filters: [],
    },
    meta: {
      nested: true,
    },
  },
  {
    name: 'State',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'state',
    },
    meta: {
      perspectives: [DASHBOARD_PERSPECTIVE__ASSIGNMENTS],
      conditions: [FILTER_CONDITION__INCLUDE],
    },
  },
  {
    name: 'Activity start',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'dateStart',
    },
    meta: {
      perspectives: [
        DASHBOARD_PERSPECTIVE__RESPONSES,
        DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
        DASHBOARD_PERSPECTIVE__ACTIVITIES,
      ],
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
  {
    name: 'Activity end',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'dateEnd',
    },
    meta: {
      perspectives: [
        DASHBOARD_PERSPECTIVE__RESPONSES,
        DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
        DASHBOARD_PERSPECTIVE__ACTIVITIES,
      ],
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
];

export default defaultFilters;
