import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

const mergeLanguagePreference = (
  patientLanguagePreference,
  projectLanguagePreference,
) => {
  return uniq([
    ...filter(patientLanguagePreference, (language) =>
      includes(projectLanguagePreference, language),
    ),
    ...(projectLanguagePreference || []),
  ]);
};

export default mergeLanguagePreference;
