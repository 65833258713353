import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { withHandlers, compose, setDisplayName } from 'recompose';
import {
  SORTING_ORDER__ASCENDING,
  SORTING_ORDER__DESCENDING,
  ROLE_TIER__MEDIUM,
  // ROLE_TIER__HIGH,
} from '../../../../common/constants';
import { PROJECT_PROFILE_VIEW_ANALYTICS } from '../../../../common/permissions';
import User from '../../../../common/models/User';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';
import ProjectFilter from '../../../../common/models/ProjectFilter';
import PatientRecord from '../../../../common/models/PatientRecord';
import Stack from '../../../../common/components/primitives/Stack';
import ActivitiesDirectory from './ActivitiesDirectory';

const propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
  onPatientClick: PropTypes.func,
  onMilestoneClick: PropTypes.func,
  patients: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone)),
  dateRangeFilter: PropTypes.instanceOf(ProjectFilter),
  currentSorting: PropTypes.string,
  currentSortingOrder: PropTypes.string.isRequired,
  sortingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  onAddMilestone: PropTypes.func,
  onRemoveMilestone: PropTypes.func,
  onSelectFilter: PropTypes.func,
  onSelectSorting: PropTypes.func,
  onSelectSortingOrder: PropTypes.func,
};

const noop = () => {};
const defaultProps = {
  onPatientClick: noop,
  onMilestoneClick: noop,
  patients: [],
  milestones: [],
  sortingOptions: [],
  dateRangeFilter: null,
  currentSorting: null,
  onAddMilestone: noop,
  onRemoveMilestone: noop,
  onSelectFilter: noop,
  onSelectSorting: noop,
  onSelectSortingOrder: noop,
};

const getSortingOptionBy = (sortingOptions, key) =>
  find(sortingOptions, ({ settings }) => settings.id === key);

const ProjectTable = compose(
  withHandlers({
    // If user has low tier, they cannot display charts of patients that are not assigned to them ...
    getIsDisabled:
      ({ validate, currentUser }) =>
      (record) => {
        const grant = validate(PROJECT_PROFILE_VIEW_ANALYTICS);

        if (!grant) {
          return true;
        }

        if (
          grant.tier < ROLE_TIER__MEDIUM &&
          !record.isAssignedTo(currentUser._id)
        ) {
          return true;
        }

        return false;
      },
    getIsCurrentSortOrderBy:
      ({ sortingOptions, currentSorting }) =>
      (key) => {
        const currentSortingOption = getSortingOptionBy(sortingOptions, key);
        if (!currentSortingOption) {
          return false;
        }
        return currentSorting === currentSortingOption.id;
      },
    getSortOrder:
      ({ currentSortingOrder }) =>
      () => {
        if (!currentSortingOrder) {
          return false;
        }
        return currentSortingOrder === SORTING_ORDER__ASCENDING
          ? 'ascend'
          : 'descend';
      },
  }),
  withHandlers({
    onSelectSorting:
      ({ onSelectSorting }) =>
      (e, item) =>
        onSelectSorting(item.props.value),
    onSelectSortingOrder:
      ({ onSelectSortingOrder }) =>
      (e, item) =>
        onSelectSortingOrder(item.props.value),
    onSelectFilter:
      ({ dateRangeFilter, onSelectFilter }) =>
      (range) =>
        onSelectFilter(
          dateRangeFilter.id,
          range.length > 0
            ? ProjectFilter.createDateRangeState(range[0], range[1])
            : {},
        ),
    handleTableChange:
      ({ sortingOptions, onSelectSorting, onSelectSortingOrder }) =>
      (pagination, filters, sorter) => {
        if (isEmpty(sorter)) {
          onSelectSorting(null);
          onSelectSortingOrder(null);
        }

        if (!isEmpty(sorter)) {
          const { column, order } = sorter;
          const { key } = column;
          const sorting = getSortingOptionBy(sortingOptions, key);

          if (sorting) {
            onSelectSorting(sorting.id);
            onSelectSortingOrder(
              order === 'descend'
                ? SORTING_ORDER__DESCENDING
                : SORTING_ORDER__ASCENDING,
            );
          }
        }
      },
  }),
  setDisplayName('ProjectTable'),
)(
  ({
    patients,
    milestones,
    variables,
    // handleTableChange,
    subscriptionsReady,
    nPatients,
    currentPage,
    pageSize,
    onPageChange,
    onShowSizeChange,
    // project,
    projectId,
    onMilestoneClick,
    onPatientClick,
    getIsDisabled,
    onSetActivePatientId,
  }) => {
    return (
      <Stack>
        <ActivitiesDirectory
          patients={patients}
          loading={!subscriptionsReady}
          pagination={{
            total: nPatients,
            current: currentPage,
            onChange: onPageChange,
            pageSizeOptions: ['5', '10', '15'],
            size: 'small',
            showSizeChanger: true,
            pageSize,
            onShowSizeChange,
            itemRender: (page, type, originalElement) => (
              <span data-testid={`pagination-${type}-${page}`}>
                {originalElement}
              </span>
            ),
          }}
          // onChange={handleTableChange}
          projectId={projectId}
          onPatientClick={onPatientClick}
          getIsDisabled={getIsDisabled}
          milestones={milestones}
          variables={variables}
          onMilestoneClick={onMilestoneClick}
          onSetActivePatientId={onSetActivePatientId}
        />
      </Stack>
    );
  },
);

ProjectTable.propTypes = propTypes;
ProjectTable.defaultProps = defaultProps;

export default ProjectTable;
