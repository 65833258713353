// @ts-check
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

/**
 * @param {number} value
 * @param {number | null} [precision]
 * @returns {number}
 */
const withPrecision = (value, precision) => {
  if (isNaN(value) || isNil(precision)) {
    return value;
  }
  const base = 10 ** (precision || 0);
  return Math.round(value * base) / base;
};

/**
 * @param {object} options
 * @param {number} options.step
 * @param {number} [options.max]
 * @param {number} [options.min]
 * @param {number} [options.precision]
 * @param {number | null} options.value
 */
export const getSubtractedValue = ({ value, max, min, step, precision }) => {
  const clean = (x) => {
    return withPrecision(isNil(min) ? x : Math.max(x, min), precision);
  };
  if (isNil(value)) {
    if (isNil(max)) {
      return step;
    }
    return clean(max - step);
  }
  if (!isNil(max) && value > max) {
    return max;
  }
  return clean(value - step);
};

/**
 * @param {object} options
 * @param {number} options.step
 * @param {number} [options.max]
 * @param {number} [options.min]
 * @param {number} [options.precision]
 * @param {number | null} options.value
 */
export const getIncreasedValue = ({ value, max, min, step, precision }) => {
  const clean = (x) => {
    return withPrecision(isNil(max) ? x : Math.min(x, max), precision);
  };
  if (isNil(value)) {
    if (isNil(min)) {
      return step;
    }
    return clean(min + step);
  }
  if (!isNil(min) && value < min) {
    return min;
  }
  return clean(value + step);
};

/**
 * @param {number | string | null | undefined} value
 * @param {number | null} [precision]
 * @returns {string}
 */
export const formatToString = (value, precision) => {
  if (typeof value === 'string') {
    return value;
  }
  if (isNil(value) || isNaN(value)) {
    return '';
  }
  if (isNil(precision)) {
    return value.toString();
  }
  return value.toFixed(precision);
};

/**
 * @param {string | null | undefined} value
 * @param {number | null} [precision]
 * @returns {number | null | undefined}
 */
export const formatToNumber = (value, precision) => {
  if (isNil(value) || value === '') {
    return null;
  }
  const number = withPrecision(Number.parseFloat(value), precision);
  if (isNaN(number)) {
    return undefined;
  }
  return number;
};
