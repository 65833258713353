import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Profile, Question } from 'styled-icons/remix-line';
import {
  Forms,
  Settings,
  SignOut,
  Recipient,
  Projects,
} from '../../../common/components/icons';
import {
  PROJECT_SEARCH_PROJECT,
  PATIENT_SEARCH_PATIENT,
  ADMIN_SEARCH_USER,
  ADMIN_SEARCH_AUDIT_LOGS,
  ADMIN_SEARCH_PROJECT,
  ADMIN_SEARCH_REPORT,
  ADMIN_SEARCH_WORKFLOW,
  ADMIN_SEARCH_GROUP,
  ADMIN_SEARCH_DOMAIN,
  ADMIN_SEARCH_ROLE,
  ADMIN_SEARCH_VARIABLE,
  ORGANIZATION_SEARCH_QUESTIONNAIRE,
} from '../../../common/permissions';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import Navigation from '../../../common/components/Navigation';
import Logo from '../../../common/components/Logo';
import CurrentUserProfile from '../../dialogs/CurrentUserProfile';
import usePermission from '../../../utils/usePermission';
import branding from '../../../utils/branding';
import settings from '../../../common/settings';

const {
  helpdeskLink = 'https://theclinician.atlassian.net/servicedesk/customer/portal/3',
} = settings.public;

const Drawer = ({
  // eslint-disable-next-line react/prop-types
  location,
  onEdit,
  onLogout,
}) => {
  const { t } = useTranslation();
  const validate = useSelector(
    CurrentUserSelect.getCurrentPermissionsValidator(),
  );
  const canBrowseProjects = usePermission([PROJECT_SEARCH_PROJECT]);
  const canBrowsePatients = usePermission([PATIENT_SEARCH_PATIENT]);
  const canBrowseQuestionnaires = usePermission([
    ORGANIZATION_SEARCH_QUESTIONNAIRE,
  ]);
  const canBrowseSettings =
    validate(ADMIN_SEARCH_USER) ||
    validate(ADMIN_SEARCH_AUDIT_LOGS) ||
    validate(ADMIN_SEARCH_PROJECT) ||
    validate(ADMIN_SEARCH_REPORT) ||
    validate(ADMIN_SEARCH_WORKFLOW) ||
    validate(ADMIN_SEARCH_GROUP) ||
    validate(ADMIN_SEARCH_DOMAIN) ||
    validate(ADMIN_SEARCH_ROLE) ||
    validate(ADMIN_SEARCH_VARIABLE);
  const userId = useSelector(CurrentUserSelect.userId());

  const items = [
    canBrowseProjects
      ? {
          id: 'projects',
          label: t('project', {
            count: 0,
          }),
          icon: <Projects />,
          to: '/projects',
        }
      : null,
    canBrowsePatients
      ? {
          id: 'patients',
          label: t('recipient', {
            count: 0,
            context: branding,
          }),
          icon: <Recipient />,
          to: '/patients',
        }
      : null,
    canBrowseQuestionnaires
      ? {
          id: 'forms',
          label: t('allForms'),
          icon: <Forms />,
          to: '/forms',
        }
      : null,
    canBrowseSettings
      ? {
          id: 'my-settings',
          label: t('settings'),
          icon: <Settings />,
          to: '/settings/users',
        }
      : null,
    userId
      ? {
          id: 'my-profile',
          label: t('myProfile'),
          icon: <Profile />,
          onClick: onEdit,
        }
      : null,
    userId
      ? {
          id: 'my-log-out',
          label: t('signOut'),
          icon: <SignOut />,
          onClick: onLogout,
        }
      : null,
  ];

  const extra = [];

  if (helpdeskLink) {
    extra.push({
      id: 'helpdesk',
      label: t('helpdesk'),
      icon: <Question />,
      href: helpdeskLink,
    });
  }

  return (
    <>
      <Navigation
        logo={{
          children: <Logo branding={branding} />,
          to: '/',
        }}
        items={items}
        extra={extra}
        currentPath={location && location.pathname}
      />
      <CurrentUserProfile />
    </>
  );
};

Drawer.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Drawer.defaultProps = {
  location: null,
};

export default Drawer;
