import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import { createStructuredSelector } from 'reselect';
import { createEntitiesSelectors } from '@theclinician/ddp-connector/lib/selectors';
import createSelectors from './createSelectors';
import Recipient from '../models/Recipient';
import NotificationLog from '../models/NotificationLog';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';

const RecipientSelect = createEntitiesSelectors(Recipient.collection);

const Select = createSelectors(
  NotificationLog,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      recipientsDb: RecipientSelect.all().byId(),
    }),
    transform: (rawNotificationLog, { piiRealm, recipientsDb } = {}) => {
      const recipient = recipientsDb[rawNotificationLog.recipientId];
      if (
        recipient &&
        PermissionsDomain.belongsToRealm(recipient.ownership, piiRealm)
      ) {
        return rawNotificationLog;
      }
      // TODO: We need a more systematic way of defining this type of transformations.
      const newNotificationLog = mapValues(rawNotificationLog, (value, key) => {
        switch (key) {
          case 'deliveryAddress':
            return null;
          case 'events':
            return map(value, (event) => {
              return mapValues(event, (v1, k1) => {
                switch (k1) {
                  case 'payload':
                    return mapValues(v1, (v2, k2) => {
                      switch (k2) {
                        case 'deliveryAddress':
                          return null;
                        default:
                          return v2;
                      }
                    });
                  default:
                    return v1;
                }
              });
            });
          default:
            return value;
        }
      });
      return newNotificationLog;
    },
  },
);

export default Select;
