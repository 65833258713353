import { createStructuredSelector } from 'reselect';
import createSelectors from './createSelectors';
import Participation from '../models/Participation';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';
import omitPIIFields from '../utils/omitPIIFields';

const Select = createSelectors(
  Participation,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (rawParticipation, { piiRealm, variablesDb } = {}) => {
      if (
        PermissionsDomain.belongsToRealm(rawParticipation.ownership, piiRealm)
      ) {
        return rawParticipation;
      }
      const newParticipation = omitPIIFields(
        rawParticipation,
        Participation.scopeName,
        variablesDb,
      );
      return newParticipation;
    },
  },
);

export default Select;
