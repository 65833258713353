import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__TEXT,
  SORTING_ORDER__DESCENDING,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsDomains',
  initialState: {
    filters: [],
    activeDomain: null,
    editPermissionsDomainDialogVisible: false,
    createNewDomain: false,
    currentDomain: {
      name: '',
    },
  },
});

const constant = (x) => () => x;

const toBoolean = (x) => !!x;
export const getOpenPermissionsDomainDialogVisible = store.get(
  'editPermissionsDomainDialogVisible',
  toBoolean,
  false,
);

export const getCreateNewDomainFlag = store.get(
  'createNewDomain',
  toBoolean,
  false,
);

export const getDomain = createSelector(
  store.get('currentDomain'),
  (domain) => domain,
);

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  order: SORTING_ORDER__DESCENDING,
  settings: {
    id: 'createdAt',
  },
});

export const getFilters = createSelector(getSearchPhrase, (text) => {
  return [
    {
      id: 'filter_by_subject_or_ip_address',
      type: FILTER_TYPE__ANY_OF,
      condition: FILTER_CONDITION__TEXT,
      settings: {
        filters: [
          {
            type: FILTER_TYPE__PROPERTY,
            settings: {
              id: '_id',
            },
          },
          {
            type: FILTER_TYPE__PROPERTY,
            settings: {
              id: 'name',
            },
          },
        ],
      },
      state: {
        text,
      },
    },
  ];
});

export const setDomain = store.create.set('currentDomain');
export const setPermissionsDomainEditDialogVisible = store.create.set(
  'editPermissionsDomainDialogVisible',
);

export const setCreateNewDomainFlag = store.create.set('createNewDomain');

export default store;
