import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => theme(`measure.${props.max}`)(props)};

  ${(props) => `
    ${
      props.space
        ? `
      padding-right: ${theme(`space.${props.space}`)(props)};
      padding-left: ${theme(`space.${props.space}`)(props)};
    `
        : ''
    };

    ${
      props.intrinsic
        ? `
      display:flex;
      flex-direction: column;
      align-items: center;
    `
        : ''
    };

    ${
      props.isStretched
        ? `
      width: 100%;
    `
        : ''
    };
  `};
`;

const Center = ({
  className,
  children,
  max,
  space,
  intrinsic,
  isStretched,
}) => (
  <Component
    className={className}
    max={max}
    space={space}
    intrinsic={intrinsic}
    isStretched={isStretched}
  >
    {children}
  </Component>
);

Center.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  space: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  // TODO: Change max to something different as it gets passes to the DOM
  max: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  intrinsic: PropTypes.bool,
  isStretched: PropTypes.bool,
};

Center.defaultProps = {
  className: null,
  children: null,
  space: 0,
  max: 4,
  intrinsic: false,
  isStretched: false,
};

export default Center;
