import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { useTranslation } from 'react-i18next';
import { ddp } from '@theclinician/ddp-connector';
import get from 'lodash/get';
import { SHA256 } from '@theclinician/toolbelt';
import {
  isLicenseValid,
  checkUserCredentials,
} from '../../common/api/currentUser';
import {
  callMethod,
  login,
  login2FA,
} from '../../common/utilsClient/ddp/actions';
import SignInForm from './SignInForm';
import {
  ERROR_ACCOUNT_LOCKED,
  ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
} from '../../common/constants';
import settings from '../../common/settings';
import Stack from '../../common/components/primitives/Stack';
import Divider from '../../common/components/Divider';
import useDocumentTitle from '../../utils/useDocumentTitle';
import useLoginServices from '../../utils/useLoginServices';
import { useQueryParam } from '../../store/router';
import LoginServices from '../../components/LoginServices';
import tracker from '../../utils/tracker';

const defaultLoginMethods = (settings.public.loginMethods &&
  settings.public.loginMethods) || ['password', 'services'];

const SignIn = ({
  onSubmitLogin,
  onSubmit2FA,
  showQRForm,
  qrImage,
  qrCode,
  isLoggingIn,
  licenseData,
}) => {
  const { t } = useTranslation();

  const loginMethods = useQueryParam('methods', (value) => {
    if (!value) {
      return defaultLoginMethods;
    }
    return value && value.split(',');
  });

  useDocumentTitle([t('entry:signIn')]);

  const { services } = useLoginServices({
    debounceMs: 500,
  });

  const loginMethodPasswordEnabled = includes(loginMethods, 'password');
  const loginMethodServicesEnabled =
    includes(loginMethods, 'services') && !isEmpty(services);

  return (
    <Stack>
      {loginMethodPasswordEnabled && (
        <SignInForm
          qrCode={qrCode}
          qrImage={qrImage}
          form="entry"
          isLoggingIn={isLoggingIn}
          onSubmit={showQRForm ? onSubmit2FA : onSubmitLogin}
          showQRForm={showQRForm}
          license={licenseData}
        />
      )}
      {loginMethodPasswordEnabled && loginMethodServicesEnabled && (
        <Divider>OR</Divider>
      )}
      {loginMethodServicesEnabled && (
        <Stack space={1}>
          <LoginServices services={services} />
        </Stack>
      )}
    </Stack>
  );
};

SignIn.propTypes = {
  showQRForm: PropTypes.bool,
  qrImage: PropTypes.string,
  qrCode: PropTypes.string,
  isLoggingIn: PropTypes.bool.isRequired,
  onSubmitLogin: PropTypes.func.isRequired,
  onSubmit2FA: PropTypes.func.isRequired,
  licenseData: PropTypes.shape({
    hasExpired: PropTypes.bool,
    isInGracePeriod: PropTypes.bool,
    gracePeriodLeft: PropTypes.number,
  }),
};

SignIn.defaultProps = {
  licenseData: null,
  showQRForm: false,
  qrImage: null,
  qrCode: null,
};

const mapStateToProps = (state) => {
  const { type } = state.router.location.query;
  const loginType = type || 'password';
  return {
    loginType,
  };
};
const mapDispatchToProps = (
  dispatch,
  { location, history, setShowQRForm, setQRImage, setQRCode },
) => ({
  onSubmit2FA: ({ password, email, code }) =>
    dispatch(
      login2FA({
        password,
        email,
        code,
      }),
    )
      .then(() => {
        if (location.state && location.state.from) {
          history.replace(
            location.state.from.pathname +
              location.state.from.search +
              location.state.from.hash,
          );
        } else {
          history.push('/');
        }
      })
      .catch((err) => {
        if (err.error === ERROR_ACCOUNT_LOCKED) {
          throw new SubmissionError({
            _error: ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
          });
        }
        throw new SubmissionError({
          _error: err.message,
        });
      }),
  onSubmitLogin: ({ email, password }) =>
    dispatch(
      callMethod(checkUserCredentials, {
        login: email,
        hashedPassword: {
          digest: SHA256(password),
          algorithm: 'sha-256',
        },
      }),
    )
      .then((loginData) => {
        if (loginData) {
          const skip2fa = get(loginData, 'skip2fa', false);
          if (skip2fa) {
            return dispatch(
              login({
                password,
                email,
              }),
            )
              .then(() => {
                tracker.setUser(email);

                if (location.state && location.state.from) {
                  history.replace(
                    location.state.from.pathname +
                      location.state.from.search +
                      location.state.from.hash,
                  );
                } else {
                  history.push('/');
                }
              })
              .catch((err) => {
                if (err.error === ERROR_ACCOUNT_LOCKED) {
                  throw new SubmissionError({
                    _error: ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
                  });
                }
                throw new SubmissionError({
                  _error: err.message,
                });
              });
          }
          const qrImgFormServer = get(loginData, 'qrImg');
          if (qrImgFormServer) {
            setQRImage(qrImgFormServer);
          }
          const qrCodeFormServer = get(loginData, 'qrCode');
          if (qrCodeFormServer) {
            setQRCode(qrCodeFormServer);
          }
          setShowQRForm(true);
        } else {
          throw new SubmissionError({
            _error: 'Invalid credentials',
          });
        }
        return undefined;
      })
      .catch((err) => {
        if (err.error === ERROR_ACCOUNT_LOCKED) {
          throw new SubmissionError({
            _error: ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
          });
        }
        throw err;
      }),
});

const DecoratedSignIn = compose(
  withState('showQRForm', 'setShowQRForm', false),
  withState('qrImage', 'setQRImage', null),
  withState('qrCode', 'setQRCode', null),
  connect(mapStateToProps, mapDispatchToProps),
  ddp({
    queries: () => ({
      licenseData: isLicenseValid.withParams({}),
    }),
  }),
)(SignIn);

export default DecoratedSignIn;
