import map from 'lodash/map';
import { EditOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../../common/permissions';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import Text from '../../../common/components/base/Text';
import Avatar from '../../../common/components/Avatar';
import Divider from '../../../common/components/Divider';
import Button from '../../../common/components/Button';
import usePermission from '../../../utils/usePermission';
import { update as updateRecipient } from '../../../common/api/collections/Recipients';
import RecipientDetailsList from '../RecipientDetailsList';
import RecipientVariablesList from '../RecipientVariablesList';

const PatientDetails = ({ participations, recipient, onEdit, actions }) => {
  const canEdit = usePermission(updateRecipient.getPermissions(), {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const { t } = useTranslation();
  const userIntegrationId = ''; // FIXME: Get this identifier from somewhere.
  const addedVia = useMemo(
    () =>
      recipient && recipient.createdBy === userIntegrationId
        ? t('viaIntegration')
        : t('manually'),
    [recipient, t],
  );

  const handleOnEdit = useCallback(() => {
    if (onEdit && recipient) {
      onEdit(recipient._id);
    }
  }, [onEdit, recipient]);

  return (
    <Stack>
      <Sidebar
        sidebar={
          <Cluster>
            <Button
              data-testid="button-edit"
              type="link"
              icon={<EditOutlined />}
              onClick={handleOnEdit}
              disabled={!canEdit}
            >
              {t('edit')}
            </Button>
            <Button
              data-testid="button-send-registration-link"
              type="link"
              icon={<MobileOutlined />}
              disabled
            >
              {t('sendEnrollmentText')}
            </Button>
            {actions.map((action) => action)}
          </Cluster>
        }
      >
        <Cluster>
          <Avatar size="large">
            <UserOutlined />
          </Avatar>
          <Stack space={0}>
            <Text.Paragraph size="large" importance="high">
              {canSeePII ? recipient.getFullName() : recipient.getTruncatedId()}
            </Text.Paragraph>
            <Text.Paragraph size="small">
              {t('added')} {addedVia}
            </Text.Paragraph>
          </Stack>
        </Cluster>
      </Sidebar>
      <Stack>
        <RecipientDetailsList recipient={recipient} />
        <Stack>
          <Divider />
          {map(participations, (participation) => (
            <RecipientVariablesList
              key={participation._id}
              recipient={recipient}
              participation={participation}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

PatientDetails.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  actions: PropTypes.arrayOf(PropTypes.node),
  onEdit: PropTypes.func,
};

PatientDetails.defaultProps = {
  recipient: new Recipient({}),
  participations: [],
  actions: [],
  onEdit: null,
};

export default PatientDetails;
