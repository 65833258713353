import { createSelector, createStructuredSelector } from 'reselect';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import User from '../models/User';
import ProjectSelect from './Project';
import createSelectors from './createSelectors';
import { ORGANIZATION_ACCESS_USER_PII } from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';

const constant = (x) => () => x;

const Select = createSelectors(
  User,
  {
    whenMemberOfProject: (getProjectId) => (select) =>
      select.where(
        createSelector(
          ProjectSelect.one().whereIdEquals(getProjectId),
          (project) => {
            if (!project) {
              return constant(false);
            }
            return (user) => user.isMemberOf(project.getDomains());
          },
        ),
      ),
  },
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        ORGANIZATION_ACCESS_USER_PII,
      ),
      currentUserId: CurrentUserSelect.userId(),
    }),
    transform: (rawUser, { piiRealm, currentUserId } = {}) => {
      const domains = map(rawUser.groups, 'appliesTo');
      if (
        PermissionsDomain.belongsToRealm(domains, piiRealm) ||
        currentUserId === rawUser._id
      ) {
        return rawUser;
      }
      const newUser = omitBy(rawUser, (value, key) => {
        switch (key) {
          case 'name':
          case 'emails':
          case 'phones': {
            return true;
          }
          default:
            return false;
        }
      });
      return newUser;
    },
  },
);

export default Select;
