import pathToRegexp from 'path-to-regexp';
import isPlainObject from 'lodash/isPlainObject';
import parentLogger from '../logger';
import { makeSchemaValidator, makeJsonSchemaValidator } from './errors';
import { urlJoin } from '../utils/url';

const constant = (x) => () => x;
class ApiSpec {
  constructor(options) {
    Object.assign(this, options);
    if (!this.name) {
      throw new Error('Api spec requires name option');
    }
    Object.defineProperty(this, 'toPath', {
      value: this.path ? pathToRegexp.compile(this.path) : constant('/'),
    });
  }

  getName() {
    return this.name;
  }

  getPath() {
    return this.path;
  }

  getAbsoluteUrl(params, { baseUrl = this.host } = {}) {
    if (baseUrl) {
      return urlJoin(baseUrl, this.toPath(params));
    }
    return this.toPath(params);
  }

  getMethod() {
    return this.method ? this.method.toLowerCase() : 'post';
  }

  withParams(params) {
    const validator = this.getValidator();
    if (this.schema) {
      try {
        validator(params); // this may throw an error!
      } catch (err) {
        this.constructor.logger.error(err.toString(), {
          stack: err.stack,
          meta: {
            params,
            name: this.getName(),
            details: err.details,
          },
        });
        throw err;
      }
    }
    return {
      name: this.getName(),
      params: params !== undefined ? [params] : [],
    };
  }

  getPermissions() {
    return this.permissions || [];
  }

  getJsonSchema() {
    if (isPlainObject(this.schema)) {
      return this.schema;
    }
    return null;
  }

  getValidator(ValidationError) {
    if (isPlainObject(this.schema)) {
      return makeJsonSchemaValidator(this.schema, ValidationError);
    }
    return makeSchemaValidator(this.schema, ValidationError);
  }

  callMethod(params, { client, ValidationError, ...options }) {
    const validator = this.getValidator(ValidationError);
    return Promise.resolve()
      .then(() => validator(params))
      .then(() => client.apply(this.name, [params], options));
  }
}

ApiSpec.logger = parentLogger.create('apiSpecs');

export default ApiSpec;

ApiSpec.prototype.createAction = function (params, { ValidationError } = {}) {
  const validator = this.getValidator(ValidationError);
  return (dispatch, getState, { ddpConnector }) => {
    try {
      validator(params);
    } catch (err) {
      return Promise.reject(err);
    }
    return ddpConnector.apply(this.name, [params]);
  };
};
