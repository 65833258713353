import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CurrentUserSelect from '../common/selectors/CurrentUser';
import Text from '../common/components/base/Text';
import EnvironmentAlert from '../common/components/EnvironmentAlert';
import settings from '../common/settings';
import branding from './branding';

const { commitSha, commitTime, showDemoBanner, showStagingBanner } =
  settings.public;

const getEnvironmentName = () => {
  if (showDemoBanner) {
    return 'demo';
  }

  if (showStagingBanner) {
    return 'staging';
  }

  return null;
};

const getEnvironmentType = () => {
  if (showDemoBanner) {
    return 'info';
  }

  if (showStagingBanner) {
    return 'warning';
  }

  return null;
};

export default () => {
  const { t } = useTranslation('components');
  const user = useSelector(CurrentUserSelect.user());

  const details = [
    {
      label: 'Version',
      value: commitSha.substr(0, 8),
    },
    {
      label: 'Last Updated',
      value: moment.unix(commitTime).format('DD/MM/YYYY HH:mm'),
    },
    {
      label: 'Organisation',
      value: settings.public.organization,
    },
    {
      label: 'User Name',
      value: user && user.name,
    },
    {
      label: 'User ID',
      value: user && user._id,
    },
  ];
  const name = getEnvironmentName();
  const type = getEnvironmentType();

  if (!showDemoBanner && !showStagingBanner) {
    return null;
  }

  return (
    <EnvironmentAlert type={type} details={details}>
      <Text.Paragraph>
        <Text.Span size="large">
          <strong>{t('EnvironmentAlert.attention')}</strong>
        </Text.Span>{' '}
        {t('EnvironmentAlert.content.beginning')}{' '}
        <Text.Span importance="high">{t(`EnvironmentAlert.${name}`)}</Text.Span>{' '}
        {t('EnvironmentAlert.content.end', {
          context: branding,
        })}
      </Text.Paragraph>
    </EnvironmentAlert>
  );
};
