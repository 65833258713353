/* eslint-disable no-underscore-dangle */
import map from 'lodash/map';
import React, { useCallback, useContext } from 'react';
import { LoginOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DDPContext } from '@theclinician/ddp-connector';
import Button from '../common/components/Button';
import Cluster from '../common/components/primitives/Cluster';
import OAuth, { credentialRequestCompleteHandler } from '../common/oauth';
import Random from '../common/utils/random';

const LoginServices = ({ services }) => {
  const ddpConnector = useContext(DDPContext);
  const handleOnClick = useCallback(
    (data) => {
      const {
        service,
        serviceType,
        loginStyle,
        authorizeUrl: baseLoginUrl,
        clientId,
        requiredScopes = ['openid'],
      } = data;

      const flatScope = map(requiredScopes, encodeURIComponent).join('+');

      const credentialToken = Random.secret();
      let loginUrl =
        `${baseLoginUrl}?response_type=code` +
        `&client_id=${clientId}&prompt=login` +
        `&redirect_uri=${encodeURIComponent(
          `${window.location.origin}/_oauth/${service}`,
        )}` +
        `&scope=${flatScope}&state=${OAuth._stateParam(
          loginStyle,
          credentialToken,
          window.location.origin,
        )}`;

      if (serviceType === 'azure' || serviceType === 'azure:adfs') {
        loginUrl = `${loginUrl}&code_challenge=${credentialToken}`;
      }

      const credentialRequestCompleteCallback =
        credentialRequestCompleteHandler(ddpConnector, () => {});

      OAuth.launchLogin({
        loginService: serviceType,
        loginStyle,
        loginUrl,
        credentialRequestCompleteCallback,
        credentialToken,
        popupOptions: {
          height: 600,
        },
      });
    },
    [ddpConnector],
  );

  const isLoggingIn = false;
  return services.map((service) => {
    return (
      <Cluster key={service._id} justify="center">
        <Button
          data-testid={`button-sign-in=${service.service}`}
          type="primary"
          icon={<LoginOutlined />}
          htmlType="submit"
          loading={isLoggingIn}
          block
          onClick={() => handleOnClick(service)}
        >
          {service.displayName ||
            `Login with ${service.service || '[unknown]'}`}
        </Button>
      </Cluster>
    );
  });
};

LoginServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LoginServices;
