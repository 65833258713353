import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { languages } from 'countries-list';
import QuestionnaireSelect from '../../common/selectors/Questionnaire';
import QuestionnaireTranslationSelect from '../../common/selectors/QuestionnaireTranslation';
import Select from './Select';

const TranslationsSelect = ({
  questionnaireId,
  translationId,
  value,
  onChange,
  compact,
}) => {
  const { i18n } = useTranslation();

  const UILanguage = i18n.language;
  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const questionnaireLanguage = questionnaire?.language;
  const answersSheetTranslation = useSelector(
    QuestionnaireTranslationSelect.one().whereIdEquals(translationId),
  );
  const translations = answersSheetTranslation ? [answersSheetTranslation] : [];
  const filteredTranslations = questionnaireId
    ? translations.filter(
        (translation) =>
          translation.language !== questionnaireLanguage &&
          startsWith(translation._id, questionnaireId),
      )
    : [];
  const translationThatMatchesUILanguage = filteredTranslations.find(
    ({ language }) => language === UILanguage,
  );

  const handleCurrentLanguageChange = (language) => onChange(language);
  const languageOptions = filteredTranslations.map(({ language }) => {
    return {
      value: language,
      label: languages[language]?.name,
    };
  });

  if (
    !find(
      filteredTranslations,
      ({ language }) => language === questionnaireLanguage,
    )
  ) {
    languageOptions.unshift({
      value: questionnaireLanguage,
      label: languages[questionnaireLanguage]?.name,
    });
  }

  useEffect(() => {
    if (translationThatMatchesUILanguage) {
      onChange(translationThatMatchesUILanguage.language);
    } else if (questionnaireLanguage) {
      onChange(questionnaireLanguage);
    }
  }, [translationThatMatchesUILanguage, questionnaireLanguage, onChange]);

  return !isEmpty(filteredTranslations) ? (
    <Select
      options={languageOptions}
      onChange={handleCurrentLanguageChange}
      value={value}
      compact={compact}
    />
  ) : null;
};

TranslationsSelect.propTypes = {
  questionnaireId: PropTypes.string,
  translationId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
};

TranslationsSelect.defaultProps = {
  questionnaireId: null,
  translationId: null,
  value: null,
  onChange: () => {},
  compact: false,
};

export default TranslationsSelect;
