/* eslint-disable react/prop-types, react/no-children-prop */
import React from 'react';
import { Route } from 'react-router-dom';

const HashRoute = ({ hash, render, component: Component, ...rest }) => (
  <Route
    {...rest}
    children={(props) => {
      if (!(props.location && props.location.hash)) {
        return null;
      }
      const groups = hash.exec(props.location.hash);
      if (groups) {
        if (Component) {
          return (
            <Component
              {...{
                ...props,
                groups,
              }}
            />
          );
        }
        if (render) {
          return render({
            ...props,
            groups,
          });
        }
      }
      return null;
    }}
  />
);

export default HashRoute;
