import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const NativeDatePicker = ({
  value,
  onChange,
  disabled,
  autoFocus,
  'data-testid': testId,
  min,
  max,
}) => {
  const inputRef = useRef();

  const handleOnChange = useCallback(
    (event) => onChange(event.target.value),
    [onChange],
  );

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <Input
      ref={inputRef}
      data-testid={testId}
      type="date"
      value={value}
      disabled={disabled}
      onChange={handleOnChange}
      min={min}
      max={max}
    />
  );
};

NativeDatePicker.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
};

NativeDatePicker.defaultProps = {
  'data-testid': 'date-picker',
  disabled: false,
  autoFocus: false,
  min: null,
  max: null,
};

export default NativeDatePicker;
