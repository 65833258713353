import { SyncOutlined, LogoutOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import User from '../../../common/models/User';
import Card from '../../../common/components/Card';
import Button from '../../../common/components/Button';
import Switcher from '../../../common/components/primitives/Switcher';

const AlreadySignedIn = ({ currentUser, onReset, onLogout }) => (
  <Card
    footer={
      <Switcher>
        <Button icon={<SyncOutlined />} htmlType="submit" onClick={onReset}>
          Reset Password
        </Button>
        <Button icon={<LogoutOutlined />} htmlType="submit" onClick={onLogout}>
          Sign Out
        </Button>
      </Switcher>
    }
  >
    {currentUser && (
      <Link to="/">
        Continue as <b>{currentUser.getFullName()}</b>
      </Link>
    )}
  </Card>
);

AlreadySignedIn.propTypes = {
  currentUser: PropTypes.instanceOf(User),
  onReset: PropTypes.func,
  onLogout: PropTypes.func,
};

AlreadySignedIn.defaultProps = {
  currentUser: null,
  onReset: null,
  onLogout: null,
};

export default AlreadySignedIn;
