import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import Text from '../../../common/components/base/Text';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import { CollapseItem } from '../../../common/components/Collapse';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import useProjectVariables from '../../../utils/useProjectVariables';
import ProjectSelect from '../../../common/selectors/Project';
import { apiZedocOneProject } from '../../../common/api/zedoc';

const RecipientVariablesList = ({ recipient, participation }) => {
  const { t, i18n } = useTranslation('states');

  const projectId = participation && participation.projectId;
  const variables = useProjectVariables(projectId);

  const { ready } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const details = [];

  variables.forEach((variable) => {
    if (!variable.isPatient() && !variable.isParticipation()) {
      return;
    }

    const value = variable.getFromContext({
      recipient,
      participation,
    });

    if (value !== '' && !isNil(value)) {
      details.push({
        variableId: variable._id,
        title: variable.name,
        description: variable.getDisplayValue(value, {
          projectId,
          language: i18n.language,
        }),
      });
    }
  });

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  if (!ready) {
    return null;
  }

  const content = (
    <Cluster>
      {details.map(({ variableId, title, description }, i) => (
        <React.Fragment key={variableId}>
          {i > 0 && <div>&#xb7;</div>}
          <Stack space={0}>
            <Text.Paragraph importance="high">{title}</Text.Paragraph>
            <Text.Paragraph>{description}</Text.Paragraph>
          </Stack>
        </React.Fragment>
      ))}
    </Cluster>
  );

  return (
    <CollapseItem
      title={`${project && project.getName()} - ${
        participation &&
        participation.state &&
        t(participation.state.toLowerCase())
      }`}
      content={content}
    />
  );
};

RecipientVariablesList.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
  participation: PropTypes.instanceOf(Participation),
};

RecipientVariablesList.defaultProps = {
  recipient: null,
  participation: null,
};

export default RecipientVariablesList;
