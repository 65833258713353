import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { apiCollectionsRolesAll } from '../../common/api/collections/Roles';
import RolesSelect from '../../common/selectors/Role';
import {
  ADMIN_SEARCH_GROUP,
  ADMIN_CREATE_GROUP,
} from '../../common/permissions';
import { all as allPermissionsDomains } from '../../common/api/collections/PermissionsDomains';
import PermissionsDomainSelect from '../../common/selectors/PermissionsDomain';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import Stack from '../../common/components/primitives/Stack';
import EditUsersGroupDialog from '../../components/dialogs/EditUserGroup';
import UsersGroupsFilters from './components/UsersGroupsFilters';
import UsersGroupsTable from './components/UsersGroupsTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  apiAdminCreateUsersGroup,
  apiAdminUpdateUsersGroup,
} from '../../common/api/admin';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import {
  getUsersGroupDialogVisible,
  setUsersGroupDialogVisible,
  getCreateUsersGroupFlag,
  setCreateUsersGroupFlag,
  getGroup,
} from './store';
import { notifyError, notifySuccess } from '../../utils/notify';

const constant = (x) => () => x;

const selectDomains = PermissionsDomainSelect.all()
  .whereUserHasPermission(CurrentUserSelect.user(), ADMIN_SEARCH_GROUP)
  .map(
    constant((domain) => ({
      value: domain._id,
      label: domain.name,
    })),
  );

const SettingsGroups = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('group', {
      count: 0,
    }),
  ]);
  useDDPSubscription(allPermissionsDomains.withParams());
  useDDPSubscription(apiCollectionsRolesAll.withParams());
  const roleSelector = createSelector(RolesSelect.all(), (roles) => roles);
  const roles = useSelector(roleSelector);
  const roleOptions = roles.map((role) => ({
    value: role._id,
    label: role.getName(),
  }));

  const groups = useSelector(
    PermissionsDomainSelect.all().whereUserHasPermission(
      CurrentUserSelect.user(),
      ADMIN_CREATE_GROUP,
    ),
  );
  const groupOptions = useMemo(
    () =>
      map(groups, (grp) => ({
        label: grp.name,
        value: grp._id,
      })),
    [groups],
  );

  const dispatch = useDispatch();
  const resetForm = useCallback(() => {
    dispatch(setUsersGroupDialogVisible(false));
    dispatch(setCreateUsersGroupFlag(false));
    // dispatch(
    //   setDomain({
    //     id: null,
    //     name: null,
    //   }),
    // );
  }, [dispatch]);

  const onCancel = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const isCreate = useSelector(getCreateUsersGroupFlag);
  const currentGroup = useSelector(getGroup);

  const onCreate = useCallback(() => {
    const newUsersGroup = {
      ...currentGroup,
      roles: map(
        roles.filter((role) => currentGroup.roles.indexOf(role._id) > -1),
        (role) => ({
          id: role._id,
          tier: role.tier,
          name: role.name,
        }),
      ),
    };
    if (isCreate) {
      dispatch(callMethod(apiAdminCreateUsersGroup, newUsersGroup))
        .then(() => {
          resetForm();
          notifySuccess(t('confirmations:addGroup.success'))();
        })
        .catch(notifyError());
    } else {
      dispatch(
        callMethod(apiAdminUpdateUsersGroup, {
          ...newUsersGroup,
          groupId: currentGroup._id,
          _id: undefined,
        }),
      )
        .then(() => {
          resetForm();
          notifySuccess(t('confirmations:editGroup.success'))();
        })
        .catch(notifyError());
    }
  }, [resetForm, isCreate, dispatch, roles, t, currentGroup]);

  const domains = useSelector(selectDomains);
  const open = useSelector(getUsersGroupDialogVisible);
  return (
    <>
      <EditUsersGroupDialog
        open={open}
        creating={isCreate}
        onCancel={onCancel}
        onCreate={onCreate}
        groupOptions={groupOptions}
        roleOptions={roleOptions}
      />
      <Stack>
        <UsersGroupsFilters domains={domains} />
        <UsersGroupsTable domains={domains} />
      </Stack>
    </>
  );
};

export default SettingsGroups;
