import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectProfileDialog from '../../../components/dialogs/ProjectProfile';
import { getProjectProfileDialog } from '../selectors';
import { closeProjectProfileDialog } from '../actions';

const ConnectedProjectProfileDialog = ({ projectId: defaultProjectId }) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeProjectProfileDialog());
  }, [dispatch]);
  const options = useSelector(getProjectProfileDialog);
  if (!options) {
    return (
      <ProjectProfileDialog visible={false} projectId={defaultProjectId} />
    );
  }
  const { projectId, recipientId, participationId } = options;
  return (
    <ProjectProfileDialog
      visible
      allowOtherProjects={false}
      projectId={projectId || defaultProjectId}
      recipientId={recipientId}
      participationId={participationId}
      onCancel={handleOnCancel}
    />
  );
};

ConnectedProjectProfileDialog.propTypes = {
  projectId: PropTypes.string,
};

ConnectedProjectProfileDialog.defaultProps = {
  projectId: null,
};

export default ConnectedProjectProfileDialog;
