import React, { useMemo, useCallback } from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/lists/Table';
import { apiAdminSearchAuditLogs } from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import AuditLogSelect from '../../../common/selectors/AuditLog';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import AuditLogDetails from './AuditLogDetails';
import { useModal } from '../../../utils/modal';
import { getFilters, getSorter } from '../store';

const AuditLogsTable = React.memo(() => {
  const modal = useModal();
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const { t } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: logs,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: AuditLogSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiAdminSearchAuditLogs.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const highlight = useHighlight(filters, [
    'name',
    'subject.label',
    'client.ip',
  ]);

  const handleSelectLog = useCallback(
    (log) => {
      modal.render(({ visible }) => (
        <AuditLogDetails
          rawLogEntry={log.raw}
          visible={visible}
          onCancel={() => modal.cancel(500)}
        />
      ));
    },
    [modal],
  );

  const columns = useMemo(
    () => [
      {
        title: t('forms:date.label'),
        dataIndex: 'date',
        render: (_, log) => log.formatCreatedAt(),
      },
      {
        title: t('clientIp'),
        dataIndex: 'clientIp',
        render: (_, log) => (
          <Highlighter text={log.getClientIp()} highlight={highlight} />
        ),
      },
      {
        title: t('browser'),
        dataIndex: 'browser',
        render: (text, log) => log.getClientBrowser(),
      },
      {
        title: t('event'),
        dataIndex: 'event',
        render: (text, log) => log.formatType(),
      },
      {
        title: t('eventResult'),
        dataIndex: 'eventResult',
        render: (text, log) => log.getEventResult(),
      },
      {
        title: t('subject'),
        dataIndex: 'subject',
        render: (_, log) => (
          <Highlighter text={log.getSubjectLabel()} highlight={highlight} />
        ),
      },
    ],
    [t, highlight],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={logs}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={(log) => ({
        onClick: () => handleSelectLog(log),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default AuditLogsTable;
