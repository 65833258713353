import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { useStore, useDispatch } from 'react-redux';
import Questionnaire, {
  syncFormValues,
} from '../../../common/containers/Questionnaire';
import createQuestionnaire from './createQuestionnaire';
import FormSection from './FormSection';
import FormContext from './FormContext';
import collapseValues from './collapseValues';

const Form = React.forwardRef(
  (
    {
      onChange,
      name,
      schema,
      fields,
      initialValues: newInitialValues,
      properties: newProperties,
      'data-testid': testId,
    },
    forwardedRef,
  ) => {
    const questionnaire = useMemo(() => {
      return createQuestionnaire(schema);
    }, [schema]);

    const store = useStore();
    const ref = useRef(null);

    useEffect(() => {
      if (!onChange) {
        return () => {};
      }
      return store.subscribe(() => {
        const state = store.getState();
        const formValues = state?.questionnaire?.[name]?.values;
        if (!ref.current || ref.current !== formValues) {
          onChange(questionnaire.getFinalVariables(formValues));
          ref.current = formValues;
        }
      });
    }, [name, store, onChange, questionnaire]);

    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
      if (!initialValues && newInitialValues) {
        const formValues = questionnaire.getInitialValues(newInitialValues);
        setInitialValues(newInitialValues);
        dispatch(syncFormValues(name, formValues));
      }
    }, [dispatch, newInitialValues, name, questionnaire, initialValues]);

    const questionnaireRef = useRef();

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          setErrors: (errors) => {
            const formErrors = questionnaire.getInitialValues(errors);
            questionnaireRef.current.setErrors(formErrors);
          },
          submit: () =>
            questionnaireRef.current.submit().then(({ formValues }) => {
              return questionnaire.getFinalVariables(formValues);
            }),
        };
      },
      [questionnaire, questionnaireRef],
    );

    const properties = useMemo(() => {
      return collapseValues(questionnaire.getInitialValues(newProperties));
    }, [newProperties, questionnaire]);

    const context = useMemo(() => {
      const getField = (questionId) => {
        if (!questionId) {
          return fields[''] || {};
        }
        const path = questionId.replace(/\//g, '.');
        return fields[path] || {};
      };
      return {
        schema,
        fields,
        getField,
      };
    }, [schema, fields]);

    return (
      <Questionnaire
        ref={questionnaireRef}
        name={name}
        questionnaire={questionnaire}
        properties={properties}
      >
        <FormContext.Provider value={context}>
          <FormSection data-testid={testId} />
        </FormContext.Provider>
      </Questionnaire>
    );
  },
);

Form.propTypes = {
  name: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
  schema: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.objectOf(
    PropTypes.shape({
      testLabel: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.string),
      ]),
      render: PropTypes.func,
      disabled: PropTypes.bool,
      dependencies: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  initialValues: PropTypes.objectOf(PropTypes.any),
  properties: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

Form.defaultProps = {
  'data-testid': null,
  schema: {},
  fields: {},
  initialValues: {},
  properties: {},
  onChange: null,
};

export default Form;
