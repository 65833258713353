import React from 'react';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import { ADMIN_SEARCH_USER } from '../../common/permissions';
import { all as allPermissionsDomains } from '../../common/api/collections/PermissionsDomains';
import PermissionsDomainSelect from '../../common/selectors/PermissionsDomain';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import Stack from '../../common/components/primitives/Stack';
import EditUser from './components/EditUser';
import UsersTable from './components/UsersTable';
import UsersPageBar from './components/UsersPageBar';
import useDocumentTitle from '../../utils/useDocumentTitle';

const constant = (x) => () => x;

const selectDomains = PermissionsDomainSelect.all()
  .whereUserHasPermission(CurrentUserSelect.user(), ADMIN_SEARCH_USER)
  .map(
    constant((domain) => ({
      value: domain._id,
      label: domain.name,
    })),
  );

const SettingsUsers = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('user', {
      count: 0,
    }),
  ]);
  useDDPSubscription(allPermissionsDomains.withParams());
  const domains = useSelector(selectDomains);
  return (
    <>
      <EditUser />
      <Stack>
        <UsersPageBar domains={domains} />
        <UsersTable domains={domains} />
      </Stack>
    </>
  );
};

export default SettingsUsers;
