import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle } from 'recompose';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import { getActiveProjectId } from './selectors';
import { openProjectProfileDialog } from './actions';
import PatientsTable from './components/PatientsTable';
import {
  setCurrentMode,
  setCurrentProjectId,
} from '../../store/ui/search/actions';
import Stack from '../../common/components/primitives/Stack';
import PageBar from '../../components/Layout/PageBar';
import useDocumentTitle from '../../utils/useDocumentTitle';
import branding from '../../utils/branding';
import ConnectedProjectProfileDialog from './components/ConnectedProjectProfileDialog';
import ConnectedEditPatientDialog from './components/ConnectedEditPatientDialog';

const Directory = compose(
  ddp({
    selectors: () =>
      createStructuredSelector({
        activeProjectId: getActiveProjectId,
      }),
  }),
  lifecycle({
    componentDidMount() {
      const { dispatch, activeProjectId } = this.props;
      if (activeProjectId) {
        dispatch(setCurrentProjectId(activeProjectId));
      } else {
        dispatch(setCurrentProjectId(null));
      }
      dispatch(setCurrentMode('directory'));
    },
    componentDidUpdate(prevProps) {
      const { dispatch, activeProjectId } = this.props;
      if (prevProps.activeProjectId !== activeProjectId) {
        dispatch(setCurrentProjectId(activeProjectId));
      }
    },
  }),
)(({ activeProjectId }) => {
  const { t } = useTranslation();

  useDocumentTitle([
    t('recipient', {
      count: 0,
      context: branding,
    }),
  ]);

  const dispatch = useDispatch();

  const handleOnSubmitted = useCallback(
    ({ recipientId }) => {
      dispatch(push(`/patients/${recipientId}`));
    },
    [dispatch],
  );

  const handleOnPatientCreated = useCallback(
    (recipientId, options) => {
      if (options && options.addToProject) {
        dispatch(
          openProjectProfileDialog({
            recipientId,
          }),
        );
      } else {
        dispatch(push(`/patients/${recipientId}`));
      }
    },
    [dispatch],
  );

  return (
    <>
      <ConnectedProjectProfileDialog
        projectId={activeProjectId}
        onSubmitted={handleOnSubmitted}
      />
      <ConnectedEditPatientDialog onSubmitted={handleOnPatientCreated} />
      <Stack>
        <PageBar
          title={t('recipient', {
            count: 0,
            context: branding,
          })}
        />
        <PatientsTable />
      </Stack>
    </>
  );
});

export default Directory;
