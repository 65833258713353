/* eslint-disable react/prop-types */

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  background-color: ${theme('color.underlay')};
  color: ${theme('color.onUnderlay')};
  height: ${(props) => theme(`size.${props.size}`)};
  width: ${(props) => theme(`size.${props.size}`)};
  min-width: ${(props) => theme(`size.${props.size}`)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* TODO: Remove once we go back to default 1.5 theme line height */
  line-height: 1.5rem;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const BoldComponent = styled(Component)`
  background-color: ${theme('color.primary')};
  color: ${theme('color.onPrimary')};
  font-weight: ${() => theme('font.weight.bold')};
`;

const SuccessComponent = styled(BoldComponent)`
  background-color: ${theme('color.success')};
  color: ${theme('color.onSuccess')};
`;

/**
 * Avatars can be used to represent people or objects. It supports text and icons.
 */
const Avatar = ({ children, size, style, kind, color }) => {
  switch (kind) {
    case 'bold':
      return (
        <BoldComponent size={size} style={style} color={color}>
          <span>{children}</span>
        </BoldComponent>
      );
    case 'success':
      return (
        <SuccessComponent size={size} style={style} color={color}>
          <span>{children}</span>
        </SuccessComponent>
      );
    default:
      return (
        <Component size={size} style={style}>
          <span>{children}</span>
        </Component>
      );
  }
};

Avatar.propTypes = {
  /**
   * Can be either a text node or an icon.
   */
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'base', 'large']),
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  kind: PropTypes.oneOf(['subtle', 'bold', 'success']),
};

Avatar.defaultProps = {
  size: 'base',
  style: {},
  kind: 'subtle',
};

export default Avatar;
