import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withProps } from 'recompose';
import sample from 'lodash/sample';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import map from 'lodash/map';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import User from '../../../../../common/models/User';
import PatientRecord from '../../../../../common/models/PatientRecord';
import Tag from '../../../../../common/components/TagAntd';
import Stack from '../../../../../common/components/primitives/Stack';
// import Tabs from '../../../../../components/layouts/Tabs';
import ActionItemsList from '../ActionItemsList';
import Snooze from '../../../../../components/dialogs/Snooze';

const { CheckableTag } = Tag;

const propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
  patientRecords: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  filterBy: PropTypes.string,
};

const defaultProps = {
  pending: [],
  filterBy: 'questionnaire',
};

const property = (name) => (state, props) => props[name];

const Layout = compose(
  connect(() => {
    const greetings = sample([
      (name) => `Good day, ${name}! You have no items for today.`,
      (name) =>
        `It's good to see you again, ${name}. You have no items for today.`,
    ]);
    return createStructuredSelector({
      greetings: createSelector(property('currentUser'), (currentUser) =>
        greetings(currentUser.getFullName()),
      ),
      notifications: createSelector(
        property('patientRecords'),
        property('currentUser'),
        (patientRecords, currentUser) =>
          patientRecords.map((record) => {
            const questionnaires = record.getPendingQuestionnaires({
              assigneeId: currentUser && currentUser._id,
              ignoreSnoozed: new Date(), // TODO: Replace with clock selector
            });
            return {
              questionnaires,
              projectId: record.getProjectId(),
              recipientId: record.getRecipientId(),
              recipientName: record.getFullName(),
              milestoneIds: compose(
                uniq,
                compact,
                map,
              )(questionnaires, 'milestoneId'),
            };
          }),
      ),
    });
  }),
  withProps(() => ({
    options: [
      {
        label: 'By Questionnaire',
        value: 'questionnaire',
      },
      {
        label: 'By Milestone',
        value: 'milestone',
      },
    ],
  })),
)(({ greetings, notifications, options, filterBy, onChange }) => (
  <Stack>
    {options.map(({ value, label }) => (
      <CheckableTag
        data-testid={`component-${label}`}
        key={value}
        checked={value === filterBy}
        onChange={() => {
          onChange(value);
        }}
      >
        {label}
      </CheckableTag>
    ))}
    {notifications.length > 0 ? (
      <ActionItemsList notifications={notifications} filterBy={filterBy} />
    ) : (
      greetings
    )}
    <Snooze />
  </Stack>
));

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
