import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import UsersSelect from '../../common/selectors/User';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsUsers',
  initialState: {
    filters: [],
    activeUserId: null,
    activeDomain: null,
    editUserDialogVisible: false,
  },
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: 'name',
  },
});

export const getActiveDomain = createSelector(
  store.get('activeDomain'),
  (activeDomain) => activeDomain || '', // NOTE: '' is considered valid domain, other value like null, is not
);

const toBoolean = (x) => !!x;

export const getEditUserDialogVisible = store.get(
  'editUserDialogVisible',
  toBoolean,
  false,
);
export const getActiveUserId = store.get('activeUserId');

export const getActiveUser = UsersSelect.one().whereIdEquals(getActiveUserId);

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(
  getSearchPhrase,
  getActiveDomain,
  (text, domain) => {
    return [
      {
        id: 'filter_by_user_name_or_email_or_phone',
        type: FILTER_TYPE__ANY_OF,
        condition: FILTER_CONDITION__TEXT,
        settings: {
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'name',
              },
            },
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'emails.address',
              },
            },
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'phones.number',
              },
            },
          ],
        },
        state: {
          text,
        },
      },
      domain && {
        id: 'filter_by_domain',
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: 'groups.appliesTo',
        },
        state: {
          include: [domain],
        },
      },
    ];
  },
);

export const setEditUserDialogVisible = store.create.set(
  'editUserDialogVisible',
);
export const setActiveUserId = store.create.set('activeUserId');
export const setActiveDomain = store.create.set('activeDomain');

export const openEditUserDialog =
  ({ userId } = {}) =>
  (dispatch) => {
    if (userId !== undefined) {
      dispatch(setActiveUserId(userId));
    }
    dispatch(setEditUserDialogVisible(true));
  };

export const closeEditUserDialog = () => (dispatch) => {
  dispatch(setEditUserDialogVisible(false));
};

export default store;
