/* eslint-disable react/prop-types */
import theming from 'styled-theming';
import styled, { css } from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import palette from '../../utilsClient/palette';
import Milestone from './Milestone';

const backgroundColor = theming('mode', {
  light: css`
    background-color: rgb(222, 234, 248);
  `,
  dark: css`
    background-color: ${palette.blue[30]};
    color: ${palette.black};
  `,
});

const StyledMilestone = styled(Milestone)`
  ${backgroundColor};
`;

const MilestoneUpcoming = ({ ...props }) => {
  const { t } = useTranslation('components');

  return (
    <StyledMilestone
      status={t('Milestone.upcoming')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default MilestoneUpcoming;
