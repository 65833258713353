/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../../common/components/Dropdown';
import Menu from '../../../../../common/components/Menu';
import Button from '../../../../../common/components/Button';
import { PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET } from '../../../../../common/permissions';
import usePermission from '../../../../../utils/usePermission';

const propTypes = {
  canRemove: PropTypes.bool,
  // onReassign: PropTypes.func,
  onReschedule: PropTypes.func,
  onRequestRemove: PropTypes.func,
  // canReassign: PropTypes.bool,
  dashboardsPerspectiveResponses: PropTypes.objectOf(PropTypes.array),
  disabled: PropTypes.bool,
};

const noop = () => {};
const defaultProps = {
  canRemove: false,
  // onReassign: noop,
  onReschedule: noop,
  onRequestRemove: noop,
  // canReassign: false,
  dashboardsPerspectiveResponses: {},
  disabled: false,
};

const FormDialog = ({
  answersSheet,
  completed,
  onNotesOpen,
  // canReassign,
  // onReassign,
  onRequestRemove,
  onDashboardsPerspectiveResponsesPreview,
  canRemove,
  dashboardsPerspectiveResponses,
  disabled,
}) => {
  const { t } = useTranslation();

  const canShowNotes = usePermission(
    [PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET],
    {
      relativeTo: answersSheet && answersSheet.getDomains(),
    },
  );

  const overlay = (
    <Menu>
      {map(dashboardsPerspectiveResponses, (dashboards) => {
        const sorted = sortBy(dashboards, 'index');

        return map(sorted, (dashboard, index) => {
          return (
            <Menu.Item
              data-testid="menu-questionnaires-more-preview"
              key="preview"
              onClick={() =>
                onDashboardsPerspectiveResponsesPreview({
                  type: dashboard.type,
                  presetNo: index + 1,
                })
              }
            >
              Preview ({dashboard.title})
            </Menu.Item>
          );
        });
      })}
      <Menu.Item
        data-testid="menu-questionnaires-more-notes"
        key="notes"
        onClick={onNotesOpen}
        disabled={!canShowNotes}
      >
        {t('showNote', {
          count: 0,
        })}
      </Menu.Item>
      {!completed && <Menu.Divider />}
      {completed ? null : (
        <Menu.Item
          data-testid="menu-questionnaires-more-remove"
          key="remove"
          onClick={onRequestRemove}
          disabled={!canRemove}
        >
          {t('remove')}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={overlay} trigger={['click']} disabled={disabled}>
      <Button data-testid="menu-questionnaires-more" block>
        {t('more')}
      </Button>
    </Dropdown>
  );
};

FormDialog.propTypes = propTypes;
FormDialog.defaultProps = defaultProps;

export default FormDialog;
