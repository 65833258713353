import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AnswersSheetSelect from '../../../../common/selectors/AnswersSheet';
import { apiDocumentsSearchAnswersSheets } from '../../../../common/api/documents';
import PatientDocuments from '../../../../components/patients/PatientDocuments';
import PatientDocument from '../../../../components/patients/PatientDocument';
import usePagination from '../../../../utils/usePagination';
import {
  FILTER_TYPE__PROPERTY,
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
} from '../../../../common/constants';
import Cluster from '../../../../common/components/primitives/Cluster';
import Stack from '../../../../common/components/primitives/Stack';
import { openTab } from '../../actions';
import { selectViewPresetNo } from '../../selectors';

const DashboardDocuments = ({ answersSheetId, dashboardId, recipientId }) => {
  const dispatch = useDispatch();
  const {
    // ready: subscriptionsReady,
    items: answersSheets,
    renderPagination,
  } = usePagination({
    debounceMs: 500,
    selector: AnswersSheetSelect,
    getSubscription: (currentPage, resultsPerPage) => {
      if (answersSheetId || !dashboardId) {
        return null;
      }
      const filters = [];
      if (recipientId) {
        filters.push({
          type: FILTER_TYPE__PROPERTY,
          settings: {
            id: 'recipientId',
          },
          state: {
            include: [recipientId],
          },
        });
      }
      return apiDocumentsSearchAnswersSheets.withParams({
        controlId: '$meta.id',
        dashboardId,
        filters,
        pageIndex: currentPage - 1,
        resultsPerPage,
      });
    },
  });
  const presetNo = useSelector(selectViewPresetNo);

  if (answersSheetId && dashboardId) {
    return (
      <PatientDocument
        answersSheetId={answersSheetId}
        dashboardId={dashboardId}
        presetNo={presetNo}
        openTab={openTab}
      />
    );
  }

  const onPreview = (answersSheet) => {
    dispatch(
      openTab({
        recipientId,
        presetNo,
        type: PROJECT_DASHBOARD_TYPE__DOCUMENTS,
        perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
        projectId: answersSheet.getProjectId(),
        settings: {
          responseId: answersSheet._id,
        },
      }),
    );
  };

  return (
    <Stack>
      <PatientDocuments answersSheets={answersSheets} onPreview={onPreview} />
      <Cluster justify="flex-end">{renderPagination()}</Cluster>
    </Stack>
  );
};

DashboardDocuments.propTypes = {
  // recipientId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string,
  answersSheetId: PropTypes.string,
  recipientId: PropTypes.string,
};

DashboardDocuments.defaultProps = {
  dashboardId: null,
  answersSheetId: null,
  recipientId: null,
};

export default DashboardDocuments;
