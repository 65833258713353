import { EllipsisOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose, withHandlers, withProps } from 'recompose';
import { theme } from '../../../../../common/utilsClient/cssHelpers';
import Stack from '../../../../../common/components/primitives/Stack';
import Sidebar from '../../../../../common/components/primitives/Sidebar';
import Dropdown from '../../../../../common/components/Dropdown';
import Button from '../../../../../common/components/Button';
import Menu from '../../../../../components/lists/Menu';
import { openSnoozeDialog, openSnoozeOneDialog } from '../actions';

const propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  recipientName: PropTypes.string,
  milestoneIds: PropTypes.arrayOf(PropTypes.string),
  milestoneNames: PropTypes.objectOf(PropTypes.string),
  answersSheetId: PropTypes.string,
  questionnaireId: PropTypes.string,
};

const defaultProps = {
  milestoneIds: [],
  milestoneNames: {},
};

const StyledExtra = styled.div`
  margin-top: ${theme('rhythm.xs')};
  margin-left: ${theme('rhythm.small')};
`;

const ActionItemMilestone = withHandlers({
  handleClick:
    ({ milestoneId, onClick }) =>
    () =>
      onClick(milestoneId),
})(({ handleClick, milestoneName }) => (
  <Button onClick={handleClick}>
    Open <strong>{milestoneName || 'Milestone'}</strong>
  </Button>
));

const ActionItem = compose(
  withHandlers({
    handleSnoozeSelect:
      ({ projectId, recipientId, answersSheetId, dispatch }) =>
      (snoozeEnd) => {
        if (answersSheetId) {
          dispatch(
            openSnoozeOneDialog({
              answersSheetId,
              snoozeEnd,
            }),
          );
        } else {
          dispatch(
            openSnoozeDialog({
              recipientId,
              snoozeEnd,
              projectId,
            }),
          );
        }
      },
    handleRecipientClick:
      ({ onRecipientClick, recipientId, recipientName }) =>
      () =>
        onRecipientClick(recipientName, recipientId),
    handleMilestoneClick:
      ({ onMilestoneClick, recipientId }) =>
      (milestoneId) =>
        onMilestoneClick(recipientId, milestoneId),
    handleQuestionnaireClick:
      ({ onQuestionnaireClick, answersSheetId }) =>
      () =>
        answersSheetId && onQuestionnaireClick(answersSheetId),
  }),
  withProps(({ handleSnoozeSelect, dateEnd }) => ({
    items: [
      {
        title: 'Tomorrow',
        onClick: () =>
          handleSnoozeSelect(
            moment()
              .add(1, 'days')
              .hours(8)
              .startOf('hour')
              .format('YYYY-MM-DD'),
          ),
      },
      {
        title: 'Later This Week',
        onClick: () =>
          handleSnoozeSelect(
            moment()
              .add(2, 'days')
              .hours(8)
              .startOf('hour')
              .format('YYYY-MM-DD'),
          ),
      },
      {
        title: 'Next Week',
        onClick: () =>
          handleSnoozeSelect(
            moment()
              .add(1, 'weeks')
              .isoWeekday(1)
              .hours(8)
              .startOf('hour')
              .format('YYYY-MM-DD'),
          ),
      },
    ],
    dueDate: dateEnd ? moment(dateEnd).format('MMMM Do YYYY') : '',
  })),
)(
  ({
    recipientName,
    milestoneIds,
    milestoneNames,
    answersSheetId,
    questionnaireId,
    handleSnoozeSelect,
    handleRecipientClick,
    handleMilestoneClick,
    handleQuestionnaireClick,

    dueDate,

    items,
  }) => (
    <Sidebar
      sidebar={
        <>
          {dueDate && <Button onClick={handleSnoozeSelect}>Snooze</Button>}
          <Dropdown overlay={<Menu items={items} />} trigger={['click']}>
            <EllipsisOutlined />
          </Dropdown>
          {dueDate && (
            <StyledExtra>
              Due <strong>{dueDate}</strong>
            </StyledExtra>
          )}
        </>
      }
    >
      <Stack>
        <Button onClick={handleRecipientClick}>
          Open <strong>{recipientName}</strong> profile
        </Button>
        <React.Fragment>
          {answersSheetId && (
            <Button onClick={handleQuestionnaireClick}>
              Take <strong>{questionnaireId}</strong>
            </Button>
          )}
          {milestoneIds.map((milestoneId, index) => (
            <React.Fragment>
              {index !== 0 && <span>, </span>}
              <ActionItemMilestone
                key={milestoneId}
                milestoneId={milestoneId}
                milestoneName={milestoneNames[milestoneId]}
                onClick={handleMilestoneClick}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      </Stack>
    </Sidebar>
  ),
);

ActionItem.propTypes = propTypes;
ActionItem.defaultProps = defaultProps;

export default ActionItem;
