import maxBy from 'lodash/maxBy';
import filter from 'lodash/filter';
import minBy from 'lodash/minBy';
import find from 'lodash/find';
import map from 'lodash/map';
import BaseModel from './BaseModel';
import DOB from './DOB';
import { getCurrentYearMonthDay } from '../utils/date';
import {
  VARIABLE_ID__AGE,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__BIRTH_YEAR,
  VARIABLE_ID__STATUS_IN_PROJECT,
  VARIABLE_ID__NATIONAL_ID,
  VARIABLE_ID__STUDY_NO,
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
  VARIABLE_ID__NAME,
  VARIABLE_ID__IDENTIFIER,
  VARIABLE_ID__CLINICIAN,
  VARIABLE_ID__CASE_MANAGER,
  VARIABLE_ID__PHONE,
  VARIABLE_ID__EMAIL,
  GENDER_FEMALE,
  GENDER_MALE,
  PARTICIPANT_STATE__SUSPENDED,
  PARTICIPANT_STATE__COMPLETED,
  PARTICIPANT_STATE__CANCELED,
  PARTICIPATION_STATE__ACTIVE,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__COMPLETED,
} from '../constants';

class PatientRecord extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }

  getTruncatedRecipientId() {
    if (!this.recipientId) {
      // NOTE: This can happen client side if new PatientRecord({})
      //       is used to create a sentinel object.
      return '';
    }
    const parts = this.recipientId.split('-');
    return parts.length > 2 && parts[2];
  }

  getFullName() {
    return this.recipientName || this.getVariable(VARIABLE_ID__NAME);
  }

  getIdentifier() {
    return this.getVariable(VARIABLE_ID__IDENTIFIER);
  }

  getPrimaryIdentifier() {
    return this.primaryIdentifier;
  }

  getProjectId() {
    return this.projectId;
  }

  getRecipientId() {
    return this.recipientId;
  }

  isAssignedTo(userId) {
    if (!this.questionnaires) {
      return false;
    }
    return this.questionnaires.some((q) => q.assigneeId === userId);
  }

  getAge() {
    // NOTE: Either date or year may be present depending on project configuration.
    const birthDate =
      this.getVariable(VARIABLE_ID__BIRTH_DATE) ||
      this.getVariable(VARIABLE_ID__BIRTH_YEAR);
    if (birthDate) {
      return DOB.fromString(birthDate).getCurrentAge();
    }
    return this.getVariable(VARIABLE_ID__AGE);
  }

  getStatus() {
    return this.getVariable(VARIABLE_ID__STATUS_IN_PROJECT);
  }

  getNationalId() {
    return this.getVariable(VARIABLE_ID__NATIONAL_ID);
  }

  getStudyNo() {
    return this.getVariable(VARIABLE_ID__STUDY_NO);
  }

  getGender() {
    return (
      this.getVariable(VARIABLE_ID__GENDER) ||
      this.getVariable(VARIABLE_ID__GENDER_SINGAPORE)
    );
  }

  getGenderIcon() {
    switch (this.getGender()) {
      case GENDER_MALE:
        return 'man';
      case GENDER_FEMALE:
        return 'woman';
      default:
        return 'question-circle';
    }
  }

  getStudyNoLabel() {
    return this.constructor.getStudyNoLabel();
  }

  static getStudyNoLabel() {
    return 'Participant ID';
  }

  getPhoneNumber() {
    return this.getVariable(VARIABLE_ID__PHONE);
  }

  getEmailAddress() {
    return this.getVariable(VARIABLE_ID__EMAIL);
  }

  isActive() {
    return this.getStatus() === PARTICIPATION_STATE__ACTIVE;
  }

  getClinicianId() {
    return this.getVariable(VARIABLE_ID__CLINICIAN);
  }

  getCaseManagerId() {
    return this.getVariable(VARIABLE_ID__CASE_MANAGER);
  }

  getPendingQuestionnaires({
    assigneeId,
    currentDate = getCurrentYearMonthDay(),
    ignoreSnoozed = null,
  } = {}) {
    const currentSchedule = {
      dateStart: currentDate,
      dateEnd: currentDate,
    };
    if (!this.questionnaires) {
      return [];
    }
    return this.questionnaires.filter((questionnaire) => {
      if (assigneeId && questionnaire.assigneeId !== assigneeId) {
        return false;
      }
      if (
        ignoreSnoozed &&
        questionnaire.snoozeEnd &&
        ignoreSnoozed < questionnaire.snoozeEnd
      ) {
        return false;
      }
      if (
        questionnaire.state === PARTICIPANT_STATE__COMPLETED ||
        questionnaire.state === PARTICIPANT_STATE__CANCELED ||
        questionnaire.state === PARTICIPANT_STATE__SUSPENDED
      ) {
        return false;
      }
      return this.constructor.areIntersectingSchedules(currentSchedule, {
        dateStart: questionnaire.dateStart,
      });
    });
  }

  hasPendingQuestionnaires(...args) {
    return this.getPendingQuestionnaires(...args).length > 0;
  }

  getVariable(id) {
    return this.variables && this.variables[id];
  }

  getFirstRelevantActivity() {
    return minBy(
      filter(this.activities, ({ state }) => {
        return (
          state === ACTIVITY_STATE__ACTIVE ||
          state === ACTIVITY_STATE__SCHEDULED ||
          state === ACTIVITY_STATE__COMPLETED ||
          state === ACTIVITY_STATE__EXPIRED ||
          state === ACTIVITY_STATE__ABORTED
        );
      }),
      'dateStart',
    );
  }

  getLastActivity() {
    return maxBy(this.activities, 'dateEnd');
  }

  getNearestActivity({ currentDate = getCurrentYearMonthDay() } = {}) {
    const actual = filter(this.activities, (activity) => {
      if (!activity.dateEnd) {
        return false;
      }
      if (activity.dateEnd < currentDate) {
        return false;
      }
      return true;
    });
    if (actual.length === 0) {
      return null;
    }
    return minBy(actual, 'dateEnd');
  }

  getActivityCompletedAt(activityId) {
    const activity = find(this.activities, {
      activityId,
    });
    if (!activity) {
      return null;
    }
    return activity.completedAt;
  }

  getActivityDateStart(activityId) {
    const activity = find(this.activities, {
      activityId,
    });
    if (!activity) {
      return null;
    }
    if (!activity.dateStart) {
      return null;
    }
    return activity.dateStart;
  }

  getActivityDateEnd(activityId) {
    const activity = find(this.activities, {
      activityId,
    });
    if (!activity) {
      return null;
    }
    if (!activity.dateEnd) {
      return null;
    }
    return activity.dateEnd;
  }

  static areDisjointSchedules(q1, q2) {
    if (!q1 || !q2) {
      return true;
    }
    if (!q1.dateEnd && !q1.dateStart) {
      return false;
    }
    if (!q2.dateEnd && !q2.dateStart) {
      return false;
    }
    return (
      (q1.dateEnd && q2.dateStart && q1.dateEnd < q2.dateStart) ||
      (q2.dateEnd && q1.dateStart && q2.dateEnd < q1.dateStart)
    );
  }

  static areIntersectingSchedules(q1, q2) {
    return !this.areDisjointSchedules(q1, q2);
  }
}

PatientRecord.collection = 'PatientRecords';
PatientRecord.defaultSortingProperties = ['recipientName', '_id'];

export default PatientRecord;
