import styled from 'styled-components';
import React from 'react';
import { propTypes, defaultProps } from './Text';
import { relativeStyles, colorStyles } from './styles';

const Span = styled.span`
  ${colorStyles};
  ${relativeStyles};
`;

const TextSpan = ({ ...props }) => <Span {...props} />;

TextSpan.propTypes = propTypes;
TextSpan.defaultProps = defaultProps;

export default React.memo(TextSpan);
