import map from 'lodash/map';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import has from 'lodash/has';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import ReportSelect from '../../../common/selectors/Report';
import ProjectFilter from '../../../common/models/ProjectFilter';
import {
  getMultipleValues,
  currentReports,
} from '../../../common/api/aggregations/Filters';
import { projectMilestones } from '../../../common/api/zedoc';
import {
  FILTER_TYPE__VARIABLE,
  FILTER_TYPE__NOT_COMPLETED,
} from '../../../common/constants';
import Stack from '../../../common/components/primitives/Stack';
import Filter from './Filter';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';

const getProjectId = (state, { projectId }) => projectId;

const getVariableId = (filter) => filter.settings && filter.settings.id;

const Filters = compose(
  connect(
    createStructuredSelector({
      report: ReportSelect.one().forProject(getProjectId),
    }),
  ),
  ddp({
    subscriptions: (state, { projectId }) => [
      currentReports.withParams({
        projectId,
      }),
      projectMilestones.withParams({
        projectId,
      }),
    ],
    queries: (state, { filters, report, projectId }) => ({
      items:
        report &&
        getMultipleValues.withParams({
          // ...params,
          variableIds: compact(
            filters.map((filter) => {
              if (filter.type !== FILTER_TYPE__VARIABLE) {
                return null;
              }
              // TODO: This should be a more explicit condition, e.g.
              //       filter.widgetType === 'select', but we currently
              //       do not support that.
              if (has(filter.state, 'text')) {
                return null;
              }
              return getVariableId(filter);
            }),
          ),
          projectId,
          type: FILTER_TYPE__VARIABLE,
          versionId: report.getVersionId(),
        }),
    }),
    selectors: () => ({
      milestones: ProjectMilestoneSelect.all().where({
        projectId: getProjectId,
      }),
    }),
  }),
  withHandlers({
    onChange:
      ({ dispatch, setFilter, clearFilter }) =>
      (value, filter, checked) => {
        if (isEmpty(value)) {
          dispatch(
            clearFilter({
              id: filter.id,
            }),
          );
        }

        const index = findIndex(
          filter.state && filter.state.include,
          (x) => x === value,
        );
        // NOTE: In this context "filter" is an evaluated "bound filter". This means, it's state
        //       may vary from the original raw representation in the store, e.g. the default
        //       values may not be present in the store because they're computed in getBoundFilter() method.
        if ((index >= 0 && !checked) || (index < 0 && checked)) {
          if (!checked && index === 0 && filter.state.include.length === 1) {
            dispatch(
              setFilter({
                id: filter.id,
                state: omit(filter.state, 'include'),
              }),
            );
          } else {
            dispatch(
              setFilter({
                id: filter.id,
                state: {
                  ...filter.state,
                  include: checked
                    ? [...(filter.state.include || []), value]
                    : [
                        ...filter.state.include.slice(0, index),
                        ...filter.state.include.slice(index + 1),
                      ],
                },
              }),
            );
          }
        }
      },
  }),
)(({ filters, items, milestones, tags, onChange }) => {
  const mainFilters = filters
    .filter((filter) => filter.includesTags(tags))
    .map((filter) => {
      let filterItems;
      switch (filter.type) {
        case FILTER_TYPE__VARIABLE: {
          if (items) {
            filterItems = items[getVariableId(filter)];
          }
          break;
        }
        case FILTER_TYPE__NOT_COMPLETED: {
          filterItems = map(milestones, (milestone) => ({
            value: milestone._id,
            label: milestone.name,
          }));
          break;
        }
        default:
        // ...
      }

      const values = useMemo(() => filter.state.include, [filter]);
      return (
        <Filter
          key={filter.id}
          filter={filter}
          items={filterItems}
          onChange={onChange}
          values={values}
        />
      );
    });

  return <Stack>{mainFilters}</Stack>;
});

Filters.propTypes = {
  projectId: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(ProjectFilter)),
  tags: PropTypes.arrayOf(PropTypes.string),
};

Filters.defaultProps = {
  filters: [],
  tags: [],
};

export default Filters;
