import { useEffect, useState } from 'react';

const QUERY = '(prefers-reduced-motion: no-preference)';

export default () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(true);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);

    setPrefersReducedMotion(!window.matchMedia(QUERY).matches);

    const listener = (event) => {
      setPrefersReducedMotion(!event.matches);
    };

    if (matchMedia.addEventListener) {
      mediaQueryList.addEventListener('change', listener);
    } else {
      // polyfil for Safari and IE
      mediaQueryList.addListener(listener);
    }

    return () =>
      mediaQueryList.removeEventListener
        ? mediaQueryList.removeEventListener('change', listener)
        : mediaQueryList.removeListener(listener);
  }, []);

  return prefersReducedMotion;
};
