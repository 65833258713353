import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import without from 'lodash/without';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import Select, { StyledSelect } from '../../common/components/Select';
import Input from '../../common/components/Input';
import InputNumber from '../../common/components/InputNumber';
import InputDate from '../../common/components/InputDate';
import {
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_AFTER,
} from '../../common/constants';
import { createFilterOptionFactory } from '../../common/utils/text';
import usePagination from '../../utils/usePagination';

const getFilterOption = createFilterOptionFactory();

const noop = () => {};
const toString = (value) => {
  if (value === true) {
    return 'YES';
  }
  if (value === false) {
    return 'NO';
  }
  if (isNil(value)) {
    return 'NULL';
  }
  return value.toString();
};

const SelectOption = Select.Option;

const FilterInput = ({
  type,
  condition,
  state,
  settings,
  optionsSelector,
  optionsSubscription,
  knownOptions,
  meta,
  onChange,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const { ready: subscriptionsReady, items: fetchedOptions } = usePagination({
    debounceMs: 500,
    selector: optionsSelector,
    getSubscription: () => {
      if (!optionsSubscription) {
        return null;
      }
      const useSearch = !(meta && meta.noSearch);
      switch (condition) {
        case FILTER_CONDITION__INCLUDE:
        case FILTER_CONDITION__EXCLUDE: {
          const params = {
            type,
            condition,
            state,
            settings,
          };
          if (knownOptions && knownOptions.length < 1000) {
            // NOTE: We are expecting at most this number of results.
            //       "+1" is needed to accommodate NULL value.
            params.resultsPerPage = knownOptions.length + 1;
          } else {
            params.searchText = useSearch ? trim(searchText) : undefined;
          }
          return optionsSubscription(params);
        }
        default:
          return null;
      }
    },
  });
  const filterOptions = useMemo(() => {
    const allOptions = [...fetchedOptions];
    let currentValue;
    switch (condition) {
      case FILTER_CONDITION__INCLUDE:
        currentValue = state && state.include;
        break;
      case FILTER_CONDITION__EXCLUDE:
        currentValue = state && state.exclude;
        break;
      default:
      // ...
    }
    const byValue = keyBy(allOptions, 'value');
    const getLabel = (value) => meta && meta.labels && meta.labels[value];
    if (isArray(currentValue)) {
      forEach(currentValue, (value) => {
        if (!byValue[value]) {
          byValue[value] = {
            value,
            label: getLabel(value),
          };
          allOptions.push(byValue[value]);
        }
      });
    }
    const knownOptionsByValue = keyBy(knownOptions, 'value');
    return map(allOptions, (option) => {
      let label;
      if (
        knownOptionsByValue[option.value] &&
        knownOptionsByValue[option.value].label
      ) {
        label = knownOptionsByValue[option.value].label;
      } else {
        label = isNil(option.label) ? toString(option.value) : option.label;
      }
      return {
        ...option,
        label,
      };
    });
  }, [fetchedOptions, knownOptions, condition, state, meta]);
  let value;
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
      value = state && state.text;
      break;
    case FILTER_CONDITION__INCLUDE:
      value = state && state.include;
      break;
    case FILTER_CONDITION__EXCLUDE:
      value = state && state.exclude;
      break;
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_NOT_EQUAL:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
    case FILTER_CONDITION__EQUALS:
    case FILTER_CONDITION__NOT_EQUAL:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      value = state && state.threshold;
      break;
    default:
      value = null;
  }
  const handleOnSelect = useCallback(
    (newValue, newOption) => {
      if (isEmpty(value)) {
        onChange([newValue], {
          newLabels: {
            [newValue]: newOption.label,
          },
        });
      } else if (isArray(value) && !includes(value, newValue)) {
        onChange([...value, newValue], {
          newLabels: {
            ...(meta && meta.labels),
            [newValue]: newOption.label,
          },
        });
      }
      setSearchText('');
    },
    [value, meta, onChange, setSearchText],
  );
  const handleOnDeselect = useCallback(
    (clearedValue) => {
      if (includes(value, clearedValue)) {
        onChange(without(value, clearedValue), {
          newLabels: omit(meta && meta.labels, clearedValue),
        });
      }
    },
    [value, meta, onChange],
  );
  const handleOnChange = useCallback(
    (eventOrValue) => {
      if (eventOrValue && eventOrValue.target) {
        onChange(eventOrValue.target.value);
      } else {
        onChange(eventOrValue);
      }
    },
    [onChange],
  );
  const handleOnSearch = useCallback(
    (newSearchText) => {
      setSearchText(newSearchText);
    },
    [setSearchText],
  );
  switch (condition) {
    case FILTER_CONDITION__INCLUDE:
    case FILTER_CONDITION__EXCLUDE: {
      return (
        <StyledSelect
          data-testid="filters-filter-input"
          loading={!subscriptionsReady}
          mode="multiple"
          autoFocus
          autoClearSearchValue
          defaultOpen
          showSearch
          onSearch={handleOnSearch}
          onChange={noop}
          onSelect={handleOnSelect}
          onDeselect={handleOnDeselect}
          dropdownMatchSelectWidth={false}
          notFoundContent={t('empty')}
          value={value || []}
          style={{
            minWidth: 150,
          }}
          filterOption={getFilterOption()}
          optionLabelProp="label"
        >
          {map(filterOptions, (option) => {
            return (
              <SelectOption
                key={option._id || option.value}
                label={option.label}
                value={option.value}
              >
                {option.label}
                &nbsp;
                {'('}
                {option.count || 0}
                {')'}
              </SelectOption>
            );
          })}
        </StyledSelect>
      );
    }
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS: {
      return (
        <Input
          data-testid="filters-filter-input"
          autoFocus
          value={value || ''}
          onChange={handleOnChange}
        />
      );
    }
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_NOT_EQUAL:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
      return (
        <InputDate
          data-testid="filters-filter-input"
          style={{
            width: 'auto',
          }}
          autoFocus
          value={value || null}
          onChange={handleOnChange}
        />
      );
    case FILTER_CONDITION__EQUALS:
    case FILTER_CONDITION__NOT_EQUAL:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM: {
      switch (settings && settings.valueType) {
        case 'string': {
          return (
            <Input
              data-testid="filters-filter-input"
              autoFocus
              value={value || ''}
              onChange={handleOnChange}
            />
          );
        }
        default: {
          // NOTE: We treat 'number' as the default one,
          //       because valueTypes derived from JSON Schema,
          //       and currently it will only be specified for
          //       filters derived from variables.
          return (
            <InputNumber
              data-testid="filters-filter-input"
              style={{
                width: 'auto',
              }}
              autoFocus
              value={value || null}
              onChange={handleOnChange}
            />
          );
        }
      }
    }
    default:
      return null;
  }
};

FilterInput.propTypes = {
  type: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.objectOf(PropTypes.string),
    noSearch: PropTypes.bool,
  }),
  state: PropTypes.objectOf(PropTypes.any),
  settings: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
  knownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
};

FilterInput.defaultProps = {
  meta: null,
  state: null,
  settings: null,
  optionsSubscription: null,
  optionsSelector: null,
  knownOptions: null,
};

export default FilterInput;
