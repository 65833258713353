import { DeleteOutlined } from '@ant-design/icons';
import filterLodash from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import EJSON from '../common/utils/ejson';
import { notifyError } from '../utils/notify';
import Random from '../common/utils/random';
import Button from '../common/components/Button';
import Divider from '../common/components/Divider';
import Input, { InputGroup } from '../common/components/Input';
import Stack from '../common/components/primitives/Stack';
import Cluster from '../common/components/primitives/Cluster';
import Box from '../common/components/primitives/Box';
import Text from '../common/components/base/Text';
import Select, { StyledSelect } from '../common/components/Select';
import {
  dashboardFilters,
  updateUserDashboard,
} from '../common/api/currentUser';
import CurrentUserSelect from '../common/selectors/CurrentUser';
import UserDashboardSelect from '../common/selectors/UserDashboard';

const { Option } = Select;

const SavedFilters = ({
  dashboardId,
  filters,
  onLoadFilters,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const userId = useSelector(CurrentUserSelect.userId());
  const [filterName, setFilterName] = useState();
  let savedFilters = [];

  const userDashboard = useSelector(
    UserDashboardSelect.one().where({
      dashboardId,
      userId,
    }),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const { ready: dashboardFiltersReady } = useDDPSubscription(
    dashboardId &&
      dashboardFilters.withParams({
        dashboardId,
      }),
  );

  if (dashboardFiltersReady && userDashboard) {
    savedFilters = userDashboard.savedFilters || [];
  }

  const onFilterNameChange = (e) => setFilterName(e.target.value);
  const onFilterSave = () => {
    if (ddpIsPending && !dashboardFiltersReady) {
      return;
    }

    const filterId = Random.id();

    onChange(filterId);
    setFilterName(null);

    ddpCall(
      updateUserDashboard.withParams({
        dashboardId,
        savedFilters: [
          ...savedFilters,
          {
            id: filterId,
            label: filterName,
            filtersJSONString: EJSON.stringify(filters),
          },
        ],
      }),
    ).catch(notifyError());
  };

  const onFilterSelect = (filterId) => {
    onChange(filterId);

    const filter = find(savedFilters, {
      id: filterId,
    });

    if (!filter) {
      return;
    }

    let filtersJSONString = [];
    try {
      filtersJSONString = EJSON.parse(filter.filtersJSONString);
    } catch (e) {
      filtersJSONString = [];
    }

    onLoadFilters(filtersJSONString, filter.id);
  };
  const onFilterDelete = (event, filterId) => {
    event.stopPropagation();

    if (!filterId) {
      return;
    }

    onChange();

    const filteredFilters = filterLodash(
      savedFilters,
      (filter) => filter.id !== filterId,
    );

    ddpCall(
      updateUserDashboard.withParams({
        dashboardId,
        savedFilters: filteredFilters,
      }),
    ).catch(notifyError());
  };

  return (
    <div>
      <StyledSelect
        data-testid="filters-saved"
        style={{
          width: 250,
        }}
        placeholder={t('filterSaved', {
          count: 0,
        })}
        optionLabelProp="label"
        value={value}
        onSelect={onFilterSelect}
        dropdownRender={(menu) => (
          <Stack space={1}>
            {menu}
            {!isEmpty(filters) && (
              <>
                <Divider />
                <Box space={1}>
                  <InputGroup>
                    <Input
                      value={filterName}
                      onChange={onFilterNameChange}
                      placeholder={t('name')}
                    />
                    <Button type="primary" onClick={onFilterSave}>
                      {t('save')}
                    </Button>
                  </InputGroup>
                </Box>
              </>
            )}
          </Stack>
        )}
      >
        {map(savedFilters, (filter) => (
          <Option key={filter.id} value={filter.id} label={filter.label}>
            <Cluster justify="space-between">
              <Text.Span>{filter.label}</Text.Span>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={(event) => onFilterDelete(event, filter.id)}
                ghost
              />
            </Cluster>
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

SavedFilters.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  onLoadFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SavedFilters.defaultProps = {
  filters: [],
  value: null,
  onChange: () => {},
};

export default SavedFilters;
