import { css } from 'styled-components';
import { theme } from '../../../utilsClient/cssHelpers';

// eslint-disable-next-line import/prefer-default-export
export const sizes = {
  small: css`
    font-size: ${theme('font.size.small')};
  `,
  default: css``,
  large: css`
    font-size: ${theme('font.size.large')};
  `,
};
