import moment from 'moment';
import isNaN from 'lodash/isNaN';
import { YEAR_MONTH_DAY } from '../../constants';
import BaseModel from '../BaseModel';

class CalendarDate extends BaseModel {
  getHour() {
    if (this.hours !== undefined) {
      return Math.floor(this.hours / 1000);
    }
    return NaN;
  }

  getMinute() {
    if (this.hours !== undefined) {
      return Math.floor(this.hours % 1000);
    }
    return NaN;
  }

  /**
   * Convert calendar date to moment in current system time.
   * @returns {Moment}
   */
  toMoment() {
    const base = moment(this.yearMonthDay, YEAR_MONTH_DAY);
    if (!base.isValid()) {
      return base;
    }
    const minutes = this.getMinute() + 60 * this.getHour();
    if (isNaN(minutes)) {
      return base;
    }
    return base.add(minutes, 'minutes');
  }

  isValid() {
    return this.toMoment().isValid();
  }

  toJSON() {
    return {
      ...this,
    };
  }

  static current() {
    const rawCalendarDate = {
      yearMonthDay: moment().format(YEAR_MONTH_DAY),
    };
    return new this(rawCalendarDate);
  }

  static currentInTimezone(tz = this.defaultTimezone) {
    if (!moment.tz) {
      throw new Error(
        'CalendarDate.currentInTimezone requires moment-timezone',
      );
    }
    const rawCalendarDate = {
      yearMonthDay: moment().tz(tz).format(YEAR_MONTH_DAY),
    };
    return new this(rawCalendarDate);
  }

  /**
   * Create a CalendarDate object based on date object and provided timezone.
   * @param {Date} date
   * @param {String} tz
   * @returns {CalendarDate}
   */
  static fromDateAndTimezone(date, tz = this.defaultTimezone) {
    if (!moment.tz) {
      throw new Error(
        'CalendarDate.fromDateAndTimezone requires moment-timezone',
      );
    }
    const m = moment(date);
    if (m.isValid()) {
      return new this({
        yearMonthDay: m.tz(tz).format(YEAR_MONTH_DAY),
      });
    }
    return new this();
  }

  /**
   * Parse date exactly as is in the isoString. Theoretically, the date string
   * may contain detailed time/ utc offset information, in which case we will not
   * be translating the date to utc.
   * @param {String} isoString
   * @returns {CalendarDate}
   */
  static fromIsoStringLiterally(isoString) {
    // NOTE: We need to use "parse" zone, or otherwise we are risking
    //       that moment will modify the date by translating it to utc.
    const m = moment.parseZone(isoString, moment.ISO_8601);
    if (m.isValid()) {
      return new this({
        yearMonthDay: m.format(YEAR_MONTH_DAY),
      });
    }
    return new this();
  }

  static isSameOrBefore(d1, d2) {
    if (!d1 || !d2) {
      return false;
    }
    return d1.yearMonthDay <= d2.yearMonthDay;
  }

  static isBefore(d1, d2) {
    if (!d1 || !d2) {
      return false;
    }
    return d1.yearMonthDay < d2.yearMonthDay;
  }
}

CalendarDate.defaultTimezone = 'UTC';

export default CalendarDate;

require('./only.meteor');
