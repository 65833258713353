import { ThemeProvider } from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../common/components/Button';
import Templates from './components/Templates';
import Template from './components/Template';
import Stack from '../../common/components/primitives/Stack';
import DialogCreateProject, {
  dialog as dialogCreateProject,
} from '../SettingsProjects/components/DialogCreateProject';
import Layout from './components/Layout';
import themeProjectWizard from '../../ui/themeProjectWizard';
import GlobalStyleProjectWizard from '../../ui/GlobalStyleProjectWizard';

const ProjectWizardTemplates = () => {
  // const {
  //   t,
  // } = useTranslation();

  // useDocumentTitle([
  //   t('settings'),
  //   t('project', {
  //     count: 0,
  //   }),
  //   questionnaire.getName(),
  // ]);

  const dispatch = useDispatch();

  const handleAddProject = useCallback(
    () => dispatch(dialogCreateProject.open({})),
    [dispatch],
  );

  return (
    <ThemeProvider theme={themeProjectWizard}>
      <GlobalStyleProjectWizard>
        <DialogCreateProject />
        <Layout
          title="Pick a template and make it your own"
          subTitle="All templates are easily customisable"
          // backUrl="/settings/projects"
          extra={
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              // FIXME: Add permission so add project permission can access templates page
              onClick={handleAddProject}
            >
              Blank Project
            </Button>
          }
        >
          <Stack>
            <Templates
              title="ePROMs"
              description="Electronic Patient Reported Outcomes Measures (including ICHOM sets)"
              // count={1252}
            >
              <Template
                title="Depression and Anxiety (ICHOM Set)"
                description="Routine measurement of symptoms and function to support shared decision-making and inform treatment."
                onClick={handleAddProject}
              />
              <Template
                title="Depression and Anxiety (ICHOM Set)"
                description="Routine measurement of symptoms and function to support shared decision-making and inform treatment."
                onClick={handleAddProject}
              />
            </Templates>
            <Templates
              title="Remote Symptom Monitoring"
              // count={5}
            >
              <Template
                title="Lung Cancer"
                description="Weekly symptom monitoring to support early relapse detection and patient symptom support."
                onClick={handleAddProject}
              />
            </Templates>
          </Stack>
        </Layout>
      </GlobalStyleProjectWizard>
    </ThemeProvider>
  );
};

ProjectWizardTemplates.propTypes = {};

ProjectWizardTemplates.defaultProps = {};

export default ProjectWizardTemplates;
