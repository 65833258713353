import { SettingOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/components/Button';
import { openProjectProfileDialog } from '../store';

const ProjectSettings = ({ projectId, recipientId, participationId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleOnClick = useCallback(() => {
    dispatch(
      openProjectProfileDialog({
        projectId,
        recipientId,
        participationId,
      }),
    );
  }, [dispatch, projectId, recipientId, participationId]);

  return (
    <Button
      data-testid="button-settings"
      type="link"
      icon={<SettingOutlined />}
      onClick={handleOnClick}
    >
      {t('settings')}
    </Button>
  );
};

ProjectSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  participationId: PropTypes.string.isRequired,
};

export default ProjectSettings;
