import React from 'react';
import { ddp } from '@theclinician/ddp-connector';
import map from 'lodash/map';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { notifyError } from '../../../../utils/notify';
import { all as allPermissionsDomains } from '../../../../common/api/collections/PermissionsDomains';
import { apiAdminCreateProject } from '../../../../common/api/admin';
import createDialog from '../../../../store/ui/dialogs/create';
import { callMethod } from '../../../../common/utilsClient/ddp/actions';
import PermissionsDomainSelect from '../../../../common/selectors/PermissionsDomain';
import Modal from '../../../../components/dialogs/Modal';
import Form from './Form';

const dialog = createDialog('Screens | SettingsProjects | DialogCreateProject');

const Container = compose(
  dialog.connect({
    mapDispatchToProps: (dispatch) => ({
      onCancel: () => {
        dispatch(dialog.close());
        dispatch(reset(dialog.name));
      },
      doSubmit: () => dispatch(submit(dialog.name)),
    }),
  }),
  ddp({
    subscriptions: [allPermissionsDomains.withParams()],
    selectors: () => ({
      belongsToOptions: createSelector(
        PermissionsDomainSelect.all(),
        (permissionsDomains) =>
          map(permissionsDomains, (domain) => ({
            value: domain._id,
            label: domain.name,
          })),
      ),
    }),
    mutations: {
      onCreate:
        ({ dispatch }) =>
        ({ name, description, belongsTo, billingCode }) =>
          dispatch(
            callMethod(apiAdminCreateProject, {
              name,
              description,
              belongsTo,
              billingCode,
            }),
          )
            .then(({ projectId }) =>
              dispatch(
                push(`/settings/projects/${projectId}/project-variables`),
              ),
            )
            .then(() => dispatch(dialog.close()))
            .catch(notifyError()),
    },
    renderLoader: null,
  }),
)(({ open, doSubmit, onCancel, onCreate, belongsToOptions }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('createProject')}
      okText={t('create')}
      visible={open}
      // confirmLoading={isConfirmLoading}
      onOk={doSubmit}
      onCancel={onCancel}
    >
      <Form
        form={dialog.name}
        onSubmit={onCreate}
        belongsToOptions={belongsToOptions}
      />
    </Modal>
  );
});

export default Container;
export { dialog };
