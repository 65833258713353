import map from 'lodash/map';
import range from 'lodash/range';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../../../../common/components/primitives/Stack';
import Cluster from '../../../../common/components/primitives/Cluster';
import Text from '../../../../common/components/base/Text';
import Skeleton from '../../../../common/components/Skeleton';
import { theme } from '../../../../common/utilsClient/cssHelpers';

const Component = styled.ul`
  list-style: none;

  > * + * {
    margin-top: ${theme('space.2')};
  }
`;

// TODO: Improve loading state with better Skeleton placement

const List = ({
  'data-testid': testId,
  title,
  dataSource,
  pageSize,
  loading,
  renderItem,
  renderPagination,
}) => {
  return (
    <Stack>
      {(title || renderPagination) && (
        <>
          <Cluster justify="space-between">
            {title && (
              <Text.Paragraph importance="high">{title}</Text.Paragraph>
            )}
            {renderPagination && renderPagination()}
          </Cluster>
        </>
      )}
      <Component data-testid={testId}>
        {loading
          ? range(pageSize).map((index) => (
              <Stack key={index} as="li">
                <Stack space={0}>
                  <Skeleton width="50%" />
                  <Skeleton />
                </Stack>
              </Stack>
            ))
          : map(
              dataSource,
              (item, index) => renderItem && renderItem(item, index),
            )}
      </Component>
    </Stack>
  );
};

List.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  renderItem: PropTypes.func,
  renderPagination: PropTypes.func,
};

List.defaultProps = {
  'data-testid': 'list',
  title: null,
  dataSource: [],
  pageSize: 2,
  loading: false,
  renderItem: null,
  renderPagination: null,
};

export default List;
