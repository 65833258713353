import React from 'react';
import styled from 'styled-components/macro';
import Slider from './LazySlider';

const Component = styled(Slider)`
  && {
    height: auto;

    .rc-slider-rail,
    .rc-slider-step {
      height: 0.125em;
    }

    .rc-slider-handle {
      margin-top: -0.9375em;
      transform: translateX(-25%);
      will-change: left;
    }

    .rc-slider-mark {
      position: relative;

      &-text {
        max-width: calc(
          100% / ${(props) => Object.keys(props.marks || {}).length}
        );
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .rc-slider-dot {
      margin-bottom: -1px;
    }
  }
`;

export default (props) => <Component {...props} />;
