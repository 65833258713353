import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';
import {
  RESOURCE_TYPE__PROJECT,
  RESOURCE_TYPE__PATIENT,
  PROJECT_SEARCH_PROJECT,
  PROJECT_FORCE_SYNCHRONIZATION,
  ORGANIZATION_ACCESS_VARIABLE,
  ORGANIZATION_SEARCH_QUESTIONNAIRE,
  ORGANIZATION_REVIEW_QUESTIONNAIRE,
  PROJECT_VIEW_ANALYTICS,
  PROJECT_ATTACH_PARTICIPATION,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
  PROJECT_REVIEW_PROJECT,
  PROJECT_PROFILE_REVIEW_PARTICIPATION,
  PROJECT_PROFILE_UPDATE_PARTICIPATION,
  PATIENT_MILESTONE_REVIEW_ACTIVITY,
  PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS,
  PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK,
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ACTIVITY,
  PATIENT_MILESTONE_CREATE_ACTIVITY,
  PATIENT_MILESTONE_FORCE_SYNCHRONIZATION,
  PATIENT_MILESTONE_DELETE_ANSWERS_SHEET,
  PATIENT_REVIEW_PATIENT,
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_UPDATE_EAPP,
  PROJECT_SEARCH_EAPP,
  PROJECT_PROFILE_ATTACH_ACTIVITY,
} from '../permissions';
import CalendarDateSchema from '../models/CalendarDateSchema';
import {
  filtersSchema,
  sorterSchema,
  searchApiSchema,
  searchFilterOptionsApiSchema,
} from './common';
import { ACTIVITY_STATES } from '../constants';
import Id from '../schemata/Id';

export const assignedToMeAndPending = new ApiSpec({
  name: 'api.zedoc.assignedToMeAndPending',
  permissions: [PROJECT_PROFILE_SEARCH_PARTICIPATION],
  schema: new Schema({
    projectId: {
      type: String,
      optional: true,
      regEx: Schema.RegEx.Id,
    },
    pageIndex: {
      type: Number,
      optional: true,
      min: 0,
    }, // 0, 1, 2, 3, etc.
    resultsPerPage: {
      type: Number,
      optional: true,
      min: 0,
    },
    currentDate: {
      type: CalendarDateSchema,
      optional: true,
    },
  }),
});

export const apiZedocSearchPatientRecords = new ApiSpec({
  name: 'api.zedoc.searchPatientRecords',
  resources: [
    {
      if: 'projectId',
      then: [
        {
          type: RESOURCE_TYPE__PROJECT,
        },
      ],
    },
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
      optional: true,
    },
    filters: {
      type: [filtersSchema],
      optional: true,
    },
    sorter: {
      type: sorterSchema,
      optional: true,
    },
    pageIndex: {
      type: Number,
      optional: true,
      min: 0,
    }, // 0, 1, 2, 3, etc.
    resultsPerPage: {
      type: Number,
      optional: true,
      min: 0,
    },
    controlId: {
      type: String,
      optional: true,
    },
  }),
});

export const patientDetails = new ApiSpec({
  name: 'api.zedoc.patientDetails',
  resources: [
    {
      variables: {
        recipientsRealm: PATIENT_REVIEW_PATIENT,
        participationsRealm: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    properties: {
      projectId: Id,
      recipientId: Id,
    },
  },
});

export const patientActivityDetails = new ApiSpec({
  name: 'api.zedoc.patientActivityDetails',
  permissions: [PATIENT_MILESTONE_REVIEW_ACTIVITY],
  schema: new Schema({
    activityId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocPatientActivityNotifications = new ApiSpec({
  name: 'api.zedoc.patientActivityNotifications',
  permissions: [PATIENT_MILESTONE_REVIEW_ACTIVITY],
  schema: new Schema({
    activityId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocAllProjects = new ApiSpec({
  name: 'api.zedoc.allProjects',
  permissions: [PROJECT_SEARCH_PROJECT],
  schema: new Schema({}),
});

export const apiZedocMyProjects = new ApiSpec({
  name: 'api.zedoc.myProjects',
  permissions: [PROJECT_SEARCH_PROJECT],
  schema: new Schema({}),
});

export const apiZedocOneProject = new ApiSpec({
  name: 'api.zedoc.oneProject',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocIdentifierVariables = new ApiSpec({
  name: 'api.zedoc.identifierVariables',
  resources: [
    {
      variables: {
        scope: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: new Schema({}),
});

export const apiZedocProjectVariables = new ApiSpec({
  name: 'api.zedoc.projectVariables',
  resources: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const projectMilestones = new ApiSpec({
  name: 'api.zedoc.projectMilestones',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
    },
  }),
});

export const getSortedMilestones = new ApiSpec({
  name: 'api.zedoc.getSortedMilestones',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const generateStudyNo = new ApiSpec({
  name: 'api.zedoc.generateStudyNo',
  permissions: [PROJECT_ATTACH_PARTICIPATION],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const getExportSelections = new ApiSpec({
  name: 'api.zedoc.getExportSelections',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const sendActivityReminder = new ApiSpec({
  name: 'api.zedoc.sendActivityReminder',
  permissions: [PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS],
  schema: new Schema({
    activityId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocRemoveEmptyAnswersSheet = new ApiSpec({
  name: 'api.zedoc.removeEmptyAnswersSheet',
  resources: [PATIENT_MILESTONE_DELETE_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const changeActivity = new ApiSpec({
  name: 'api.zedoc.changeActivity',
  permissions: [PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    activityId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const answersSheetDetails = new ApiSpec({
  name: 'api.zedoc.answersSheetDetails',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const answersSheetGenerics = new ApiSpec({
  name: 'api.zedoc.answersSheetGenerics',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const answersSheetCATReports = new ApiSpec({
  name: 'api.zedoc.answersSheetCATReports',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const answersSheetCATCharts = new ApiSpec({
  name: 'api.zedoc.answersSheetCATCharts',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocGetPDFForAnswersSheet = new ApiSpec({
  name: 'api.zedoc.getPDFForAnswersSheet',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocCopyActivity = new ApiSpec({
  name: 'api.zedoc.copyActivity',
  resources: [
    PATIENT_MILESTONE_UPDATE_ACTIVITY,
    PATIENT_MILESTONE_CREATE_ACTIVITY,
  ],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: {
        type: 'string',
      },
      state: {
        type: 'string',
        enum: ACTIVITY_STATES,
      },
      dateStart: {
        type: 'string',
        format: 'full-date',
      },
      dateEnd: {
        type: 'string',
        format: 'full-date',
      },
      timeStart: {
        type: 'string',
        format: 'time',
      },
      timeEnd: {
        type: 'string',
        format: 'time',
      },
      keepOriginal: {
        type: 'boolean',
      },
      keepOriginalResponses: {
        type: 'boolean',
      },
    },
  },
});

export const projectTotals = new ApiSpec({
  name: 'api.zedoc.projectTotals',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocProjectSummary = new ApiSpec({
  name: 'api.zedoc.projectSummary',
  resources: [
    {
      // note that there's no action here, only permission check
      can: PROJECT_REVIEW_PROJECT,
    },
    {
      variables: {
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiZedocPatientServiceSyncProgress = new ApiSpec({
  name: 'api.zedoc.patientServiceSyncProgress',
  resources: [
    {
      // note that there's no action here, only permission check
      can: PROJECT_REVIEW_PROJECT,
    },
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const generateRecipientLoginForServerless = new ApiSpec({
  name: 'api.zedoc.generateRecipientLoginForServerless',
  resources: [
    PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
    {
      type: RESOURCE_TYPE__PATIENT,
    },
  ],
  schema: new Schema({
    activityId: {
      type: String,
    },
  }),
});

export const forceSyncToPatientService = new ApiSpec({
  name: 'api.zedoc.forceSyncToPatientService',
  permissions: [PROJECT_FORCE_SYNCHRONIZATION],
  schema: new Schema({
    projectId: {
      type: String,
    },
  }),
});

export const apiZedocSyncActivity = new ApiSpec({
  name: 'api.zedoc.apiZedocSyncActivity',
  permissions: [PATIENT_MILESTONE_FORCE_SYNCHRONIZATION],
  schema: new Schema({
    activityId: {
      type: String,
    },
  }),
});

export const apiZedocSearchQuestionnaireRecords = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireRecords',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: searchApiSchema,
});

export const apiZedocSearchQuestionnaireRecordsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireRecords.filterOptions',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: searchFilterOptionsApiSchema,
});

export const apiZedocSearchQuestionnaireRecordsTags = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireRecords.tags',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: new Schema({}),
});

export const apiZedocSearchParticipationAndQuestionnaire = new ApiSpec({
  name: 'api.zedoc.searchParticipationAndQuestionnaire',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    ORGANIZATION_REVIEW_QUESTIONNAIRE,
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      projectId: {
        type: String,
      },
      questionnaireId: {
        type: String,
      },
    }),
  ]),
});

export const apiZedocSearchParticipationAndQuestionnaireFilterOptions =
  new ApiSpec({
    name: 'api.zedoc.searchParticipationAndQuestionnaire.filterOptions',
    resources: [
      PROJECT_VIEW_ANALYTICS,
      ORGANIZATION_REVIEW_QUESTIONNAIRE,
      {
        variables: {
          scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        },
      },
    ],
    schema: Schema.merge([
      searchFilterOptionsApiSchema,
      {
        projectId: {
          type: String,
        },
        questionnaireId: {
          type: String,
        },
      },
    ]),
  });

export const apiZedocSearchProjects = new ApiSpec({
  name: 'api.zedoc.searchProjects',
  resources: [
    {
      variables: {
        viewProjects: PROJECT_REVIEW_PROJECT,
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: searchApiSchema,
});

export const apiZedocSearchResponses = new ApiSpec({
  name: 'api.zedoc.searchResponses',
  resources: [
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      projectId: {
        type: String,
        optional: true,
      },
      recipientId: {
        type: String,
        optional: true,
      },
      questionnaireId: {
        type: String,
        optional: true,
      },
    }),
  ]),
});

export const apiZedocSearchEAPPs = new ApiSpec({
  name: 'api.zedoc.searchEAPPs',
  resources: [
    {
      variables: {
        scope: PROJECT_SEARCH_EAPP,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      projectId: {
        type: String,
        optional: true,
      },
      recipientId: {
        type: String,
        optional: true,
      },
      participationId: {
        type: String,
        optional: true,
      },
      activityId: {
        type: String,
        optional: true,
      },
      answersSheetId: {
        type: String,
        optional: true,
      },
    }),
  ]),
});

export const apiZedocSearchEAPPsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchEAPPs.filterOptions',
  resources: [
    {
      variables: {
        scope: PROJECT_SEARCH_EAPP,
      },
    },
  ],
  schema: Schema.merge([
    searchFilterOptionsApiSchema,
    new Schema({
      projectId: {
        type: String,
        optional: true,
      },
      recipientId: {
        type: String,
        optional: true,
      },
      participationId: {
        type: String,
        optional: true,
      },
      activityId: {
        type: String,
        optional: true,
      },
      answersSheetId: {
        type: String,
        optional: true,
      },
    }),
  ]),
});

export const apiZedocUpdateEAPP = new ApiSpec({
  name: 'api.zedoc.updateEAPP',
  resources: [
    PROJECT_UPDATE_EAPP,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['eappId'],
    properties: {
      eappId: Id,
      assigneeId: Id,
      completeStep: {
        type: 'boolean',
      },
      noteContent: {
        type: 'string',
      },
    },
  },
});

export const apiZedocCreateParticipation = new ApiSpec({
  name: 'api.zedoc.createParticipation',
  resources: [
    PROJECT_ATTACH_PARTICIPATION,
    {
      if: 'recipientId',
      then: [PATIENT_ATTACH_PARTICIPATION],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId'],
    additionalProperties: false,
    properties: {
      projectId: {
        type: 'string',
      },
      recipientId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
      proceedEvenIfIdentifierExists: {
        type: 'boolean',
      },
    },
  },
});

export const apiZedocUpdateParticipation = new ApiSpec({
  name: 'api.zedoc.updateParticipation',
  resources: [
    PROJECT_PROFILE_UPDATE_PARTICIPATION,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['participationId'],
    additionalProperties: false,
    properties: {
      participationId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
    },
  },
});

export const apiZedocCreateActivity = new ApiSpec({
  name: 'api.zedoc.createActivity',
  resources: [
    PROJECT_PROFILE_ATTACH_ACTIVITY,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['participationId'],
    additionalProperties: false,
    properties: {
      participationId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
    },
  },
});

export const apiZedocUpdateActivity = new ApiSpec({
  name: 'api.zedoc.updateActivity',
  resources: [
    PATIENT_MILESTONE_UPDATE_ACTIVITY,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['activityId'],
    additionalProperties: false,
    properties: {
      activityId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
    },
  },
});
