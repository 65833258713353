import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../common/components/base/Text';
import Tag from '../../common/components/Tag';
import Cluster from '../../common/components/primitives/Cluster';
import Stack from '../../common/components/primitives/Stack';
import {
  stateTypes,
  deliveryTypeIcons,
  stateTranslationKeys,
  deliveryTypeTranslationKeys,
} from './helpers';

const NotificationLog = ({
  address,
  deliveryType,
  state,
  sentAt,
  numberOfMessageParts,
}) => {
  const { t } = useTranslation();

  return (
    <Cluster justify="space-between">
      <Cluster space={2}>
        {state && (
          <Tag type={stateTypes[state]}>
            {t(`states:${stateTranslationKeys[state]}`)}
          </Tag>
        )}
        <Stack space={0}>
          {sentAt && <Text.Paragraph>{sentAt}</Text.Paragraph>}
          {numberOfMessageParts && (
            <Text.Paragraph size="small">
              {numberOfMessageParts} {t('states:sent')}
            </Text.Paragraph>
          )}
        </Stack>
      </Cluster>
      <Cluster>
        <Text.Paragraph>
          <Stack space={0}>
            <Text.Span>{address}</Text.Span>
            <Text.Paragraph align="end">
              <Text.Span size="small">
                {t(deliveryTypeTranslationKeys[deliveryType])}
              </Text.Span>{' '}
              <Text.Span>{deliveryTypeIcons[deliveryType]}</Text.Span>
            </Text.Paragraph>
          </Stack>
        </Text.Paragraph>
      </Cluster>
    </Cluster>
  );
};

NotificationLog.propTypes = {
  address: PropTypes.string,
  deliveryType: PropTypes.string,
  state: PropTypes.string,
  sentAt: PropTypes.string,
  numberOfMessageParts: PropTypes.number,
};

NotificationLog.defaultProps = {
  address: null,
  deliveryType: null,
  state: null,
  sentAt: null,
  numberOfMessageParts: null,
};

export default NotificationLog;
