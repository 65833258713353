import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import { ADMIN_SEARCH_ROLE } from '../../common/permissions';
import { all as allPermissionsDomains } from '../../common/api/collections/PermissionsDomains';
import PermissionsDomainSelect from '../../common/selectors/PermissionsDomain';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import Stack from '../../common/components/primitives/Stack';
import RolesFilters from './components/RolesFilters';
import RolesTable from './components/RolesTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import DialogCreateRole from './components/DialogCreateRole';
import { setSelectedRole } from './store';

const constant = (x) => () => x;

const selectDomains = PermissionsDomainSelect.all()
  .whereUserHasPermission(CurrentUserSelect.user(), ADMIN_SEARCH_ROLE)
  .map(
    constant((domain) => ({
      value: domain._id,
      label: domain.name,
    })),
  );

const SettingsRoles = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('role', {
      count: 0,
    }),
  ]);
  const dispatch = useDispatch();
  useDDPSubscription(allPermissionsDomains.withParams());
  useEffect(() => {
    dispatch(setSelectedRole({}));
  }, [dispatch]);
  const domains = useSelector(selectDomains);
  return (
    <>
      <DialogCreateRole />
      <Stack>
        <RolesFilters domains={domains} />
        <RolesTable domains={domains} />
      </Stack>
    </>
  );
};

export default SettingsRoles;
