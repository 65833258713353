import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { slugify } from '../../common/utils/formatting';
import Project from '../../common/models/Project';
import ProjectSelect from '../../common/selectors/Project';
import Stack from '../../common/components/primitives/Stack';
import Grid from '../../common/components/primitives/Grid';
import Sidebar from '../../common/components/primitives/Sidebar';
import Box from '../../common/components/primitives/Box';
import Spacer from '../../common/components/primitives/Spacer';
import Text from '../../common/components/base/Text';
import List from '../../common/components/List';
import Divider from '../../common/components/Divider';
import PageBar from '../../components/Layout/PageBar';
import Search from '../../components/inputs/Search';
import Highlighter from '../../common/components/Highlighter';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import useHighlight from '../../utils/useHighlight';
import ProjectSummary from '../../components/projects/ProjectSummary';
import { apiZedocSearchProjects } from '../../common/api/zedoc';
import { getSorter, getFilters } from './store';

const ProjectItem = ({ project, highlight }) => {
  return (
    <Sidebar sidebar={<ProjectSummary project={project} />}>
      <Stack space={0}>
        <Text.Link
          data-testid={`project-tab-${project.name}`}
          to={`/projects/${project._id}`}
          size="large"
          importance="high"
        >
          <Highlighter text={project.name} highlight={highlight} />
        </Text.Link>
        <Text.Paragraph importance="low">{project.description}</Text.Paragraph>
      </Stack>
    </Sidebar>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  highlight: PropTypes.arrayOf(PropTypes.string),
};

ProjectItem.defaultProps = {
  highlight: null,
};

const ListComponent = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('project', {
      count: 0,
    }),
  ]);
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  // TODO: In the future, this list should be evaluated
  //       based on user preferences.
  const userProjects = [];
  const {
    ready: subscriptionsReady,
    items: projects,
    pageSize,
    totalItems,
    renderPagination,
  } = usePagination({
    debounceMs: 1000,
    selector: ProjectSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchProjects.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const highlight = useHighlight(filters, ['name']);
  return (
    <Stack>
      <PageBar
        title={t('project', {
          count: 0,
        })}
      />
      <Stack>
        <Grid>
          <Search />
        </Grid>
        {!isEmpty(userProjects) && (
          <Box.Primary boxShadow="base">
            <Stack asList>
              <Text.Paragraph>Your projects</Text.Paragraph>
              {map(userProjects, (project, i) => (
                <List.Item
                  data-testid={`project-item-${slugify(project.name)}`}
                  key={project._id}
                >
                  <Stack>
                    {i !== 0 && <Divider />}
                    <Spacer space={2}>
                      <ProjectItem project={project} />
                    </Spacer>
                  </Stack>
                </List.Item>
              ))}
            </Stack>
          </Box.Primary>
        )}
        <Box.Primary boxShadow="base">
          <List
            data-testid="projects-list-Your projects"
            title={`${t('total')} ${totalItems}`}
            dataSource={projects}
            loading={!subscriptionsReady}
            pageSize={pageSize}
            renderItem={(project) => (
              <ProjectItem
                data-testid={`project-item-${slugify(project.name)}`}
                key={project._id}
                project={project}
                highlight={highlight}
              />
            )}
            renderPagination={renderPagination}
          />
        </Box.Primary>
      </Stack>
    </Stack>
  );
};

export default ListComponent;
