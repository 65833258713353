import React from 'react';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { escapeRegExp } from '../../utils/text';

// eslint-disable-next-line import/prefer-default-export
export const renderMarks = (text, highlight) => {
  if (!highlight) {
    return text;
  }
  if (!isArray(highlight)) {
    return renderMarks(text, highlight.split(/\s+/));
  }
  const regexes = highlight.map(
    (chunk) => new RegExp(`${escapeRegExp(chunk)}`, 'i'),
  );
  const chunks = text.split(/\s+/);

  return map(chunks, (chunk, index) => {
    for (let i = 0; i <= regexes.length; i += 1) {
      const match = chunk.match(regexes[i]);
      if (match) {
        const j = match.index;
        const k = match[0].length;
        const highlightedText = chunk.substr(j, k);
        return [
          index > 0 ? ' ' : null,
          chunk.substr(0, j),
          highlightedText && <mark key={index}>{highlightedText}</mark>,
          chunk.substr(j + k),
        ];
      }
    }
    return [index > 0 ? ' ' : null, chunk];
  });
};
