import map from 'lodash/map';
import get from 'lodash/get';
// import set from 'lodash/set';
import has from 'lodash/has';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isPlainObject from 'lodash/isPlainObject';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import BaseModel from './BaseModel';
import Activity from './Activity';
import Recipient from './Recipient';
import Participation from './Participation';
import cleanValue from '../utils/cleanValue';
import setValue, { deleteKey, isRequiredKeyError } from '../utils/setValue';
import checkSchema, { isAtomic } from '../utils/checkSchema';
import csvEncode from '../utils/csvEncode';
import { DOMAIN_PREFIX } from '../constants';

const getKey = (target, path, options = {}) => {
  const { arrayFilters } = options;
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index < 0) {
    return path;
  }
  const key = parts.slice(0, index).join('.');
  const array = get(target, key);
  if (isArray(array) && arrayFilters && arrayFilters[0]) {
    const i = findIndex(array, arrayFilters[0]);
    if (index === parts.length - 1) {
      return `${key}.${i}`;
    }
    const nextKey = getKey(array[i], parts.slice(index + 1).join('.'), {
      ...options,
      arrayFilters: arrayFilters.slice(1),
    });
    if (nextKey) {
      return `${key}.${i}.${nextKey}`;
    }
  }
  return undefined;
};

const getValueWithArrayFilters = (target, path, arrayFilters) => {
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index >= 0) {
    const array = get(target, parts.slice(0, index).join('.'));
    if (isArray(array) && arrayFilters[0]) {
      const item = find(array, arrayFilters[0]);
      if (index === parts.length - 1) {
        return item;
      }
      return getValueWithArrayFilters(
        item,
        parts.slice(index + 1).join('.'),
        arrayFilters.slice(1),
      );
    }
    return undefined;
  }
  return get(target, path);
};

const getSelectorWithArrayFilters = (value, path, arrayFilters) => {
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index >= 0) {
    if (arrayFilters[0]) {
      const key = parts.slice(0, index).join('.');
      if (index === parts.length - 1) {
        return {
          [key]: {
            $elemMatch: arrayFilters[0],
          },
        };
      }
      const selector = getSelectorWithArrayFilters(
        value,
        parts.slice(index + 1).join('.'),
        arrayFilters.slice(1),
      );
      return {
        [key]: {
          $elemMatch: {
            ...arrayFilters[0],
            ...selector,
          },
        },
      };
    }
    return undefined;
  }
  return {
    [path]: value,
  };
};

class Variable extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }

  isIdentifier() {
    return this.nativeKey === 'identifiers.$.value';
  }

  isPatientName() {
    return this.isPatient() && this.nativeKey === 'name.text';
  }

  isPatientLanguage() {
    return this.isPatient() && this.nativeKey === 'languagePreference';
  }

  isPatientGender() {
    return this.isPatient() && this.nativeKey === 'gender';
  }

  isParticipationStudyNo() {
    return this.isParticipation() && this.nativeKey === 'studyNo';
  }

  isParticipationTrackId() {
    return this.isParticipation() && this.nativeKey === 'trackId';
  }

  isParticipationTrackDate() {
    return this.isParticipation() && this.nativeKey === 'trackDate';
  }

  isParticipationState() {
    return this.isParticipation() && this.nativeKey === 'state';
  }

  isActivityState() {
    return this.isActivity() && this.nativeKey === 'state';
  }

  isActivityMilestoneId() {
    return this.isActivity() && this.nativeKey === 'milestoneId';
  }

  isPII() {
    if (this.pii) {
      return true;
    }
    if (this.isPatient() && this.nativeKey) {
      if (this.nativeKey === 'name' || /^name\./.test(this.nativeKey)) {
        return true;
      }
      if (this.nativeKey === 'emails' || /^emails\./.test(this.nativeKey)) {
        return true;
      }
      if (this.nativeKey === 'phones' || /^phones\./.test(this.nativeKey)) {
        return true;
      }
      if (
        this.nativeKey === 'identifiers' ||
        /^identifiers\./.test(this.nativeKey)
      ) {
        return true;
      }
    }
    return false;
  }

  getResourceType() {
    switch (this.scopeName) {
      case Activity.scopeName:
        return 'activity';
      case Participation.scopeName:
        return 'participation';
      case Recipient.scopeName:
        return 'patient';
      default:
        return 'unknown';
    }
  }

  isNative() {
    return !!this.nativeKey;
  }

  isCustom() {
    return !this.isNative();
  }

  isEditable(isCreate = false) {
    if (this.evaluated) {
      return false;
    }
    if (isCreate) {
      return true;
    }
    return !this.immutable;
  }

  getTitle(language = 'en') {
    const schema = this.getJsonSchema({
      language,
    });
    return schema && schema.title;
  }

  isActivity() {
    return this.scopeName === Activity.scopeName;
  }

  isPatient() {
    return this.scopeName === Recipient.scopeName;
  }

  isParticipation() {
    return this.scopeName === Participation.scopeName;
  }

  isAtomic() {
    return isAtomic(this.getJsonSchema());
  }

  getIdentifierNamespace() {
    if (this.nativeKey !== 'identifiers.$.value') {
      return undefined;
    }
    const filter = this.nativeKeyFilters && this.nativeKeyFilters[0];
    return filter && filter.namespace;
  }

  getKey(target) {
    const path = this.nativeKey || `variables.${this._id}`;
    if (!target) {
      return path;
    }
    return getKey(target, path, {
      arrayFilters: this.nativeKeyFilters,
    });
  }

  setValue(target, value) {
    if (this.immutable || this.evaluated) {
      return false;
    }
    const schema = this.getJsonSchema();
    const errors = checkSchema(schema, value);
    if (errors) {
      // NOTE: Compare with analogous case at getVariablesUpdatePipeline.
      if (value === null || (value === '' && schema && isAtomic(schema))) {
        try {
          deleteKey(target, this.getKey(), {
            requiredKeys: this.requiredKeys,
            arrayFilters: this.nativeKeyFilters,
            throwOnRequiredKey: true,
          });
          return true;
        } catch (err) {
          if (isRequiredKeyError(err)) {
            return false;
          }
          throw err;
        }
      }
      return false;
    }
    setValue(target, this.getKey(), value, {
      modifiers: this.modifiers,
      arrayFilters: this.nativeKeyFilters,
    });
    return true;
  }

  getRawValue(target) {
    if (!target || !isObject(target)) {
      return undefined;
    }
    const { scopeName } = target.constructor;
    if (scopeName && this.scopeName && scopeName !== this.scopeName) {
      return undefined;
    }
    if (this.valueGetter) {
      switch (this.valueGetter) {
        case 'domains': {
          const value = get(target, this.nativeKey);
          if (value !== undefined) {
            return map(value, 'domain');
          }
          return undefined;
        }
        default:
          return undefined;
      }
    }
    if (this.nativeKey) {
      if (this.nativeKeyFilters) {
        return getValueWithArrayFilters(
          target,
          this.nativeKey,
          this.nativeKeyFilters,
        );
      }
      return get(target, this.nativeKey);
    }
    return target && target.variables && target.variables[this._id];
  }

  getSelector(value) {
    if (this.nativeKey) {
      if (this.nativeKeyFilters) {
        return getSelectorWithArrayFilters(
          value,
          this.nativeKey,
          this.nativeKeyFilters,
        );
      }
      return {
        [this.nativeKey]: value,
      };
    }
    return {
      [`variables.${this._id}`]: value,
    };
  }

  getValue(target) {
    const value = this.getRawValue(target);
    const schema = this.getJsonSchema();
    return cleanValue(schema, value);
  }

  getDisplayValue(value, options) {
    const schema = this.getJsonSchema(options);
    const choices = schema.anyOf || schema.oneOf;
    if (choices) {
      const option = find(choices, {
        const: value,
      });
      if (!option) {
        return '[unknown]';
      }
      return option.title;
    }
    if (isArray(value)) {
      if (
        schema &&
        schema.type === 'array' &&
        schema.items &&
        schema.items.type === 'string'
      ) {
        return map(value, csvEncode).join(',');
      }
      return '[array]';
    }
    if (isPlainObject(value)) {
      return '[object]';
    }
    return value;
  }

  getFromContext(context = {}) {
    // TODO: Replace magic strings with enums.
    switch (this.scopeName) {
      case '@project': {
        return this.getValue(context.project);
      }
      case '@milestone': {
        return this.getValue(context.milestone);
      }
      case '@recipient': {
        return this.getValue(context.recipient);
      }
      case '@participation': {
        return this.getValue(context.participation);
      }
      case '@activity': {
        return this.getValue(context.activity);
      }
      case '@answersSheet': {
        return this.getValue(context.answersSheet);
      }
      default:
        return undefined;
    }
  }

  getJsonSchema(options) {
    const { language = 'en', projectId, allowedDomains } = options || {};
    const params = {
      language,
      projectId,
    };
    const key = `_getJsonSchema(${JSON.stringify(omitBy(params, isNil))})`;
    if (has(this, key)) {
      return this[key];
    }
    let jsonSchema;
    if (projectId) {
      const originalJsonSchema = this.getJsonSchema({
        ...options,
        projectId: null,
      });
      if (
        this[`_project_${projectId}`] &&
        this[`_project_${projectId}`].options &&
        !originalJsonSchema.anyOf &&
        !originalJsonSchema.oneOf &&
        !originalJsonSchema.enum
      ) {
        const anyOf = map(
          this[`_project_${projectId}`].options,
          ({ value, label }) => ({
            title: label,
            const: value,
          }),
        );
        if (originalJsonSchema.type === 'array') {
          jsonSchema = {
            ...originalJsonSchema,
            items: {
              ...originalJsonSchema.items,
              anyOf,
            },
          };
        } else {
          jsonSchema = {
            ...originalJsonSchema,
            anyOf,
          };
        }
      } else {
        jsonSchema = originalJsonSchema;
      }
      if (
        this.valueSetter === 'domains' &&
        allowedDomains &&
        jsonSchema.type === 'array' &&
        jsonSchema.items &&
        jsonSchema.items.type === 'string' &&
        !jsonSchema.anyOf &&
        !jsonSchema.oneOf &&
        !jsonSchema.enum
      ) {
        jsonSchema = {
          ...jsonSchema,
          items: {
            ...jsonSchema.items,
            anyOf: map(allowedDomains, (domain) => {
              if (domain && domain._id) {
                return {
                  const: domain._id,
                  title: domain.name || domain._id,
                };
              }
              return {
                const: domain,
                title: domain,
              };
            }),
          },
        };
      }
    } else if (language) {
      const originalJsonSchema = this.getJsonSchema({
        ...options,
        language: null,
      });
      let jsonSchemaI18n;
      try {
        jsonSchemaI18n = JSON.parse(
          this.jsonSchemaI18n && this.jsonSchemaI18n[language],
        );
      } catch (err) {
        jsonSchemaI18n = {}; // any
      }
      jsonSchema = merge(cloneDeep(originalJsonSchema), jsonSchemaI18n);
    } else {
      try {
        jsonSchema = JSON.parse(this.jsonSchema);
      } catch (err) {
        jsonSchema = {}; // any
      }
      if (isPlainObject(jsonSchema) && !jsonSchema.title) {
        jsonSchema.title = this.name;
      }
    }
    Object.defineProperty(this, key, {
      value: jsonSchema,
    });
    return jsonSchema;
  }

  static fromBuiltIn({
    _id,
    name,
    question,
    pii,
    valueSetter,
    valueGetter,
    nativeKey,
    nativeKeyFilters,
    requiredKeys,
    modifiers,
    immutable,
    evaluated,
    fhir,
    widgetType,
    jsonSchema,
    jsonSchemaI18n,
    scopeName,
    patientServiceSafe,
    patientServiceId,
  }) {
    return new Variable({
      _id,
      ownership: [
        {
          domain: DOMAIN_PREFIX,
        },
      ],
      name: name || (question ? question.label : ''),
      pii,
      valueSetter,
      valueGetter,
      nativeKey,
      nativeKeyFilters,
      requiredKeys,
      modifiers,
      immutable: !!immutable,
      evaluated: !!evaluated,
      fhir,
      widgetType,
      jsonSchema: jsonSchema ? JSON.stringify(jsonSchema) : '{}',
      jsonSchemaI18n: mapValues(jsonSchemaI18n, (value) =>
        JSON.stringify(value),
      ),
      scopeName,
      patientServiceSafe,
      patientServiceId,
    });
  }
}

Variable.collection = 'Variables';

export default Variable;
