import PropTypes from 'prop-types';
import React from 'react';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CalendarOutlined, FlagOutlined } from '@ant-design/icons';
import Cluster from '../../../../common/components/primitives/Cluster';
import Box from '../../../../common/components/primitives/Box';
import Stack from '../../../../common/components/primitives/Stack';
import ProjectTotals from '../../../../common/models/ProjectTotals';
import ProjectSummary from '../../../../components/projects/ProjectSummary';
import ProjectIconText from '../../../../components/projects/ProjectIconText';
import ProjectSelect from '../../../../common/selectors/Project';
import {
  projectTotals,
  apiZedocProjectSummary,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import Loading from '../../../../common/components/Loading';

const ProjectInfo = ({ projectId }) => {
  const { t } = useTranslation();
  const { ready: projectTotalsReady } = useDDPSubscription(
    projectTotals.withParams({
      projectId,
    }),
  );

  const { ready: projectSummaryReady } = useDDPSubscription(
    apiZedocProjectSummary.withParams({
      projectId,
    }),
  );

  const { ready: projectDetailsReady } = useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  if (!projectTotalsReady || !projectSummaryReady || !projectDetailsReady) {
    return <Loading />;
  }

  const totals = new ProjectTotals(project.totals);
  return (
    <Cluster>
      <Box.Primary boxShadow="base">
        <Stack space={1}>
          <ProjectSummary project={project} />
        </Stack>
      </Box.Primary>

      <Box.Primary boxShadow="base">
        <Stack space={1}>
          <Cluster>
            <ProjectIconText
              title={t('projects:overallToDate')}
              text={totals.getTotalCompleted()}
              icon={<FlagOutlined />}
              kind="success"
            />
            <ProjectIconText
              title={t('due')}
              text={totals.getTotalNotResolved()}
              icon={<CalendarOutlined />}
            />
          </Cluster>
        </Stack>
      </Box.Primary>
    </Cluster>
  );
};

ProjectInfo.propTypes = {
  projectId: PropTypes.string.isRequired,
};

ProjectInfo.defaultProps = {};

export default ProjectInfo;
