/* eslint-disable react/prop-types */
import React from 'react';
import Text from '../../../common/components/base/Text';
import Divider from '../../../common/components/Divider';
// import Sidebar from '../../../common/components/primitives/Sidebar';
import Stack from '../../../common/components/primitives/Stack';
import Grid from '../../../common/components/primitives/Grid';

const Templates = ({ title, count, description, children }) => {
  // FIXME: Hide count/description if no props provided
  return (
    <Stack>
      <Stack space={2}>
        <Stack space={0}>
          <Text.Heading level={3}>
            {title}
            {count && (
              <>
                {' '}
                <Text.Span size="xs" weight="normal">
                  {'('}
                  {count}
                  {')'}
                </Text.Span>
              </>
            )}
          </Text.Heading>
          <Text.Paragraph size="small">{description}</Text.Paragraph>
        </Stack>
        <Divider />
      </Stack>
      {/* <Sidebar sidebar={<Text.Link size="large">See All &rarr;</Text.Link>}>
        <Grid sizing="auto-fill">{children}</Grid>
      </Sidebar> */}
      <Grid sizing="auto-fill">{children}</Grid>
    </Stack>
  );
};

Templates.propTypes = {};

Templates.defaultProps = {};

export default Templates;
