import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  // useSelector,
  useDispatch,
} from 'react-redux';

import Button from '../../../common/components/Button';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Grid from '../../../common/components/primitives/Grid';
import Search from '../../../components/inputs/Search';
import { openEditVariableDialog } from '../store';

const PermissionsDomainFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onAdd = useCallback(() => {
    dispatch(openEditVariableDialog({}));
  }, [dispatch]);

  return (
    <Sidebar
      sidebar={
        <Button
          data-testid="button-add-user"
          type="primary"
          onClick={onAdd}
          disabled={false}
        >
          {t('addVariable')}
        </Button>
      }
    >
      <Grid>
        <Search />
      </Grid>
    </Sidebar>
  );
};

PermissionsDomainFilters.propTypes = {};

PermissionsDomainFilters.defaultProps = {};

export default PermissionsDomainFilters;
