import isObject from 'lodash/isObject';

// TODO: Add tests
// eslint-disable-next-line import/prefer-default-export
export const renderColumn = ({ column, item, index }) => {
  if (column.render) {
    return column.render(item[column.key], item, index);
  }

  if (isObject(item[column.key])) {
    return null;
  }

  return item[column.key];
};
